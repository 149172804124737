//dependencias
import React, { memo } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
//
import "./assets/css/material-dashboard-react.css?v=1.9.0";

// core components
import Admin from "./layouts/Admin";

//importando tema
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
//coponentes /vistas fuera del admin

import Login from "./views/Auth/Login";

import AuthState from "./Context/Auth/AuthState";
import tokenAuth from "./config/token";
import axiosEmpresa from "./config/axiosEmpresa";
import AlertaState from "./Context/Alerta/AlertaState";

//ruta protegida
import RutaPrivada from "./components/rutas/RutaPrivada";
import MenuState from "./Context/Menu/MenuState";
import RegistroState from "./Context/Registros/RegistroState";

import "./components/Extra/GlobalCssSlider.css";
//PERSONAS
import DocumentosState from "./Context/Configuraciones/Documentos/DocumentosState";
import InformesState from "./Context/Informes/InformesState";
import CambioPuestoState from "./Context/CambioPuesto/CambioPuestoState";
import EncuestasEvaluacionesState from "./Context/EncuestasEvaluaciones/EncuestasEvaluacionesState";

const token = localStorage.getItem("token");
const empresaheader = localStorage.getItem("empresaheader");
const usuarioheader = localStorage.getItem("usuarioheader");
const rolheader = localStorage.getItem("rolheader");
if (token) {
  tokenAuth(token);
  axiosEmpresa(empresaheader, usuarioheader, rolheader);
}
function App() {
  const hist = createBrowserHistory();

  return (
    <ThemeProvider theme={theme}>
      <AuthState>
        <AlertaState>
          <MenuState>
            <RegistroState>
              <DocumentosState>
                <InformesState>
                  <CambioPuestoState>
                    <EncuestasEvaluacionesState>
                      <Router history={hist}>
                        <Switch>
                          <Route path="/login" component={Login} />
                          <RutaPrivada path="/Seguridad" component={Admin} />
                          <Redirect from="/" to="/login" />
                        </Switch>
                      </Router>
                    </EncuestasEvaluacionesState>
                  </CambioPuestoState>
                </InformesState>
              </DocumentosState>
            </RegistroState>
          </MenuState>
        </AlertaState>
      </AuthState>
    </ThemeProvider>
  );
}

export default memo(App);
