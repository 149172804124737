import { FechaExacta } from "../../../config/const";

export const columns = [
  {
    id: "documento_proveedor",
    label: "Proveedor",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "documento_proveedor",
    align: "center",
    ocultar: true,
  },

  {
    id: "numFactura",
    label: "Num. Factura",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "numFactura",
    align: "center",
    ocultar: true,
  },
  {
    id: "fecFactura",
    label: "Fec. Factura",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "fecFactura",
    align: "center",
    ocultar: true,
  },
  {
    id: "cantidad",
    label: "Cantidad",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "cantidad",
    align: "center",
    ocultar: true,
  },

  {
    id: "user",
    label: "User",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "user",
    align: "center",
    ocultar: true,
  },
  {
    id: "fec_upd",
    label: "fec_upd",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "fec_upd",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // id_compra:"",
  id_proveedor: 0, // consumir endpoint solo de PROVEEDOR
  invId: 1, // DE MOMENTO SOLO ENVIAR EL 1,(LUEGO SE VA CONSUMIR UN ENDPOINT)
  cantidad: 1, // input number
  numFactura: "", // input text
  fecFactura: FechaExacta, // input date
  distribuido_en_bodegas: 0, // true or false (1 | 0)
  user: "",
  cod_empresa: "",
  documento_proveedor: "", // join
  razonSocial_proveedor: "", // join
};
