import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { Cancel, DeleteForever, Map, PictureAsPdf } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import clienteAxios from "../../../../../config/axios";
import { saveAs } from "save-as";
import DialogMap from "../../../../components/DialogMap";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    // tienePermiso,
    // alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    // setopen,
    // seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    // changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openMap, setopenMap] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        actUser: usuario.usuario,
        actEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        regFecReg: rowHijo.regFecReg,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={() => {}}
        item={rowHijo}
        editar={false}
        propiedad={rowHijo.regCoordenadas}
        nombrePropiedad={"regCoordenadas"}
        solover={true}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 5,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Mapa",
              texto: "",
              funcion: () => {
                setopenMap(true);
              },
              disabled: false,
              Icon: Map,
              color: "secondary",
              id: 5,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: async () => {
                const json = {
                  usuario: usuario.usuario,
                  nombre: usuario.nombre,
                  rucempresa: rowHijo.regEmpresa,
                  idPersona: rowHijo.regCodigo,
                  fecha: rowPadre.regFecReg.substr(0, 7),
                };

                const res = await clienteAxios.post(
                  `/reportes/registro_individual`,
                  {
                    ...json,
                  },
                  { responseType: "blob" }
                );

                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `Asistencia Individual.pdf`);
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 5,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="left" className={claseCelda}>
            {rowHijo.regDocumento + " " + rowHijo.regNombres}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.regHoraRegistroIngreso)
              .toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
                timeZone: "America/Guayaquil",
              })
              .substring(0, 15)}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regDatosTurno[0]?.clienteIngreso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regDatosTurno[0]?.puestoIngreso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regHoraIngreso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {
              new Date(rowHijo.regHoraRegistroIngreso)
                .toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  hour: "2-digit",
                  timeZone: "America/Guayaquil",
                })
                .split(" ")[2]
            }
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regStatusIngreso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regTiempoIngreso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regDatosTurno[0]?.clienteSalida}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regDatosTurno[0]?.puestoSalida}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regHoraSalida}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regHoraRegistroSalida === ""
              ? null
              : new Date(rowHijo.regHoraRegistroSalida).toLocaleString(
                  "es-ES",
                  {
                    weekday: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    minute: "2-digit",
                    hour: "2-digit",
                    timeZone: "America/Guayaquil",
                  }
                )}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regStatusSalida}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.regTiempoSalida}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
