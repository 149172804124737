import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import { TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { usePermiso } from "../../../../hooks/usePermiso";
import ButtonsAction from "../../../../components/ButtonsAction";

const RowAgregar = (props) => {
  const classes = useRowStyles();
  const { itemHijo, item, setitem, index, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemEdit, setItemEdit] = useState({ ...itemHijo });
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("Novedades");
  const botonDisabled = () => {
    if (itemEdit.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    socket.current.emit("client:actualizarData", {
      ...item,
      tabla: "novedad",
      novlista: item.novlista.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      novlista: item.novlista.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...item,
      tabla: "novedad",
      novlista: item.novlista.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      novlista: item.novlista.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  useEffect(() => {
    setItemEdit({ ...itemHijo });
    // eslint-disable-next-line
  }, [item]);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={editar ? botonesEditar : botones} />

        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="left"
              className={classes.celda}
            >
              {itemHijo.nombre}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
