import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";

import { useModalStyle } from "../../styles/styleModal";

import { objectDefault } from "../data";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import CamposAcumulables from "../../components/Campos/CamposAcumulables";
import { validarEmail } from "../../../config/validaciones";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalResidentes = (props) => {
  //props
  const {
    // estadoRequest,
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // resId,
    // resCliId,
    resCliDocumento,
    resCliNombre,
    resCliUbicacion,
    resCliPuesto,
    resCedula,
    resNombres,
    resTelefono,
    resDepartamento,
    resUbicacion,
    resPersonasAutorizadas,
    resCorreo,
    resEstado,
    // resUser,
    // resEmpresa,
    // resFecReg,
    // resFecUpd,
  } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //  STATE
  const [clientesData, setclientesData] = useState([]);
  const [puestosData, setpuestosData] = useState([]);
  const [openDialogClose, setopenDialogClose] = useState(false);
  const [modificado, setmodificado] = useState(false);
  const esGuardia = usuario.rol.includes("GUARDIA");
  // SECCION
  const seccionDefault = {
    seccionDatosPrincipales: true,
  };
  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const { seccionDatosPrincipales } = seccionActive;
  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {
    if (open) {
      setmodificado(true);
    }
    // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    setitem(ObjectoActivo);
    setTimeout(() => {
      setmodificado(false);
    }, 500);

    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = (args) => {
    if (args?.eliminarForm) {
      changeObjetoActivoState(objectDefault);
      setitem(objectDefault);
    }
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setopenDialogClose(false);
    setmodificado(false);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  function tienePropiedadVerdadera(objeto) {
    return Object.values(objeto).some((valor) => valor === true);
  }
  const validacion = () => {
    const errorValidacion = {};

    // !quitarCamposPorRol && bitResCedula.trim() === "";
    errorValidacion.resCliDocumento =
      !esGuardia && resCliDocumento.trim() === "";
    errorValidacion.resCliUbicacion =
      !esGuardia && resCliUbicacion.trim() === "";
    errorValidacion.resCedula = resCedula.trim() === "";
    errorValidacion.resNombres = resNombres.trim() === "";
    errorValidacion.resTelefono = resTelefono.length === 0;
    errorValidacion.resDepartamento = resDepartamento.trim() === "";
    errorValidacion.resUbicacion = resUbicacion.trim() === "";
    errorValidacion.resPersonasAutorizadas =
      resPersonasAutorizadas.length === 0 ||
      resPersonasAutorizadas.some((value) => value.trim() === "");
    errorValidacion.resCorreo =
      resCorreo.some((correo) => validarEmail(correo)) ||
      resCorreo.length === 0;
    if (tienePropiedadVerdadera(errorValidacion)) {
      mostrarAlerta("Faltan campos obligatorios", "error");
      seterror(errorValidacion);
    }

    return tienePropiedadVerdadera(errorValidacion);
  };
  const handleCrear = async () => {
    try {
      if (validacion()) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "residente",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
          regId: esGuardia ? usuario.regId : 0,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (validacion()) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "residente",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGurdar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openDialogClose}
          setopen={() => setopenDialogClose(false)}
          titulo={"Desea mantener los cambios?"}
          categoria="warning"
          botones={[
            {
              tooltip: "Mantener datos",
              texto: "SI",
              funcion: () => {
                defaultActive({ eliminarForm: false });
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Vaciar datos",
              texto: "NO",
              funcion: () => {
                defaultActive({ eliminarForm: true });
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} Residentes.`}
                  {/* <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton
                      onClick={() => {
                        handleGurdar();
                      }}
                    >
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip> */}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      if (!modificado) return defaultActive();
                      setopenDialogClose(true);
                    }}
                  >
                    <Close color={modificado ? "error" : "primary"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionDatosPrincipales
                        ? "contained"
                        : error.resCliDocumento ||
                          error.resCliUbicacion ||
                          error.resCedula ||
                          error.resNombres ||
                          error.resTelefono ||
                          error.resDepartamento ||
                          error.resUbicacion ||
                          error.resPersonasAutorizadas
                        ? "outlined"
                        : "null"
                    }
                    color={
                      error.resCliDocumento ||
                      error.resCliUbicacion ||
                      error.resCedula ||
                      error.resNombres ||
                      error.resTelefono ||
                      error.resDepartamento ||
                      error.resUbicacion ||
                      error.resPersonasAutorizadas
                        ? "error"
                        : "primary"
                    }
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDatosPrincipales: true })
                    }
                  >
                    Datos Principales
                  </Button>
                  {/* <Button
                    variant={
                      seccionDocumentos
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDocumentos: true })
                    }
                  >
                    Documentos
                  </Button> */}
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={resEstado === "ACTIVA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          resEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionDatosPrincipales && (
                  <>
                    {esGuardia ? null : (
                      <>
                        {" "}
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            options={clientesData.map((item) => {
                              return (
                                item.cliDocNumero +
                                "/+/" +
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={(
                              resCliNombre +
                              " " +
                              resCliDocumento
                            ).trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const [
                                  resCliDocumento,
                                  resCliNombre,
                                  resCliId,
                                ] = newValue.split("/+/");
                                const itemF = clientesData.find(
                                  (itemCliente) =>
                                    itemCliente.cliId === Number(resCliId)
                                );
                                setpuestosData(itemF.cliDatosOperativos);
                                setitem({
                                  ...item,
                                  resCliDocumento,
                                  resCliNombre,
                                  resCliId,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Cliente"
                                error={error.resCliDocumento}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    return mostrarAlerta(
                                      "Hubo un error",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disableClearable={true}
                            options={puestosData.map((item) => {
                              return item.ubicacion + "/+/" + item.puesto;
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " - ");
                            }}
                            value={
                              `${resCliUbicacion} ${resCliPuesto}`.trim() === ""
                                ? ""
                                : `${resCliUbicacion} - ${resCliPuesto}`.trim()
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                try {
                                  const [
                                    resCliUbicacion,
                                    resCliPuesto,
                                  ] = newValue.split("/+/");

                                  setitem({
                                    ...item,
                                    resCliUbicacion,
                                    resCliPuesto,
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Puesto"
                                error={error.resCliUbicacion}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}

                    <TextField
                      label="Cédula"
                      error={error.resCedula}
                      value={resCedula}
                      name="resCedula"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label="Nombres"
                      error={error.resNombres}
                      value={resNombres}
                      name="resNombres"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />

                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"resCorreo"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Correos"}
                      etiquetaCampo={"Correo"}
                    />
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"resTelefono"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Teléfonos"}
                      etiquetaCampo={"Teléfono"}
                    />
                    <TextField
                      label="Casa o Departamento Nº"
                      error={error.resDepartamento}
                      value={resDepartamento}
                      name="resDepartamento"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label="Ubicación"
                      error={error.resUbicacion}
                      value={resUbicacion}
                      name="resUbicacion"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"resPersonasAutorizadas"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Personas Autorizadas"}
                      etiquetaCampo={"Persona"}
                    />
                  </>
                )}
              </Box>
              <Divider />
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleGurdar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalResidentes);
