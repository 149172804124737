import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";

import Dropzone from "../../../components/Extra/Dropzone";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Cancel, Create, PersonAdd } from "@mui/icons-material";
import { objectDefault } from "../data";
import { objectDefault as objectDefaultPersona } from "../../Personas/Personas/data";
import { useModalStyle } from "../../styles/styleModal";
import CamposAcumulables from "../../components/Campos/CamposAcumulables";

import ModalPersonas from "../../Personas/Personas/Componentes/Modal";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import CampoDoc from "../../components/CampoDoc";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  // PERSONAS
  const [editarPersona, seteditarPersona] = useState(false);
  const [openPersona, setopenPersona] = useState(false);
  const [ObjectoActivoPersona, setObjectoActivoPersona] = useState(
    objectDefaultPersona
  );
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  // eslint-disable-next-line
  const {
    // infIdDirigido,
    // nomIdPersona,
    nomDocuPersona,
    nomNomPersona,
    nomMotivo,
    nomDetalle,
    nomEstado,
    nomFecha,
    nomFotos,
    nomStatusDescripcion,
    nomFecReg,
    nomPerfil,
    nomTipo,
    // nomPerId,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  const getPersona = async (idPersona) => {
    try {
      const res = await clienteAxios(`personas/perfil/${idPersona}`);
      setItemPersona(res.data.data);
    } catch (error) {
      setItemPersona(objectDefaultPersona);
    }
  };
  useEffect(() => {
    if (open && editar) {
      getPersona(ObjectoActivo.nomPerId);
    }
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = (eliminar) => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosGenerales: true,
      DatosArchivos: false,
    });
    if (itemPersona?.perId && eliminar && !editar) {
      try {
        socket.current.emit("client:eliminarData", {
          tabla: "persona",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: usuario.rucempresa,
          perId: itemPersona?.perId,
        });
      } catch (error) {}
    }
    setItemPersona(objectDefaultPersona);

    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);

  const [
    ocultar,
    // setocultar
  ] = useState(false);

  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    statusSeccion: false,
    DatosArchivos: false,
  });
  // eslint-disable-next-line
  const { DatosGenerales, statusSeccion, DatosArchivos } = seccionActive;

  const validacion = () => {
    if (
      nomNomPersona.trim() === "" ||
      nomMotivo.trim() === "" ||
      (!itemPersona?.perId &&
        nomTipo === "REEMPLAZO" &&
        nomEstado === "APROBADO") ||
      (nomMotivo === "RENUNCIA ART 190 CT" && item.nomFechas.length === 0)
    ) {
      mostrarAlerta(
        `Faltan campos obligatorios ${
          !itemPersona?.perId &&
          nomTipo === "REEMPLAZO" &&
          nomEstado === "APROBADO"
            ? "Y TAMBIEN DEBE REGISTRAR A UNA PERSONA"
            : ""
        }`,
        "error"
      );
      seterror({
        nomNomPersona: nomNomPersona.trim() === "",
        nomTipo:
          !itemPersona?.perId &&
          nomTipo === "REEMPLAZO" &&
          nomEstado === "APROBADO",
        nomMotivo:
          nomMotivo.trim() === "" ||
          (nomMotivo === "RENUNCIA ART 190 CT" && item.nomFechas.length === 0),
        nomFechas:
          nomMotivo === "RENUNCIA ART 190 CT" && item.nomFechas.length === 0,
      });

      return true;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return;
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, nomFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nomFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          nomUser: usuario.usuario,
          nomEmpresa: usuario.rucempresa,
          tabla: "avisosalida",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, nomFotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          nomFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          nomUser: usuario.usuario,
          nomEmpresa: usuario.rucempresa,
          tabla: "avisosalida",
          nomPerId: itemPersona?.perId,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const [itemPersona, setItemPersona] = useState(objectDefaultPersona);
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }
    if (usuario.usuario !== item.perUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== "persona") {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      setItemPersona(item);
    }
    if (tipo === "editar") {
      setItemPersona(item);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <>
      <ModalPersonas
        fromAvisoSalida={true}
        editar={editarPersona}
        seteditar={seteditarPersona}
        setopen={setopenPersona}
        open={openPersona}
        ObjectoActivo={ObjectoActivoPersona}
        changeObjetoActivoState={setObjectoActivoPersona}
        socket={socket}
        estadoRequest={
          nomPerfil === "GUARDIA"
            ? "GUARDIAS"
            : nomPerfil === "SUPERVISOR"
            ? "SUPERVISORES"
            : nomPerfil === "ADMINISTRACION"
            ? "ADMINISTRACIÓN"
            : ""
        }
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div
                style={ocultar ? modalStyleOculto : modalStyle}
                className={clases.paper}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    id="handle"
                    style={{ cursor: "-webkit-grab" }}
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {editar ? "Editar" : "Agregar"} Aviso Salida.
                  </Typography>
                  <Typography
                    variant="h5"
                    color="initial"
                    align="center"
                  ></Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive(true);
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box>
                    <Button
                      variant={DatosGenerales ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ DatosGenerales: true })}
                    >
                      Datos Generales
                    </Button>
                    {editar ? (
                      <Button
                        variant={statusSeccion ? "contained" : null}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() =>
                          setseccionActive({ statusSeccion: true })
                        }
                      >
                        Status
                      </Button>
                    ) : null}

                    <Button
                      variant={DatosArchivos ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ DatosArchivos: true })}
                    >
                      Datos Archivos
                    </Button>
                  </Box>
                  {/* <FormControlLabel
                  control={
                    <Switch
                      checked={
                        conEstado === "ACTIVO" || conEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
                </Box>
                <Divider />
                <Box className={clases.container}>
                  {itemPersona?.perId && (
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      alignItems={"center"}
                      className={clases.formAllW}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        fontWeight={"bold"}
                      >
                        Persona:{" "}
                        <Typography
                          variant="body2"
                          color="primary"
                          component={"span"}
                        >
                          {itemPersona.perDocNumero} {itemPersona.perApellidos}{" "}
                          {itemPersona.perNombres} {" - "}
                          {itemPersona.perDocuCliente}{" "}
                          {itemPersona.perNombreCliente}
                        </Typography>
                      </Typography>
                      <Box display={"flex"} alignItems="center">
                        <Tooltip title="Editar persona">
                          <IconButton
                            aria-label=""
                            style={{ margin: "0px" }}
                            onClick={() => {
                              setObjectoActivoPersona(itemPersona);
                              seteditarPersona(true);
                              setopenPersona(true);
                            }}
                          >
                            <Create color="secondary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                  {DatosGenerales ? (
                    <>
                      <TextField
                        className={clases.formD}
                        size="small"
                        label="Perfil"
                        value={nomPerfil}
                        name="nomPerfil"
                        error={error.nomPerfil}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem disabled value="">
                          Seleccione.
                        </MenuItem>
                        {["GUARDIA", "SUPERVISOR", "ADMINISTRACION"].map(
                          (item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          }
                        )}
                      </TextField>
                      <TextField
                        className={clases.formD}
                        size="small"
                        label="Tipo"
                        value={nomTipo}
                        name="nomTipo"
                        error={error.nomTipo}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {nomEstado === "APROBADO" &&
                                nomTipo === "REEMPLAZO" && (
                                  <Tooltip title="Agregar Persona" followCursor>
                                    <IconButton
                                      disabled={
                                        ObjectoActivo.nomEstado === "APROBADO"
                                      }
                                      size="small"
                                      onClick={() => setopenPersona(true)}
                                      color="secondary"
                                    >
                                      <PersonAdd
                                        fontSize="small"
                                        sx={{ mr: "2rem" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </>
                          ),
                        }}
                      >
                        <MenuItem disabled value="">
                          Seleccione.
                        </MenuItem>
                        {["TERMINACION", "REEMPLAZO"].map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </TextField>
                      <FormControl className={clases.formD}>
                        <Autocomplete
                          options={clientesData.map((item) => {
                            return (
                              item.perDocNumero +
                              "/+/" +
                              item.perApellidos +
                              " " +
                              item.perNombres +
                              "/+/" +
                              item.perId
                            );
                          })}
                          getOptionLabel={(option) => {
                            return option.replace("/+/", " ").split("/+/")[0];
                          }}
                          value={
                            (nomDocuPersona + " " + nomNomPersona).trim() === ""
                              ? ""
                              : nomDocuPersona + " " + nomNomPersona
                          }
                          onChange={async (event, newValue) => {
                            if (newValue) {
                              const options = newValue.split("/+/");

                              try {
                                const res = await clienteAxios.get(
                                  `/nominanovedades/byIdPersona?idPersona=${options[2]}`
                                );
                                function obtenerTresDiasConsecutivos() {
                                  const hoy = new Date();
                                  const manana = new Date(hoy);
                                  manana.setDate(hoy.getDate() + 1);

                                  const tresDiasConsecutivos = [];
                                  for (let i = 0; i < 3; i++) {
                                    const dia = new Date(manana);
                                    dia.setDate(manana.getDate() + i);
                                    tresDiasConsecutivos.push(
                                      dia.toISOString().substring(0, 10)
                                    );
                                  }

                                  return tresDiasConsecutivos;
                                }
                                let nomFechas = obtenerTresDiasConsecutivos();

                                if (res.data.data.length === 3) {
                                  function obtenerTresDiasConsecutivosGet() {
                                    const hoy = new Date(
                                      res.data.data[0].nomFecha
                                    );

                                    const tresDiasConsecutivos = [];
                                    for (let i = 0; i < 3; i++) {
                                      const dia = new Date(hoy);
                                      dia.setDate(hoy.getDate() + i);
                                      tresDiasConsecutivos.push(
                                        dia.toISOString().substring(0, 10)
                                      );
                                    }

                                    return tresDiasConsecutivos;
                                  }
                                  const diasConsecutivos = obtenerTresDiasConsecutivosGet();
                                  if (
                                    res.data.data[0].nomFecha ===
                                      diasConsecutivos[0] &&
                                    res.data.data[1].nomFecha ===
                                      diasConsecutivos[1] &&
                                    res.data.data[2].nomFecha ===
                                      diasConsecutivos[2]
                                  ) {
                                    nomFechas = diasConsecutivos;
                                  }
                                }
                                setitem({
                                  ...item,
                                  nomDocuPersona: options[0],
                                  nomNomPersona: options[1].trim(),
                                  nomIdPersona: options[2],
                                  nomFechas:
                                    nomMotivo === "RENUNCIA ART 190 CT"
                                      ? nomFechas
                                      : item.nomFechas,
                                });
                              } catch (error) {
                                console.log({ error });
                                return mostrarAlerta(
                                  "No hay personas",
                                  "error"
                                );
                              }
                            } else {
                              setitem({
                                ...item,
                                nomDocuPersona: "",
                                nomNomPersona: "",
                                nomIdPersona: "",
                              });
                              setclientesData([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              required
                              fullWidth
                              label="Persona"
                              error={error.nomNomPersona}
                              onChange={async (e) => {
                                if (e.target.value === "") {
                                  return mostrarAlerta(
                                    "Ingrese datos",
                                    "error"
                                  );
                                }
                                try {
                                  const res = await clienteAxios.get(
                                    `/personas/filtro/0?search=${
                                      e.target.value
                                    }&estado=${
                                      nomPerfil === "GUARDIA"
                                        ? "GUARDIAS"
                                        : nomPerfil === "SUPERVISOR"
                                        ? "SUPERVISORES"
                                        : nomPerfil
                                    }`
                                  );

                                  setclientesData(res.data.data);
                                } catch (error) {
                                  setitem({
                                    ...item,
                                    nomDocuPersona: "",
                                    nomNomPersona: "",
                                    nomIdPersona: "",
                                  });
                                  setclientesData([]);

                                  return mostrarAlerta(
                                    "No hay clientes",
                                    "error"
                                  );
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <TextField
                        className={clases.formD}
                        size="small"
                        label="Motivo"
                        value={nomMotivo}
                        name="nomMotivo"
                        error={error.nomMotivo}
                        onChange={async (e) => {
                          const res = await clienteAxios.get(
                            `/nominanovedades/byIdPersona?idPersona=${item.nomIdPersona}`
                          );
                          function obtenerTresDiasConsecutivos() {
                            const hoy = new Date();
                            const manana = new Date(hoy);
                            manana.setDate(hoy.getDate() + 1);

                            const tresDiasConsecutivos = [];
                            for (let i = 0; i < 3; i++) {
                              const dia = new Date(manana);
                              dia.setDate(manana.getDate() + i);
                              tresDiasConsecutivos.push(
                                dia.toISOString().substring(0, 10)
                              );
                            }

                            return tresDiasConsecutivos;
                          }
                          let nomFechas = obtenerTresDiasConsecutivos();

                          if (res.data.data.length === 3) {
                            function obtenerTresDiasConsecutivosGet() {
                              const hoy = new Date(res.data.data[0].nomFecha);

                              const tresDiasConsecutivos = [];
                              for (let i = 0; i < 3; i++) {
                                const dia = new Date(hoy);
                                dia.setDate(hoy.getDate() + i);
                                tresDiasConsecutivos.push(
                                  dia.toISOString().substring(0, 10)
                                );
                              }

                              return tresDiasConsecutivos;
                            }
                            const diasConsecutivos = obtenerTresDiasConsecutivosGet();
                            if (
                              res.data.data[0].nomFecha ===
                                diasConsecutivos[0] &&
                              res.data.data[1].nomFecha ===
                                diasConsecutivos[1] &&
                              res.data.data[2].nomFecha === diasConsecutivos[2]
                            ) {
                              nomFechas = diasConsecutivos;
                            }
                          }
                          let date = new Date();
                          date.setDate(date.getDate() + 15);
                          date = [date.toISOString().substring(0, 10)];

                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                            nomFechas:
                              e.target.value === "RENUNCIA ART 190 CT"
                                ? nomFechas
                                : date,
                          });
                        }}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem disabled value="">
                          Seleccione.
                        </MenuItem>
                        {[
                          "RENUNCIA",
                          "RENUNCIA ART 190 CT",
                          "OTRAS CAUSAS",
                        ].map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </TextField>

                      <CamposAcumulables
                        error={error}
                        propiedadTexto={"nomFechas"}
                        item={item}
                        setitem={setitem}
                        tipoCampos="date"
                        etiqueta={"Fechas"}
                        etiquetaCampo={"Fecha"}
                        ocultarAdd={
                          nomMotivo === "RENUNCIA" ||
                          editar ||
                          ((nomMotivo === "OTRAS CAUSAS" ||
                            nomMotivo === "RENUNCIA ART 190 CT") &&
                            !usuario.rol.some(
                              (rol) =>
                                rol === "ADMIN" || rol === "ADMINISTRACION"
                            ))
                        }
                        disabled={
                          nomMotivo === "RENUNCIA" ||
                          ((nomMotivo === "OTRAS CAUSAS" ||
                            nomMotivo === "RENUNCIA ART 190 CT") &&
                            !usuario.rol.some(
                              (rol) =>
                                rol === "ADMIN" || rol === "ADMINISTRACION"
                            ))
                        }
                        disabledDelete={
                          nomMotivo === "RENUNCIA" ||
                          editar ||
                          ((nomMotivo === "OTRAS CAUSAS" ||
                            nomMotivo === "RENUNCIA ART 190 CT") &&
                            !usuario.rol.some(
                              (rol) =>
                                rol === "ADMIN" || rol === "ADMINISTRACION"
                            ))
                        }
                      />

                      {editar ? (
                        <TextField
                          disabled
                          type={"date"}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            className: clases.inputPadding,
                            min: FechaExacta,
                          }}
                          className={clases.formD}
                          size="small"
                          label="Fec. Reg."
                          value={nomFecReg.split("T")[0]}
                          name="nomFecReg"
                          error={error.nomFecReg}
                        />
                      ) : null}
                      <TextField
                        multiline
                        rows={3}
                        variant={"outlined"}
                        className={clases.formAllW}
                        size="small"
                        label="Detalle"
                        value={nomDetalle}
                        name="nomDetalle"
                        error={error.nomDetalle}
                        onChange={(e) => handleChange(e)}
                      />
                    </>
                  ) : null}
                  {statusSeccion ? (
                    <>
                      <>
                        <Box
                          className={clases.formAllW}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Divider style={{ width: "42%" }} />
                          <Typography
                            style={{ width: "100%" }}
                            variant="button"
                            color="primary"
                            align="center"
                            component={"span"}
                          >
                            Verificación
                          </Typography>
                          <Divider style={{ width: "42%" }} />
                        </Box>
                        <TextField
                          disabled={ObjectoActivo.nomEstado === "APROBADO"}
                          className={clases.formAllW}
                          size="small"
                          label="Estado"
                          value={nomEstado}
                          name="nomEstado"
                          error={error.nomEstado}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem disabled value="">
                            Seleccione.
                          </MenuItem>
                          {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          // disabled={editar && nomMotivo === "RENUNCIA"}
                          type={"date"}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            className: clases.inputPadding,
                            min: FechaExacta,
                          }}
                          className={clases.formAllW}
                          size="small"
                          label="Fecha"
                          disabled={editar}
                          value={nomFecha}
                          name="nomFecha"
                          error={error.nomFecha}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled={ObjectoActivo.nomEstado === "APROBADO"}
                          multiline
                          rows={3}
                          variant="outlined"
                          className={clases.formAllW}
                          size="small"
                          label="Status"
                          value={nomStatusDescripcion}
                          name="nomStatusDescripcion"
                          error={error.nomStatusDescripcion}
                          onChange={(e) => handleChange(e)}
                        />
                      </>
                    </>
                  ) : null}
                  {/* DATOS ARCHIVOS */}
                  {DatosArchivos ? (
                    <>
                      <CampoDoc
                        setcargando={setcargando}
                        label="Documento 1"
                        propiedad={"nomArchivo1"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                      <CampoDoc
                        setcargando={setcargando}
                        label="Documento 2"
                        propiedad={"nomArchivo2"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                      <Dropzone
                        imagenes={imagenes}
                        setimagenes={(e) => {
                          setmodificoImagenes(true);
                          setimagenes(e);
                        }}
                        fotosItem={nomFotos}
                      />
                    </>
                  ) : null}
                </Box>

                {/* <Divider /> */}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={
                      cargando ||
                      ((ObjectoActivo.nomEstado === "ANULADA" ||
                        ObjectoActivo.nomEstado === "APROBADO") &&
                        editar)
                    }
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);
