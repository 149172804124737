import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";
import MuiImageSlider from "mui-image-slider";
import { withRouter } from "react-router";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  Dialog,
  Box,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Photo,
  Visibility,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { DropzoneDialog } from "react-mui-dropzone";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import ButtonsAction from "../../../components/ButtonsAction";
import Cantidades from "../Cantidades/Cantidades";

const RowArma = (props) => {
  const classes = useRowStyles();
  const { bodegaData, ver, item, setitem, row, index, devolucion } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const [cargado, setcargado] = useState(false);
  useEffect(() => {
    if (cargado) {
      return funcSubmit();
    }
    setcargado(true);
    //eslint-disable-next-line
  }, [itemHijoEdit.cantidades]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [open, setOpen] = useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [expandido, setexpandido] = useState(false);
  const [nombresData, setnombresData] = useState([]);
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      disMuniciones: item.disMuniciones.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };

  const [editar, seteditar] = useState(false);

  const botonDisabled = () => {
    if (itemHijoEdit.nombre === "" || itemHijoEdit.cantidades.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      const formDataImagenes = new FormData();
      formDataImagenes.append(`eliminar`, JSON.stringify(itemHijoEdit.fotos));
      let resDocumentos = {
        data: {
          urls: itemHijoEdit.fotos,
        },
      };

      await imagenes.map(async (item, index) => {
        return await formDataImagenes.append(`imagen${index + 1}`, item);
      });

      if (imagenes.length > 0) {
        resDocumentos = await clienteAxios.post(
          "/multimedias",
          formDataImagenes
        );
      }
      setitem({
        ...item,
        disMuniciones: item.disMuniciones.map((item, itemIndex) => {
          if (index === itemIndex) {
            return { ...itemHijoEdit, fotos: resDocumentos.data.urls };
          } else {
            return item;
          }
        }),
      });
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  useEffect(() => {
    if (editar) {
      const getData = async () => {
        try {
          const res = await clienteAxios.get(
            `/inventario/filtroanidado/0?tipo=MUNICIONES&bodega=${itemHijoEdit.bodega}`
          );
          setnombresData(res.data.data);
        } catch (error) {
          return mostrarAlerta("No hay armas", "error");
        }
      };
      getData();
    } // eslint-disable-next-line
  }, [editar]);

  return (
    <React.Fragment>
      <DropzoneDialog
        dialogProps={{ maxWidth: "lg" }}
        dialogTitle={
          <Typography variant="h5" color="primary">
            Subir archivos
          </Typography>
        }
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              Arrastra una imagen o da click aqui.
            </Typography>{" "}
            {editar ? (
              <Typography variant="h5" color="error">
                Subir nuevas imagenes eliminará las anteriores.
              </Typography>
            ) : null}
          </>
        }
        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Aceptar"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          setimagenes(files);
          setOpen(false);
        }}
        showPreviewsInDropzone={true}
        showPreviews={false}
        showAlerts={false}
        showFileNamesInPreview={false}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: ver || devolucion,
              Icon: DeleteForever,
              color: "error",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                seteditar(true);
                setitemHijoEdit(row);
              },
              disabled: ver || devolucion,
              Icon: Create,
              color: "secondary",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitemHijoEdit(row);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: cargando || botonDisabled(),
              Icon: Check,
              color: "success",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver fotos",
              texto: itemHijoEdit.fotos.length,
              funcion: async () => {
                setOpen(true);
              },
              disabled: false,
              Icon: Photo,
              color: "primary",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: async () => {
                setexpandido((prev) => !prev);
              },
              disabled: false,
              Icon: expandido ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <Dialog
          open={Openimagen}
          onClose={() => setOpenimagen(false)}
          aria-labelledby="draggable-dialog-title"
        >
          {itemHijoEdit.fotos === 0 ? null : (
            <MuiImageSlider
              images={itemHijoEdit.fotos.map((item) => item.url)}
              fitToImageHeight={true}
            />
          )}
        </Dialog>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={claseCelda}
        >
          {itemHijoEdit.fotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={bodegaData.map((item) => {
                    return item.bodNombre + "/+/" + item.bodId;
                  })}
                  getOptionLabel={(option) => {
                    return option.split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={itemHijoEdit.bodega}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      const res = await clienteAxios.get(
                        `/inventario/filtroanidado/0?tipo=MUNICIONES&bodega=${options[0]}`
                      );
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        bodega: options[0],
                        idBodega: options[1],
                        id: "",
                        nombre: "",
                        serie: "",
                        valor: "",
                        tipoArma: "",
                        calibre: "",
                        stock: "",
                      });
                      setnombresData(res.data.data);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={nombresData.map((item) => {
                    return (
                      item.invNombre +
                      "/+/" +
                      item.invStock +
                      "/+/" +
                      item.invId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ** ").split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={itemHijoEdit.nombre}
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      const nombre = options[0];
                      const id = options[2];
                      try {
                        const itemF = nombresData.find(
                          (item) => item.invId === Number(id)
                        );
                        if (Number(itemF.invStock) === 0) {
                          return mostrarAlerta(
                            "Este producto ya se encuentra distribuido, no hay stock",
                            "error"
                          );
                        }
                        const serie = itemF.invSerie;
                        const valor = itemF.invPrecioUnitario;
                        const tipoArma = itemF.invCalibre;
                        const calibre = itemF.invCalibre;
                        const marca = itemF.invMarca;
                        const modelo = itemF.invModelo;
                        const material = itemF.invMaterial;
                        const stock = itemF.invStock;
                        const hayDisponible = itemF.invTotalesStock.some(
                          (stock) => stock.cantidad > 0
                        );
                        if (hayDisponible) {
                          setexpandido(true);
                        }

                        setitemHijoEdit({
                          ...itemHijoEdit,
                          tipo: "MUNICIONES",
                          material,
                          //interno
                          id,
                          serie,
                          valor,
                          tipoArma,
                          calibre,
                          marca,
                          modelo,
                          //demas
                          nombre,
                          stock,
                          cantidades: itemF.invTotalesStock.map((stock) => ({
                            ...stock,
                            max: Number(stock.cantidad),
                            cantidad: Math.min(1, stock.cantidad),
                          })),
                        });
                      } catch (error) {}
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.valor}
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.bodega}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.nombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.valor}
            </TableCell>
          </>
        )}
      </TableRow>
      <Cantidades
        ver={ver}
        devolucion={devolucion}
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoEdit}
        setitem={setitemHijoEdit}
      />
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
