import { CloudUpload, DeleteForever, Download } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useContext } from "react";
import { useState, createRef } from "react";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
const useStyles = makeStyles((theme) => ({
  ocultar: {
    display: "none",
  },

  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));
const CampoDoc = (props) => {
  const clases = useStyles();
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    setcargando,
    propiedad,
    item,
    setitem,
    celda,
    claseCelda,
    extension,
  } = props;
  //documento
  const [documentoState, setdocumentoState] = useState("");
  const refArchivoDocumentoState = createRef();
  const focusInputDocumentoState = () => {
    refArchivoDocumentoState.current.click();
  };
  const onChangeArchivoDocumentoState = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoState("");
    }

    if (extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    } else {
      if (
        !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta("El archivo debe ser PDF", "error");
      }
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (item[propiedad] === "") {
        await formDataLogos.append(propiedad, e.target.files[0]);
      } else {
        arrayEliminar.push({
          nombre: "eliminar",
          url: item[propiedad],
        });
        await formDataLogos.append(propiedad, e.target.files[0]);
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));

      const resDocumentos = await clienteAxios.post(
        "/multiplefiles",
        formDataLogos
      );

      const documentosUrls = {};
      resDocumentos.data.urls.forEach((dataUrl) => {
        if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
          return;
        }
        documentosUrls[dataUrl.nombre] = dataUrl.url;
      });

      setitem({
        ...item,
        [propiedad]: documentosUrls[propiedad],
      });

      setdocumentoState(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoState(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoState(e.target.files[0]);
  };

  return (
    <>
      <input
        className={clases.ocultar}
        type="file"
        name="myImage"
        onChange={(e) => onChangeArchivoDocumentoState(e)}
        ref={refArchivoDocumentoState}
      />
      <TextField
        InputLabelProps={{
          shrink:
            documentoState !== "" || item[propiedad] !== "" ? true : false,
        }}
        className={celda ? (claseCelda ? claseCelda : {}) : clases.formD}
        InputProps={{
          endAdornment: (
            <Box htmlFor="contained-button-documento" fullWidth>
              <InputAdornment>
                <Tooltip title="Subir Archivo: Documento" arrow>
                  <Box>
                    <IconButton onClick={focusInputDocumentoState}>
                      <CloudUpload color="secondary" />
                    </IconButton>
                  </Box>
                </Tooltip>
                {item[propiedad] === "" ? null : (
                  <Tooltip title="Descargar Archivo: Documento" arrow>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={async () => {
                          try {
                            const formDataLogos = new FormData();
                            let arrayEliminar = [];

                            arrayEliminar.push({
                              nombre: "eliminar",
                              url: item[propiedad],
                            });

                            await formDataLogos.append(
                              "eliminar",
                              JSON.stringify(arrayEliminar)
                            );
                            setitem({
                              ...item,
                              [propiedad]: "",
                            });
                            setdocumentoState("");

                            await clienteAxios.post(
                              "/multiplefiles",
                              formDataLogos
                            );
                          } catch (error) {}
                        }}
                      >
                        <DeleteForever color="error" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
                {item[propiedad] === "" ? null : (
                  <Tooltip title="Descargar Archivo: Documento" arrow>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={async () => {
                          window.open(item[propiedad]);
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </InputAdornment>
            </Box>
          ),
        }}
        fullWidth
        label={celda ? "" : `Documento`}
        value={
          item[propiedad] && !documentoState.name
            ? item[propiedad].split("/")[item[propiedad].split("/").length - 1]
            : documentoState.name
            ? documentoState.name
            : ""
        }
        name={propiedad}
      />
    </>
  );
};

export default CampoDoc;
