export const columns = [
  {
    id: "resCliDocumento",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliDocumento",
    align: "left",
    ocultar: true,
  },
  {
    id: "resCliUbicacion",
    label: "Ubicación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliUbicacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "resCliPuesto",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliPuesto",
    align: "center",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "resCliDocumento",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliDocumento",
    align: "left",
    ocultar: true,
  },
  {
    id: "resCliUbicacion",
    label: "Ubicacion",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliUbicacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "resCliPuesto",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliPuesto",
    align: "center",
    ocultar: true,
  },
  {
    id: "resCedula",
    label: "Cedula",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCedula",
    align: "center",
    ocultar: true,
  },
  {
    id: "resNombres",
    label: "Nombres",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resNombres",
    align: "center",
    ocultar: true,
  },
  {
    id: "resTelefono",
    label: "Telefono",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resTelefono",
    align: "center",
    ocultar: true,
  },
  {
    id: "resDepartamento",
    label: "Departamento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resDepartamento",
    align: "center",
    ocultar: true,
  },
  {
    id: "resUbicacion",
    label: "Ubicacion",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resUbicacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "resFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "resUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  resId: "",
  resCorreo: [], // input
  resEstado: "ACTIVA", // ACTIVA || INACTIVA
  resCliId: "",
  resCliDocumento: "",
  resCliNombre: "",
  resCliUbicacion: "",
  resCliPuesto: "",
  resCedula: "",
  resNombres: "",
  resTelefono: [],
  resDepartamento: "",
  resUbicacion: "",
  resPersonasAutorizadas: [],
  resUser: "",
  resEmpresa: "",
  resFecReg: "",
  resFecUpd: "",
  regId: 0,
};
