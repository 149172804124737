import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";
import TablaCabecera from "../../../components/TablaCabecera";
import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Novedades");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  const [agregarItemLista, setagregarItemLista] = useState(false);

  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA
  const columnasHijo = [
    {
      id: "nombre",
      align: "left",
      minWidth: 150,
      tipo: "string",
      label: "nombre",
    },
  ];
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.novOrigen.trim() === "" ||
      item.novTipo.trim() === "" ||
      item.novPorcentaje.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        
        tabla: "novedad",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        novUser: usuario.usuario,
        novEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = (agregando) => {
    try {
      if (agregando) {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...row,
            
            tabla: "novedad",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            novUser: usuario.usuario + "/+/" + row[campoExport],
            novEmpresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      } else {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            
            tabla: "novedad",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            novUser: usuario.usuario,
            novEmpresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      }

      seteditar(false);
    } catch (error) {}
  };

  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcSubmit(true);
              },
              disabled: usuario.usuario !== row.novUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.novOrigen}
                name="novOrigen"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["NOVEDAD", "ACTIVIDAD"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.novTipo}
                name="novTipo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.novPorcentaje}
                name="novPorcentaje"
                type={"number"}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {usuario.usuario}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.novOrigen}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.novTipo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.novPorcentaje}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.novUser.split("/+/")[0]}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" component="span">
                  Lista:
                </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  disabled={row.eliminado}
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  input={{ id: "" }}
                  columns={columnasHijo}
                  habilitarOrdenar={false}
                />
                <TableBody component={"div"}>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                    />
                  ) : null}
                  {row.novlista.map((itemHijo, index) => {
                    return (
                      <RowItem
                        itemHijo={itemHijo}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                        socket={socket}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
