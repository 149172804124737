import { Paper, Table, TableBody, TableCell, TableHead } from "@mui/material";
import React from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { columnsHijos } from "../../data";
import { useState } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import Cargando from "../../../components/Cargando";
import RowHijo from "./Componentes/RowHijo";
import { Cancel, ChangeCircle, Refresh } from "@mui/icons-material";
import { usePermiso } from "../../../hooks/usePermiso";
import { useCrud } from "../../../hooks/useCrud";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
const Hijo = (props) => {
  const {
    // ROW
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    // expandir,
    tabla,
    estadoRequest,
    // FUNCION STATE
    getDataState,
    changeObjetoActivoState,
    // SOCKET
    socket,
  } = props;
  const fecha = rowPadre.rolpPeriodo;
  const cliente = "";
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE SINCRONIZACION
  const [
    openConfirmDialogSincronizar,
    setopenConfirmDialogSincronizar,
  ] = useState(false);

  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Rolpagos");
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // STRING DE BUSQUEDA
  const [search] = useState("");
  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columnsHijos.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO BUSCADOR
  const [fullBuscador] = useState(defaultFullBuscador());
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
  });

  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    cliente,
    fecha
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      grupo,
      cliente,
      fecha
    );

    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "hijo",
        cliente,
        fecha
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      setrows([...rows, ...resultado]);
    }
    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES SEARCH SIN ALTERAR
  const funcionReload = async (sincronizar) => {
    setopenConfirmDialogSincronizar(false);
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify({ ...fullBuscador, sincronizar }),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );
    setcargando(false);
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "hijo",
      cliente,
      fecha
    );

    // eslint-disable-next-line
  }, []);
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "rolpUser", usuario);
  useEffect(() => {
    if (!rowPadre.itemSocket) {
      return;
    }
    const { tipo, item } = rowPadre.itemSocket;
    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    // eslint-disable-next-line
  }, [rowPadre]);
  useEffect(() => {
    // Creates a WebSocket connection

    socket.current.on("server:procesar-iess", (data) => {
      mostrarAlerta(data?.msg, "success");
      setcargando(false);
    });

    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <>
      <ConfirmacionDialog
        open={openConfirmDialogSincronizar}
        setopen={setopenConfirmDialogSincronizar}
        categoria="warning"
        titulo={`¿Esta seguro de sincronizar este rol?`}
        botones={[
          {
            tooltip: "Se sincronizaran los roles",
            texto: "SI",
            funcion: () => {
              funcionReload(true);
            },
            disabled: false,
            Icon: Refresh,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogSincronizar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />

      <ToolBarTable
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarPaginacion={false}
        botonesIzquierdos={[
          {
            tooltip: "Sincronizar",
            texto: "Sincronizar",
            funcion: () => {
              funcionReload(true);
            },
            disabled: estadoRequest === "PROCESADO",
            Icon: Refresh,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "boton",
            variante: "text",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "PROCESAR IESS",
            texto: "PROCESAR IESS",
            funcion: () => {
              setcargando(true);
              socket.current.emit("client:procesar-iess", {
                periodo: rowPadre.rolpPeriodo,
                rucempresa: usuario.rucempresa,
              });
            },
            disabled: cargando,
            Icon: ChangeCircle,
            color: "success",
            id: 1,
            ocultar: estadoRequest === "PROCESADO",
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={
          [
            // {
            //   tooltip: "Exportar",
            //   texto: "",
            //   funcion: () => {
            //     setopenDialogOpciones(true);
            //   },
            //   disabled: arrayExport.length === 0,
            //   Icon: ListRounded,
            //   color: "primary",
            //   id: 1,
            //   ocultar: false,
            //   tipo: "icono",
            //   variante: "text",
            //   size: "medium",
            //   sx: { margin: 0, padding: 0 },
            // },
          ]
        }
      />
      <Paper>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TablaCabecera
              rows={rows}
              setarrayExport={setarrayExport}
              campoExport={campoExport}
              columns={columnsHijos}
              habilitarOrdenar={true}
              agrupado={true}
              rowPadre={rowPadre}
              //nuevos
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
              ocultarPrimeraColumna={false}
            />
          </TableHead>
          <TableBody component={"div"}>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columnsHijos.length + 2}
              >
                <Cargando titulo="Cargando..." />
              </TableCell>
            ) : (
              rows.map((rowHijo, index) => {
                return (
                  <RowHijo
                    getDataState={getDataState}
                    // HOOK DE PERMISO
                    tienePermiso={tienePermiso}
                    alertaPermiso={alertaPermiso}
                    // ROW
                    index={index}
                    rowHijo={rowHijo}
                    rowPadre={rowPadre}
                    // OPEN Y EDIT
                    setopen={setopen}
                    seteditar={seteditar}
                    // EXPORT
                    campoExport={campoExport}
                    arrayExport={arrayExport}
                    setarrayExport={setarrayExport}
                    // FUNCION STATE
                    changeObjetoActivoState={changeObjetoActivoState}
                    eliminarState={eliminarState}
                    // SOCKET
                    socket={socket}
                    tabla={tabla}
                    // ESTADO
                    estadoRequest={estadoRequest}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default Hijo;
