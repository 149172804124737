import { useState } from "react";
import clienteAxios from "../../config/axios";

const useMultimediasUpload = () => {
  const [imagenes, setimagenes] = useState([]);
  const [modificoImagenes, setmodificoImagenes] = useState(false);
  const subirImagenes = async (imagenesUpload, fotosSubidas) => {
    const formDataImagenes = new FormData();

    let resFotografias = {
      data: {
        urls: fotosSubidas,
      },
    };
    await imagenesUpload.forEach(async (item, index) => {
      return await formDataImagenes.append(`imagen${index + 1}`, item);
    });
    if (imagenesUpload.length > 0 || modificoImagenes) {
      formDataImagenes.append(`eliminar`, JSON.stringify(fotosSubidas));
      resFotografias = await clienteAxios.post(
        "/multimedias",
        formDataImagenes
      );
    }
    return resFotografias.data.urls;
  };

  return {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  };
};

export default useMultimediasUpload;
