import React, { useState, useContext, useEffect, memo } from "react";

import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Avatar,
  FormControl,
} from "@mui/material";
import Draggable from "react-draggable";
import {
  AddCircle,
  Cancel,
  Delete,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../config/axios";
import AuthContext from "../../../Context/Auth/AuthContext";
import { validarEmail } from "../../../config/validaciones";

// eslint-disable-next-line

function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "50%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const Perfil = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { socket, usuario, cerrarSesion } = useContext(AuthContext);
  //props
  const { open, setopen } = props;
  const [item, setitem] = useState({
    perNombres: "",
    perApellidos: "",
    perTelefono: [],

    perDireccion: "",
    perEmail: [],
    perUserLogin: "",
  });
  const [verpass, setverpass] = useState(false);
  const [newpassword, setnewpassword] = useState("");

  const {
    // perDocTipo,
    // perDocNumero,
    // perPerfil,

    perNombres,
    perApellidos,
    // perEstadoCivil,
    perTelefono,
    // perInstruccion,
    // perCapacitacion,
    perDireccion,
    // perFecNacimiento,
    // perNumContrato,
    // perTipoSangre,
    perEmail,

    // perFoto,
    // perExperiencia,
    // perFecVinculacion,
    // perFormaPago,
    // perRemuneracion,
    // perLugarTrabajo,
    // perAreaDepartamento,
    // perPuestoServicio,
    // perTipoCuenta,
    // perBancoRemuneracion,
    // perFecRetiro,
    // perBonos,
    // perEstado,
 
    // perCargo,
    // perTipoContrato,
    // perLicencias,

    // perCtaBancaria,
    // perEmpresa,

    // perPais,
    // perProvincia,
    // perCanton,
    // perObservacion,
    // perUser,
    // perFecReg,
    // Todos,
    perCodigo,
    perUserLogin,
  } = item;
  const [cargando, setcargando] = useState(false);
  const [archivo, setarchivo] = useState("");
  const [imagenMostrada, setimagenMostrada] = useState(null);
  // eslint-disable-next-line

  // eslint-disable-next-line

  const [error, seterror] = useState({
    perNombres: false,
    perApellidos: false,
    perTelefono: false,

    perDireccion: false,
    perEmail: false,
  });
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      setimagenMostrada(null);
      return setarchivo("");
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  useEffect(() => {
    const getData = async () => {
      const res = await clienteAxios.get(
        `/personas/perfil/${usuario.id ? usuario.id : usuario.perId}`
      );

      setitem({
        ...res.data.data,
        perTelefono:
          typeof res.data.data.perTelefono === "string"
            ? [res.data.data.perTelefono]
            : res.data.data.perTelefono,
      });
    };
    getData();
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setitem({
      perNombres: "",
      perApellidos: "",
      perTelefono: [],
      perDireccion: "",
      perEmail: [],
      perUserLogin: "",
    });
    setopen(false);

    seterror({
      perNombres: false,
      perApellidos: false,
      perTelefono: false,

      perDireccion: false,
      perEmail: false,
    });
    setverpass(false);
    setnewpassword("");

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);

  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      perNombres.trim() === "" ||
      perApellidos.trim() === "" ||
      perTelefono[0].trim() === "" ||
      perDireccion.trim() === "" ||
      perEmail.length === 0 ||
      perEmail.some((email) => {
        return validarEmail(email);
      })
    ) {
      return seterror({
        perNombres: perNombres.trim() === "" ? true : false,
        perApellidos: perApellidos.trim() === "" ? true : false,
        perTelefono: perTelefono[0].trim() === "" ? true : false,

        perDireccion: perDireccion.trim() === "" ? true : false,
        perEmail:
          perEmail.length === 0 ||
          perEmail.some((email) => {
            return validarEmail(email);
          })
            ? true
            : false,
      });
    }
    try {
      let resFoto = "";
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", archivo);
        formDataLogos.append("tipo", "fotoperfil");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        formDataLogos.append("archivoAnterior", item.perFoto);
        resFoto = await clienteAxios.post("/uploads", formDataLogos);
      }

      setcargando(true);

      await clienteAxios.put(
        `/personas/${usuario.id ? usuario.id : usuario.perId}`,
        {
          ...item,
          perFoto: resFoto === "" ? item.perFoto : resFoto.data.nombre,
          newpassword,
        }
      );

      cerrarSesion();

      mostrarAlerta("Usuario editado.", "success");
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }
  if (!item) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Editar Perfil
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                {/* <Box>
                <Button
                  variant={DatosGenerales ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ DatosGenerales: true })}
                >
                  Datos Generales
                </Button>
                <Button
                  variant={DatosLaborales ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ DatosLaborales: true })}
                >
                  Datos Laborales
                </Button>
                <Button
                  variant={HorarioIncapaciad ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ HorarioIncapaciad: true })}
                >
                  Horarios e Incapaciad
                </Button>
              </Box>

              <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <input
                      id="contained-button-file"
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFile}
                    />

                    {/* //si un archivo exoste  */}
                    {archivo ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Remy Sharp"
                            src={imagenMostrada}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          {archivo.name}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Logo"
                            src={item.perFoto}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          No se ha seleccionado ningun archivo
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </>
                <TextField
                  className={clases.formD}
                  size="small"
                  id="perNombres"
                  label="Nombres"
                  value={perNombres}
                  name="perNombres"
                  error={error.perNombres}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  className={clases.formD}
                  size="small"
                  id="perApellidos"
                  label="Apellidos"
                  value={perApellidos}
                  name="perApellidos"
                  error={error.perApellidos}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  disabled
                  className={clases.formD}
                  size="small"
                  id="perCodigo"
                  label="N. Documento"
                  value={perCodigo}
                  name="perCodigo"
                  error={error.perCodigo}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  className={clases.formD}
                  size="small"
                  id="perUserLogin"
                  label="Usuario"
                  value={perUserLogin}
                  name="perUserLogin"
                  error={error.perUserLogin}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Typography
                          color={
                            perUserLogin.length === 10 ? "error" : "secondary"
                          }
                        >
                          {perUserLogin.length} / 10
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: e.target.value
                        .trim()
                        .toLowerCase()
                        .substring(0, 10),
                    })
                  }
                />
                <FormControl className={clases.formAllW}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      color={error.perTelefono ? "error" : "primary"}
                    >
                      Telefonos:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={async () => {
                        setitem({
                          ...item,
                          perTelefono: [...item.perTelefono, ""],
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {perTelefono.map((precio, index) => {
                  return (
                    <TextField
                      key={index}
                      type="number"
                      className={clases.formD}
                      id={index + "teléfono"}
                      value={precio}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perTelefono: perTelefono.map((item, indexItem) => {
                            if (indexItem === index) {
                              return e.target.value;
                            } else {
                              return item;
                            }
                          }),
                        });
                      }}
                      label={`Teléfono #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  perTelefono: perTelefono.filter(
                                    (item, indexItem) => {
                                      return indexItem !== index;
                                    }
                                  ),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
                <TextField
                  className={clases.formD}
                  size="small"
                  id="perDireccion"
                  label="Dirección"
                  value={perDireccion}
                  name="perDireccion"
                  error={error.perDireccion}
                  onChange={(e) => handleChange(e)}
                />
                <FormControl className={clases.formAllW}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      color={error.perEmail ? "error" : "primary"}
                    >
                      Correos:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={async () => {
                        setitem({
                          ...item,
                          perEmail: [...item.perEmail, ""],
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {perEmail.map((email, index) => {
                  return (
                    <TextField
                      type="email"
                      key={index}
                      className={clases.correo}
                      value={email}
                      autoComplete="false"
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perEmail: perEmail.map((item, indexItem) => {
                            if (indexItem === index) {
                              return e.target.value;
                            } else {
                              return item;
                            }
                          }),
                        });
                      }}
                      label={`Correo #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  perEmail: perEmail.filter(
                                    (item, indexItem) => {
                                      return indexItem !== index;
                                    }
                                  ),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
                <TextField
                  type={verpass ? "text" : "password"}
                  className={clases.formD}
                  size="small"
                  label="Nueva Contraseña"
                  value={newpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        {verpass ? (
                          <IconButton
                            aria-label=""
                            onClick={() => setverpass(!verpass)}
                          >
                            <Visibility color="secondary" />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label=""
                            onClick={() => setverpass(!verpass)}
                          >
                            <VisibilityOff color="secondary" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setnewpassword(e.target.value)}
                />
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      handleCrear();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(Perfil);
