import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import { useModalStyle } from "../../styles/styleModal";

import { objectDefault } from "../data";
import Dropzone from "../../components/Dropzone";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAnticipos = (props) => {
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // bitId,
    bitTipoPersona,
    bitDocumento,
    bitNombres,
    bitTelefono,
    bitAsunto,
    bitFotos,
    bitObservacion,
    bitCliId,
    bitCliDocumento,
    bitCliNombre,
    bitCliUbicacion,
    bitCliPuesto,
    // bitEmpresa,
    // bitUser,
    bitEstado,
    bitAutoriza,
    // bitResId,
    bitResCedula,
    bitResNombres,
    // bitResTelefono,
    // bitResDepartamento,
    // bitResUbicacion,
    onlyObservacionEstado,
  } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //  STATE
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const [clientesData, setclientesData] = useState([]);
  const [puestosData, setpuestosData] = useState([]);
  const [residentesData, setresidentesData] = useState([]);
  const [autorizadosData, setautorizadosData] = useState([]);
  const quitarCamposPorRol = usuario.rol.some((rol) =>
    ["GUARDIA", "SUPERVISOR", "ADMINISTRACION"].some(
      (rolNoPermitido) => rolNoPermitido === rol
    )
  );
  // SECCION
  const seccionDefault = {
    seccionDatosPrincipales: true,
    seccionDocumentos: false,
  };
  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const { seccionDatosPrincipales, seccionDocumentos } = seccionActive;
  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);

  // FUNCIONES
  const getDirigidos = async (args, regId) => {
    try {
      const { cliId, cliUbicacion, cliPuesto } = args;

      const resResidentes = await clienteAxios(
        `/residentes/filtro/0?&search=${
          regId
            ? `&regId=${cliId}`
            : `&regId=0&cliId=${cliId}&cliUbicacion=${cliUbicacion}&cliPuesto=${cliPuesto}`
        }`
      );

      setresidentesData(resResidentes.data.data);
      return resResidentes.data.data;
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    const getclientesData = async () => {
      const res = await clienteAxios.get(
        `/clientes/filtro/0?search=${ObjectoActivo.bitCliDocumento}&estado=CLIENTES`
      );
      if (!res.data.data) {
        return;
      }
      setclientesData(res.data.data);
      const itemF = res.data.data.find(
        (itemCliente) => itemCliente.cliId === Number(ObjectoActivo.bitCliId)
      );
      setpuestosData(itemF ? itemF.cliDatosOperativos : []);
    };

    getclientesData();
    const getresidentesData = async () => {
      const resDirigidos = await getDirigidos(
        {
          cliId:
            ObjectoActivo.bitCliId === "" && quitarCamposPorRol
              ? usuario.regId
              : ObjectoActivo.bitCliId,
          cliUbicacion: ObjectoActivo.bitCliUbicacion,
          cliPuesto: ObjectoActivo.bitCliPuesto,
        },
        ObjectoActivo.bitCliId === "" && quitarCamposPorRol
      );
      const itemF = resDirigidos.find(
        (itemResidente) =>
          itemResidente.resId === Number(ObjectoActivo.bitResId)
      );
      setautorizadosData(itemF ? itemF.resPersonasAutorizadas : []);
    };

    if (open) {
      getresidentesData();
    }

    setitem({
      ...ObjectoActivo,
    });
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setimagenes([]);
    setmodificoImagenes(false);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  function tienePropiedadVerdadera(objeto) {
    return Object.values(objeto).some((valor) => valor === true);
  }
  const validacion = () => {
    const errorValidacion = {};

    errorValidacion.bitCliDocumento =
      !quitarCamposPorRol && bitCliDocumento.trim() === "";
    errorValidacion.bitCliUbicacion =
      !quitarCamposPorRol && bitCliUbicacion.trim() === "";
    errorValidacion.bitTipoPersona = bitTipoPersona.trim() === "";
    errorValidacion.bitDocumento = bitDocumento.trim() === "";
    errorValidacion.bitNombres = bitNombres.trim() === "";
    errorValidacion.bitTelefono = bitTelefono.trim() === "";
    errorValidacion.bitAsunto = bitAsunto.trim() === "";
    errorValidacion.bitEstado = bitEstado.trim() === "";
    errorValidacion.bitResCedula =
      !quitarCamposPorRol && bitResCedula.trim() === "";
    errorValidacion.bitAutoriza =
      !quitarCamposPorRol && bitAutoriza.trim() === "";

    if (tienePropiedadVerdadera(errorValidacion)) {
      mostrarAlerta("Faltan campos obligatorios", "error");
      seterror(errorValidacion);
    }

    return tienePropiedadVerdadera(errorValidacion);
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, bitFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "bitacora",
          bitFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          bitUser: usuario.usuario,
          bitEmpresa: usuario.rucempresa,

          regId: usuario.regId,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, bitFotos);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "bitacora",
          bitFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          bitUser: usuario.usuario,
          bitEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} Bitacora.`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionDatosPrincipales
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDatosPrincipales: true })
                    }
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={
                      seccionDocumentos
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDocumentos: true })
                    }
                  >
                    Documentos
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionDatosPrincipales && (
                  <>
                    {quitarCamposPorRol || onlyObservacionEstado ? null : (
                      <>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            options={clientesData.map((item) => {
                              return (
                                item.cliDocNumero +
                                "/+/" +
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={(
                              bitCliNombre +
                              " " +
                              bitCliDocumento
                            ).trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const [
                                  bitCliDocumento,
                                  bitCliNombre,
                                  bitCliId,
                                ] = newValue.split("/+/");
                                const itemF = clientesData.find(
                                  (itemCliente) =>
                                    itemCliente.cliId === Number(bitCliId)
                                );
                                setpuestosData(itemF.cliDatosOperativos);
                                getDirigidos({
                                  cliId: bitCliId,
                                  cliUbicacion: bitCliUbicacion,
                                  cliPuesto: bitCliPuesto,
                                });
                                setitem({
                                  ...item,
                                  bitCliDocumento,
                                  bitCliNombre,
                                  bitCliId,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Cliente"
                                error={error.bitCliDocumento}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    return mostrarAlerta(
                                      "Hubo un error",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disableClearable={true}
                            options={puestosData.map((item) => {
                              return item.ubicacion + "/+/" + item.puesto;
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ");
                            }}
                            id="controlled-demo"
                            value={`${bitCliUbicacion} ${bitCliPuesto}`.trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                try {
                                  const [
                                    bitCliUbicacion,
                                    bitCliPuesto,
                                  ] = newValue.split("/+/");
                                  getDirigidos({
                                    cliId: bitCliId,
                                    cliUbicacion: bitCliUbicacion,
                                    cliPuesto: bitCliPuesto,
                                  });
                                  setitem({
                                    ...item,
                                    bitCliUbicacion,
                                    bitCliPuesto,
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Puesto"
                                error={error.bitCliUbicacion}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    <TextField
                      label="Estado"
                      error={error.bitEstado}
                      value={bitEstado}
                      name="bitEstado"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione</MenuItem>
                      {["PENDIENTE", "INGRESO", "SALIDA", "ANULADA"].map(
                        (item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                    {!onlyObservacionEstado && (
                      <>
                        <TextField
                          label="Tipo Persona"
                          error={error.bitTipoPersona}
                          value={bitTipoPersona}
                          name="bitTipoPersona"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {[
                            "PERSONAL PROPIO",
                            "PROVEEDORES",
                            "VISITANTES",
                            "CONTRATISTA",
                          ].map((item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          label="Documento"
                          error={error.bitDocumento}
                          value={bitDocumento}
                          name="bitDocumento"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        <TextField
                          label="Nombres"
                          error={error.bitNombres}
                          value={bitNombres}
                          name="bitNombres"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        <TextField
                          label="Teléfono"
                          error={error.bitTelefono}
                          value={bitTelefono}
                          name="bitTelefono"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        <TextField
                          label="Asunto"
                          error={error.bitAsunto}
                          value={bitAsunto}
                          name="bitAsunto"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disableClearable={true}
                            options={residentesData.map((item) => {
                              return (
                                item.resCedula +
                                "/+/" +
                                item.resNombres +
                                "/+/" +
                                item.resId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={`${bitResCedula} ${bitResNombres}`.trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");
                                const itemF = residentesData.find(
                                  (itemResidente) =>
                                    itemResidente.resId === Number(options[2])
                                );
                                setautorizadosData(
                                  itemF.resPersonasAutorizadas
                                );
                                setitem({
                                  ...item,
                                  bitResId: itemF.resId,
                                  bitResCedula: itemF.resCedula,
                                  bitResNombres: itemF.resNombres,
                                  bitResTelefono: itemF.resTelefono,
                                  bitResDepartamento: itemF.resDepartamento,
                                  bitResUbicacion: itemF.resUbicacion,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Se dirige"
                                error={error.bitResCedula}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disableClearable={true}
                            options={autorizadosData.map((item) => {
                              return item;
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={`${bitAutoriza}`.trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                setitem({ ...item, bitAutoriza: newValue });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Persona Autorizada"
                                error={error.bitAutoriza}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      label="Observación"
                      error={error.bitObservacion}
                      value={bitObservacion}
                      name="bitObservacion"
                      onChange={(e) => handleChange(e)}
                      className={clases.formAllW}
                    />
                  </>
                )}
                {seccionDocumentos && (
                  <>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={bitFotos}
                    />
                  </>
                )}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
