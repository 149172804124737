function restarPorcentaje(numero, porcentaje) {
  return tresDecimales(((numero - (numero * porcentaje) / 100) * 100) / 100);
}
// function sumarPorcentaje(numero, porcentaje) {
//   return tresDecimales(((numero + (numero * porcentaje) / 100) * 100) / 100);
// }
const tresDecimales = (numero) => {
  // Convertir el número a una string con 3 decimales
  let string = Number(numero).toFixed(3);
  // Convertir la string a un número
  let resultado = Number(string);
  // Devolver el resultado
  return resultado;
};
const dosDecimales = (numero) => {
  // Convertir el número a una string con 3 decimales
  let string = Number(numero).toFixed(2);
  // Convertir la string a un número
  let resultado = Number(string);
  // Devolver el resultado
  return resultado;
};

export const calcularProducto = ({ producto, formPorcentaje = 0, iva = 15 }) => {
  console.log({
    producto,
    formPorcentaje,
    iva,
  });
  const {
    cantidad,
    valUnitarioInterno,
    // valorUnitario = 0,
    // recargoPorcentaje,
    // recargo,
    descPorcentaje = 0,
    // descuento,
    // precioSubTotalProducto,
    // valorIva,
    llevaIva,
    incluyeIva,
  } = producto;

  let resvalorUnitario = 0;

  if (llevaIva === "SI" && incluyeIva === "SI") {
    resvalorUnitario = tresDecimales(valUnitarioInterno / (iva / 100 + 1));
  } else {
    resvalorUnitario = valUnitarioInterno;
  }
  let resdescuento = 0;
  resdescuento = tresDecimales(
    resvalorUnitario -
      restarPorcentaje(resvalorUnitario, Number(descPorcentaje))
  );

  let resprecioSubTotalProducto = 0;
  resprecioSubTotalProducto = tresDecimales(
    restarPorcentaje(resvalorUnitario, Number(descPorcentaje)) * cantidad
  );
  let resrecargo = 0;
  resrecargo = tresDecimales(
    tresDecimales(
      restarPorcentaje(resprecioSubTotalProducto, formPorcentaje * -1)
    ) - resprecioSubTotalProducto
  );
  resprecioSubTotalProducto += resrecargo;
  let resvalorIva = 0;
  if (llevaIva === "SI") {
    resvalorIva = tresDecimales(resprecioSubTotalProducto * (iva / 100));
  }
  console.log({
    ...producto,
    valorUnitario: dosDecimales(resvalorUnitario),
    descuento: dosDecimales(resdescuento),
    precioSubTotalProducto: dosDecimales(resprecioSubTotalProducto),
    valorIva: dosDecimales(resvalorIva),
    recargoPorcentaje: dosDecimales(formPorcentaje),
    costoProduccion: dosDecimales(resvalorUnitario),
    recargo: dosDecimales(resrecargo),
  });
  const resProducto = {
    ...producto,
    valorUnitario: dosDecimales(resvalorUnitario),
    descuento: dosDecimales(resdescuento),
    precioSubTotalProducto: dosDecimales(resprecioSubTotalProducto),
    valorIva: dosDecimales(resvalorIva),
    recargoPorcentaje: dosDecimales(formPorcentaje),
    costoProduccion: dosDecimales(resvalorUnitario),
    recargo: dosDecimales(resrecargo),
  };
  return resProducto;
};
