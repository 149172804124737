import React, { memo, useContext } from "react";
import MuiImageSlider from "mui-image-slider";
import { useState } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { DropzoneDialog } from "react-mui-dropzone";

// MATERIAL
import {
  Box,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Popper,
  MenuItem,
  Typography,
  Dialog,
  Autocomplete,
} from "@mui/material";
import {
  Cancel,
  Check,
  ExpandLess,
  ExpandMore,
  Photo,
  Visibility,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import Cantidades from "../Cantidades/Cantidades";
import { v4 } from "uuid";

const RowAgregarArma = (props) => {
  const { setagregar, setitem, item, bodegaData } = props;
  const classes = useRowStyles();
  const claseCelda = `${classes.celda}`;
  const [open, setOpen] = React.useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [vestimentaData, setvestimentaData] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [expandido, setexpandido] = useState(false);
  const { mostrarAlerta } = useContext(AlertaContext);
  const itemHijoDefecto = {
    idMapeo: v4(),
    tipo: "VESTIMENTAS",
    bodega: bodegaData.length > 0 ? bodegaData[0].bodNombre : "",
    idBodega: bodegaData.length > 0 ? bodegaData[0].bodId : "",
    fotos: [],
    id: "",
    nombre: "",
    serie: "",
    valor: "",
    marca: "",
    modelo: "",
    talla: "",
    color: "",
    stock: "",
    cantidades: [],
  };

  const [itemHijoNuevo, setitemHijoNuevo] = useState(itemHijoDefecto);

  const botonDisabled = () => {
    if (
      itemHijoNuevo.nombre === "" ||
      itemHijoNuevo.marca === "" ||
      itemHijoNuevo.modelo === "" ||
      itemHijoNuevo.talla === "" ||
      itemHijoNuevo.color === "" ||
      itemHijoNuevo.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregar(false);
        setitemHijoNuevo(itemHijoDefecto);
      },
      disabled: cargando,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: async () => {
        await handleAgregar();
        setagregar(false);
        setitemHijoNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Ver fotos",
      texto: "",
      funcion: async () => {
        setOpen(true);
      },
      disabled: false,
      Icon: Photo,
      color: "primary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Expandir",
      texto: "",
      funcion: async () => {
        setexpandido((prev) => !prev);
      },
      disabled: false,
      Icon: expandido ? ExpandLess : ExpandMore,
      color: "primary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const handleAgregar = async () => {
    try {
      setcargando(true);
      const formDataImagenes = new FormData();
      let resDocumentos = {
        data: {
          urls: itemHijoNuevo.fotos,
        },
      };
      await imagenes.map(async (item, index) => {
        return await formDataImagenes.append(`imagen${index + 1}`, item);
      });

      if (imagenes.length > 0) {
        resDocumentos = await clienteAxios.post(
          "/multimedias",
          formDataImagenes
        );
      }

      const disVestimenta = [
        {
          ...itemHijoNuevo,
          fotos: resDocumentos.data.urls,
        },
        ...item.disVestimenta,
      ];

      setitem({
        ...item,
        disVestimenta,
      });
      setimagenes([]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
    }
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <DropzoneDialog
          dialogProps={{ maxWidth: "lg" }}
          dialogTitle={
            <Typography variant="h5" color="primary">
              Subir archivos
            </Typography>
          }
          dropzoneText={
            <>
              <Typography variant="h4" color="primary">
                Arrastra una imagen o da click aqui.
              </Typography>{" "}
            </>
          }
          acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
          cancelButtonText={"Cancelar"}
          submitButtonText={"Aceptar"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            setimagenes(files);
            setOpen(false);
          }}
          showPreviewsInDropzone={true}
          showPreviews={false}
          showAlerts={true}
          showFileNamesInPreview={false}
        />
        <ButtonsAction botones={botones} className={claseCelda} />

        {/* las demas columnas */}
        <>
          <Dialog
            open={Openimagen}
            onClose={() => setOpenimagen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            {itemHijoNuevo.fotos === 0 ? null : (
              <MuiImageSlider
                images={itemHijoNuevo.fotos.map((item) => item.url)}
                fitToImageHeight={true}
              />
            )}
          </Dialog>{" "}
          <TableCell size="small" align="left" className={claseCelda}>
            {itemHijoNuevo.fotos.length === 0 ? (
              "No hay imagenes"
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding="none"
                alignItems="center"
              >
                <IconButton
                  aria-label=""
                  size="small"
                  onClick={() => setOpenimagen(true)}
                  className={classes.margin}
                >
                  <Visibility size="small" color="secondary" />
                </IconButton>
              </Box>
            )}
          </TableCell>{" "}
          <TableCell size="small" align="left" className={claseCelda}>
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={bodegaData.map((item) => {
                  return item.bodNombre + "/+/" + item.bodId;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemHijoNuevo.bodega}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      bodega: options[0],
                      idBodega: options[1],
                    });
                    setvestimentaData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            <TextField
              fullWidth
              size="small"
              id="tipo"
              value={itemHijoNuevo.tipo}
              name="tipo"
              onChange={async (e) => {
                setvestimentaData([]);
                setitemHijoNuevo({
                  ...itemHijoNuevo,
                  tipo: e.target.value,
                });
              }}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["VESTIMENTAS", "UTILITARIOS"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={vestimentaData.map((item) => {
                  return (
                    item.invNombre +
                    "/+/" +
                    item.invSerie +
                    "/+/" +
                    item.invStock +
                    "/+/" +
                    item.invId
                  );
                })}
                getOptionLabel={(option) => {
                  return option
                    .replace("/+/", "** ")
                    .replace("/+/", "** ")
                    .split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  `${itemHijoNuevo.nombre} / ${itemHijoNuevo.serie}`.trim() ===
                  "/"
                    ? ""
                    : `${itemHijoNuevo.nombre} / ${itemHijoNuevo.serie}`
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    const itemF = vestimentaData.find(
                      (itemVestimenta) =>
                        itemVestimenta.invId === Number(options[3])
                    );
                    if (Number(itemF.invStock) === 0) {
                      return mostrarAlerta(
                        "Este producto ya se encuentra distribuido, no hay stock",
                        "error"
                      );
                    }
                    const hayDisponible = itemF.invTotalesStock.some(
                      (stock) => stock.cantidad > 0
                    );
                    if (hayDisponible) {
                      setexpandido(true);
                    }

                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      id: itemF.invId,
                      serie: itemF.invSerie,
                      valor: itemF.invPrecioUnitario,
                      nombre: itemF.invNombre,
                      talla: itemF.invTalla,
                      color: itemF.invColor,
                      marca: itemF.invMarca,
                      modelo: itemF.invModelo,
                      stock: itemF.invStock,
                      cantidades: itemF.invTotalesStock.map((stock) => ({
                        ...stock,
                        max: Number(stock.cantidad),
                        cantidad: Math.min(1, stock.cantidad),
                      })),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      const res = await clienteAxios.get(
                        `/inventario/filtroserie/0?tipo=${itemHijoNuevo.tipo}&bodega=${itemHijoNuevo.bodega}&nombre=${e.target.value}`
                      );
                      setvestimentaData(res.data.data);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {itemHijoNuevo.talla}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {itemHijoNuevo.color}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {itemHijoNuevo.valor}
          </TableCell>
        </>
      </TableRow>
      <Cantidades
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoNuevo}
        setitem={setitemHijoNuevo}
      />
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
