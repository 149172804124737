import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";

import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
import { Download } from "@mui/icons-material";
import Dropzone from "../../../components/Dropzone";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  // MenuItem,
  FormControl,
  InputAdornment,
  Tooltip,
  Button,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { Cancel, CloudUpload, DeleteForever } from "@mui/icons-material";
import CalendarioPlus from "../../../components/CalendarioPlus";
import { objectDefault } from "../data";
import ListPersonas from "../../../components/ListPersonas";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "4vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const [focusedDate, setFocusedDate] = useState();
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const containerRef = React.useRef(null);

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: 500 + ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //       height: "auto",
  //     },
  //   },
  // };
  // function getStylesEspecialidades(name, permisos, theme) {
  //   return {
  //     fontWeight:
  //       permisos.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium,
  //     backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
  //   };
  // }

  //documento
  const [documentoconDocumento, setdocumentoconDocumento] = useState("");
  const refArchivoconDocumento = createRef();
  const focusInputconDocumento = () => {
    refArchivoconDocumento.current.click();
  };
  const onChangeArchivoconDocumento = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoconDocumento("");
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      return mostrarAlerta("El archivo debe ser PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (conDocumento === "") {
        await formDataLogos.append("conDocumento", e.target.files[0]);
      } else {
        arrayEliminar.push({
          nombre: "eliminar",
          url: conDocumento,
        });
        await formDataLogos.append("conDocumento", e.target.files[0]);
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));

      const resDocumentos = await clienteAxios.post(
        "/multiplefiles",
        formDataLogos
      );

      const documentosUrls = {};
      resDocumentos.data.urls.forEach((dataUrl) => {
        if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
          return;
        }
        documentosUrls[dataUrl.nombre] = dataUrl.url;
      });

      setitem({
        ...item,
        conDocumento: documentosUrls["conDocumento"],
      });

      setdocumentoconDocumento(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoconDocumento(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoconDocumento(e.target.files[0]);
  };

  // eslint-disable-next-line
  const {
    // conIdCliente,
    conNombreCliente,
    conAsunto,
    conDetalle,
    conFechasConsigna,
    conFechasConsignaConsultaDB,
    conPrioridad,
    // conProgreso,
    conEstado,
    conFotosCliente,
    conGuardias,
    conSupervisores,
    conDocumento,
    conAdministracion,
    // conLeidos,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    conIdCliente: false,
    conNombreCliente: false,
    conAsunto: false,
    conDetalle: false,
    conDesde: false,
    conHasta: false,
    conFrecuencia: false,
    conPrioridad: false,
    conDiasRepetir: false,
    conGuardias: false,
  });
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  const [guardiasData, setguardiasData] = useState([]);
  const setitemsPersonas = (guardias) => {
    return guardias.map((itemGuardia) => {
      return {
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
        trabajos: [],
        leido: false,
      };
    });
  };
  useEffect(() => {
    if (open && ObjectoActivo.conNombreCliente !== "") {
      const getresguardiasData = async () => {
        try {
          // const clientes = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.conNombreCliente}&estado=CLIENTES`
          // );

          // const clienteF = clientes.find()
          const res = await clienteAxios.get(
            `personas/listado/byIdCliente/0?idCliente=${ObjectoActivo.conIdCliente}`
          );
          setguardiasData(setitemsPersonas(res.data.data));
        } catch (error) {
          setguardiasData([]);
        }
      };

      getresguardiasData();
    }

    setitem(ObjectoActivo);

    // eslint-disable-next-line
  }, [ObjectoActivo, open]);
  useEffect(() => {
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=SUPERVISORES"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }

    // eslint-disable-next-line
  }, []);
  const defaultActive = async () => {
    setFocusedDate(null);
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({
      conIdCliente: false,
      conNombreCliente: false,
      conAsunto: false,
      conDetalle: false,
      conDesde: false,
      conHasta: false,
      conFrecuencia: false,
      conPrioridad: false,
      conDiasRepetir: false,
      conGuardias: false,
    });
    setseccionActive({
      DatosGenerales: true,
      DatosArchivos: false,
      DatosFecha: false,
    });
    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
    setguardiasData([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    DatosArchivos: false,
    DatosFecha: false,
  });
  // eslint-disable-next-line
  const { DatosGenerales, DatosArchivos, DatosFecha } = seccionActive;
  const [seccionActiveSelect, setseccionActiveSelect] = useState({
    guardiasSeccion: true,
    supervisoresSeccion: false,
    administracionSeccion: false,
  });
  const {
    guardiasSeccion,
    supervisoresSeccion,
    administracionSeccion,
  } = seccionActiveSelect;

  // eslint-disable-next-line
  const validacion = () => {
    if (
      conAsunto.trim() === "" ||
      conNombreCliente.trim() === "" ||
      conFechasConsignaConsultaDB.length === 0 ||
      (conGuardias.length === 0 &&
        conSupervisores.length === 0 &&
        conAdministracion.length === 0)
      // ||
      // capaDetalles.trim() === "" ||
    ) {
      seterror({
        conAsunto: conAsunto.trim() === "" ? true : false,
        conNombreCliente: conNombreCliente.trim() === "" ? true : false,
        conGuardias:
          conGuardias.length === 0 &&
          conSupervisores.length === 0 &&
          conAdministracion.length === 0,
        conSupervisores:
          conGuardias.length === 0 &&
          conSupervisores.length === 0 &&
          conAdministracion.length === 0,
        conAdministracion:
          conGuardias.length === 0 &&
          conSupervisores.length === 0 &&
          conAdministracion.length === 0,
        conFechasConsignaConsultaDB:
          conFechasConsignaConsultaDB.length === 0 ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, conFotosCliente);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,

          conFotosCliente: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          conUser: usuario.usuario,
          conEmpresa: usuario.rucempresa,
          tabla: "consigna",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, conFotosCliente);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,

          conFotosCliente: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          conUser: usuario.usuario,
          conEmpresa: usuario.rucempresa,
          tabla: "consigna",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }
  if (!supervisoresData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Consigna.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      DatosGenerales
                        ? "contained"
                        : error.conAsunto ||
                          error.conNombreCliente ||
                          error.conGuardias
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.conAsunto ||
                      error.conNombreCliente ||
                      error.conGuardias
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>

                  <Button
                    variant={
                      DatosFecha
                        ? "contained"
                        : error.conFechasConsignaConsultaDB
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.conFechasConsignaConsultaDB ? "error" : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosFecha: true })}
                  >
                    Fechas
                  </Button>

                  <Button
                    variant={DatosArchivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosArchivos: true })}
                  >
                    Datos Archivos
                  </Button>
                </Box>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={
                        conEstado === "ACTIVO" || conEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
                ref={containerRef}
              >
                <Fade
                  direction="up"
                  in={DatosGenerales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosGenerales ? (
                      <>
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disabled={editar}
                            disableClearable={true}
                            options={clientesData.map((item) => {
                              return (
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliDocNumero +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={conNombreCliente}
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                const res = await clienteAxios.get(
                                  `/personas/listado/:codigo?docnumero=${options[1]}`
                                );
                                setguardiasData(
                                  setitemsPersonas(res.data.data)
                                );

                                setitem({
                                  ...item,
                                  conNombreCliente: options[0],
                                  conIdCliente: options[2],
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                error={error.conNombreCliente}
                                label="Cliente"
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                    });
                                    setclientesData([]);

                                    return mostrarAlerta(
                                      "No hay clientes",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="conAsunto"
                          label="Asunto"
                          value={conAsunto}
                          name="conAsunto"
                          error={error.conAsunto}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          label="Estado"
                          value={conEstado}
                          name="conEstado"
                          error={error.conEstado}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "EN PROCESO",
                            "INCUMPLIDO",
                            "CUMPLIDO",
                            "ANULADO",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        {editar && (
                          <TextField
                            inputProps={{ className: clases.inputPadding }}
                            className={clases.formD}
                            size="small"
                            id="conPrioridad"
                            label="Prioridad"
                            value={conPrioridad}
                            name="conPrioridad"
                            error={error.conPrioridad}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["BAJA", "MEDIA", "ALTA"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        )}
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="conDetalle"
                          label="Detalle"
                          value={conDetalle}
                          name="conDetalle"
                          error={error.conDetalle}
                          onChange={(e) => handleChange(e)}
                          multiline
                          rows={3}
                          variant="outlined"
                        />
                        <Box className={clases.formAllW}>
                          <Box mt={2}>
                            <Button
                              variant={guardiasSeccion ? "contained" : null}
                              disableElevation
                              style={{ padding: "8px" }}
                              color="secondary"
                              onClick={() => {
                                setseccionActiveSelect({
                                  guardiasSeccion: true,
                                });
                              }}
                            >
                              Guardias
                            </Button>
                            <Button
                              variant={supervisoresSeccion ? "contained" : null}
                              disableElevation
                              style={{ padding: "8px" }}
                              color="secondary"
                              onClick={() => {
                                setseccionActiveSelect({
                                  supervisoresSeccion: true,
                                });
                              }}
                            >
                              Supervisores
                            </Button>
                            <Button
                              variant={
                                administracionSeccion ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="secondary"
                              onClick={() => {
                                setseccionActiveSelect({
                                  administracionSeccion: true,
                                });
                              }}
                            >
                              Administración
                            </Button>
                          </Box>
                        </Box>
                        {guardiasSeccion ? (
                          <ListPersonas
                            error={error}
                            item={item}
                            setitem={setitem}
                            data={guardiasData}
                            propiedad={conGuardias}
                            propiedadString={"conGuardias"}
                          />
                        ) : null}
                        {supervisoresSeccion ? (
                          <ListPersonas
                            error={error}
                            item={item}
                            setitem={setitem}
                            data={supervisoresData}
                            propiedad={conSupervisores}
                            propiedadString={"conSupervisores"}
                          />
                        ) : null}
                        {administracionSeccion ? (
                          <ListPersonas
                            error={error}
                            item={item}
                            setitem={setitem}
                            data={administracionData}
                            propiedad={conAdministracion}
                            propiedadString={"conAdministracion"}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </Fade>

                {DatosFecha ? (
                  <>
                    <CalendarioPlus
                      propiedad={conFechasConsigna}
                      propiedadDB={conFechasConsignaConsultaDB}
                      focusedDate={focusedDate}
                      setFocusedDate={setFocusedDate}
                      item={item}
                      setitem={setitem}
                      TextoPropiedad={"conFechasConsigna"}
                      textoPropiedadDB={"conFechasConsignaConsultaDB"}
                      // openCalendario={true}
                      // setopenCalendario={setopenCalendario}
                    />
                  </>
                ) : null}

                {/* DATOS ARCHIVOS */}
                {DatosArchivos ? (
                  <>
                    <>
                      <input
                        className={clases.ocultar}
                        type="file"
                        name="myImage"
                        onChange={onChangeArchivoconDocumento}
                        ref={refArchivoconDocumento}
                      />
                      <TextField
                        error={error.conDocumento}
                        inputProps={{
                          className: clases.inputPadding,
                        }}
                        InputLabelProps={{
                          shrink:
                            documentoconDocumento !== "" ||
                            item.conDocumento !== ""
                              ? true
                              : false,
                        }}
                        className={clases.formD}
                        InputProps={{
                          endAdornment: (
                            <Box htmlFor="contained-button-documento" fullWidth>
                              <InputAdornment>
                                <Tooltip title="Subir Archivo: Documento" arrow>
                                  <Box>
                                    <IconButton
                                      onClick={focusInputconDocumento}
                                      aria-label=""
                                    >
                                      <CloudUpload color="secondary" />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                {item.conDocumento === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          try {
                                            const formDataLogos = new FormData();
                                            let arrayEliminar = [];

                                            arrayEliminar.push({
                                              nombre: "eliminar",
                                              url: conDocumento,
                                            });

                                            await formDataLogos.append(
                                              "eliminar",
                                              JSON.stringify(arrayEliminar)
                                            );
                                            setitem({
                                              ...item,
                                              conDocumento: "",
                                            });
                                            setdocumentoconDocumento("");

                                            await clienteAxios.post(
                                              "/multiplefiles",
                                              formDataLogos
                                            );
                                          } catch (error) {}
                                        }}
                                        aria-label=""
                                      >
                                        <DeleteForever color="error" />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                                {item.conDocumento === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          window.open(item.conDocumento);
                                        }}
                                        aria-label=""
                                      >
                                        <Download />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            </Box>
                          ),
                        }}
                        fullWidth
                        id="conDocumento"
                        label={`Documento`}
                        value={
                          conDocumento && !documentoconDocumento.name
                            ? conDocumento.split("/")[
                                conDocumento.split("/").length - 1
                              ]
                            : documentoconDocumento.name
                            ? documentoconDocumento.name
                            : ""
                        }
                        name="conDocumento"
                      />
                    </>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={conFotosCliente}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={submit() || cargando}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
