export const columns = [
  {
    id: "bitCliDocumento",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitCliDocumento",
    align: "left",
    ocultar: true,
  },
  {
    id: "bitCliUbicacion",
    label: "Ubicación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitCliUbicacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitCliPuesto",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitCliPuesto",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "bitEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFecReg",
    label: "F. Ingreso",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFecUpd",
    label: "F. Salida",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFecUpd",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitTipoPersona",
    label: "Tipo Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitTipoPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitDocumento",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitDocumento",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitNombres",
    label: "Nombres",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitNombres",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitTelefono",
    label: "Telefono",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitTelefono",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFotos",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitObservacion",
    label: "Observacion",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitObservacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "bitUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // bitId: "",
  bitTipoPersona: "PERSONAL PROPIO",
  bitDocumento: "",
  bitNombres: "",
  bitTelefono: "",
  bitAsunto: "",
  bitFotos: [],
  bitObservacion: "",
  bitCliId: "",
  bitCliDocumento: "",
  bitCliNombre: "",
  bitCliUbicacion: "",
  bitCliPuesto: "",
  bitEmpresa: "",
  bitUser: "",
  bitEstado: "INGRESO",
  bitEstadoIngreso: "",
  bitEstadoSalida: "",
  bitAutoriza: "",
  bitResId: "",
  bitResCedula: "",
  bitResNombres: "",
  bitResTelefono: "",
  bitResDepartamento: "",
  bitResUbicacion: "",
  bitUserApruebaIngreso: "",
  bitUserApruebaSalida: "",
  regId: 0,
  bitFecReg: "",
  bitFecUpd: "",
};
