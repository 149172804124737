import React, { useState, useContext, useEffect, memo, Fragment } from "react";

import Draggable from "react-draggable";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  FechaExacta,
  FechaExactaConHoras,
  trimPropsItem,
} from "../../../../config/const";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { AddCircle, Cancel, Delete } from "@mui/icons-material";
import { objectDefault, contenidoDefault } from "../data";

import clienteAxios from "../../../../config/axios";
import { useModalStyle } from "../../../styles/styleModal";
import ListPersonas from "../../../components/ListPersonas";
import { v4 } from "uuid";
import Contenido from "./Contenido";
import CampoDoc from "../../../components/CampoDoc";
const ModalActa = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [guardiasData, setguardiasData] = useState([]);
  const [administracionData, setadministracionData] = useState(null);
  const setitemsPersonas = (personas, tipo) => {
    if (tipo === "CLIENTES") {
      return personas.map((itemGuardia) => {
        return {
          perFoto: itemGuardia.cliFoto,
          perApellidos: itemGuardia.cliRazonSocial,
          perNombres: "",
          perDocNumero: itemGuardia.cliDocNumero,
          perId: itemGuardia.cliId,
        };
      });
    } else {
      return personas.map((itemGuardia) => {
        return {
          perFoto: itemGuardia.perFoto,
          perApellidos: itemGuardia.perApellidos,
          perNombres: itemGuardia.perNombres,
          perDocNumero: itemGuardia.perDocNumero,
          perId: itemGuardia.perId,
        };
      });
    }
  };

  const getClientesData = async (tipo) => {
    try {
      if (tipo === "CLIENTES") {
        const res = await clienteAxios.get(
          `/clientes/filtro/0?search&estado=${tipo}`
        );
        setguardiasData(setitemsPersonas(res.data.data, tipo));
      } else {
        const res = await clienteAxios.get(
          `/personas/filtro/0?search&estado=${tipo}`
        );
        setguardiasData(setitemsPersonas(res.data.data, tipo));
      }
    } catch (error) {}
  };
  useEffect(() => {
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };

    getDataadministracionData();
  }, []);

  const {
    actaTipo,
    actaAsunto,
    actaLugar,
    actaFecha,
    // actaPerId,
    actaPerNombre,
    actaPerDocNumero,
    actaPerfilPara,
    actaGuardias,
    // actaSupervisores,
    // actaAdministracion,
    // actaCliente,
    actaContenido,
    // actaUser,
    // actaEmpresa,
    // actaFecReg,
    actaEstado,
    actaPrioridad,
    actaFechaMaxRespuesta,
  } = item;

  const [error, seterror] = useState({});
  const [seccion, setseccion] = useState("DATOS PRINCIPALES");
  const [seccion2, setseccion2] = useState("");

  useEffect(() => {
    if (ObjectoActivo.actaPerfilPara !== "") {
      getClientesData(ObjectoActivo.actaPerfilPara);
    }
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState({
      ...objectDefault,

      actaPerDocNumero: usuario.codigo,
      actaPerNombre: usuario.nombre,
      actaPerId: usuario.id,
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setguardiasData([]);
    setseccion2("");
  };

  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useModalStyle();

  const validacion = () => {
    if (
      actaPerNombre.trim() === "" ||
      actaFecha.trim() === "" ||
      actaGuardias.length === 0 ||
      actaLugar.trim() === "" ||
      actaAsunto.trim() === ""
    ) {
      seterror({
        actaPerNombre: actaPerNombre.trim() === "",
        actaFecha: actaFecha.trim() === "",
        actaGuardias: actaGuardias.length === 0,
        actaLugar: actaLugar.trim() === "",
        actaAsunto: actaAsunto.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };

  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);
      // const resDocumentos = await subirImagenes(imagenes, actaFotos);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          // actaFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario,
          actaEmpresa: usuario.rucempresa,
          tabla: "acta_entrega_recepcion",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      // const resDocumentos = await subirImagenes(imagenes, actaFotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          //params
          // actaFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario,
          actaEmpresa: usuario.rucempresa,
          tabla: "acta_entrega_recepcion",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  if (!administracionData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Acta.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    disableElevation
                    variant={
                      seccion === "DATOS PRINCIPALES"
                        ? "contained"
                        : error.actaPerNombre ||
                          error.actaFecha ||
                          error.actaGuardias ||
                          error.actaSupervisores ||
                          error.actaAdministracion
                        ? "outlined"
                        : ""
                    }
                    className={clases.buttonTab}
                    color={
                      error.actaPerNombre ||
                      error.actaFecha ||
                      error.actaGuardias ||
                      error.actaSupervisores ||
                      error.actaAdministracion
                        ? "error"
                        : "primary"
                    }
                    onClick={() => setseccion("DATOS PRINCIPALES")}
                  >
                    DATOS PRINCIPALES
                  </Button>
                  <Button
                    disableElevation
                    variant={
                      seccion === "CONTENIDO"
                        ? "contained"
                        : false
                        ? "outlined"
                        : ""
                    }
                    className={clases.buttonTab}
                    color="primary"
                    onClick={() => setseccion("CONTENIDO")}
                  >
                    CONTENIDO
                  </Button>
                  <Button
                    disableElevation
                    variant={
                      seccion === "DOCUMENTOS"
                        ? "contained"
                        : false
                        ? "outlined"
                        : ""
                    }
                    className={clases.buttonTab}
                    color="primary"
                    onClick={() => setseccion("DOCUMENTOS")}
                  >
                    DOCUMENTOS
                  </Button>
                </Box>
                {/* <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={actaEstado === "ACTIVA"}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          actaEstado: e.target.checked ? "ACTIVA" : "ANULADA",
                        });
                      }}
                      name="antoine"
                    />
                  }
                  label="Estado"
                />  */}
                {editar ? (
                  <TextField
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={clases.formD}
                    name="actaEstado"
                    InputProps={{
                      sx: {
                        color:
                          actaEstado === "ANULADO" ||
                          actaEstado === "INCUMPLIDA"
                            ? "red"
                            : actaEstado === "PROCESADO"
                            ? "green"
                            : "orange",
                      },
                    }}
                    label="Estado"
                    value={actaEstado}
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                  >
              
                        <MenuItem value={"ANULADO"} sx={{ color: "red" }}>
                          ANULADO
                        </MenuItem>
                        <MenuItem value={"PROCESADO"} sx={{ color: "green" }}>
                          PROCESADO
                        </MenuItem>
                        <MenuItem value={"INCUMPLIDA"} sx={{ color: "red" }}>
                          INCUMPLIDA
                        </MenuItem>
                
                    <MenuItem value={"EN PROCESO"} sx={{ color: "orange" }}>
                      EN PROCESO
                    </MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={clases.formD}
                    name="actaEstado"
                    InputProps={{
                      sx: {
                        color:
                          actaEstado === "ANULADO" ||
                          actaEstado === "INCUMPLIDA"
                            ? "red"
                            : actaEstado === "PROCESADO"
                            ? "green"
                            : "orange",
                      },
                    }}
                    label="Estado"
                    value={actaEstado}
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                  >
       
                    <MenuItem value={"EN PROCESO"} sx={{ color: "orange" }}>
                      EN PROCESO
                    </MenuItem>
                  </TextField>
                )}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccion === "DATOS PRINCIPALES" && (
                  <>
                    <TextField
                      disabled={editar}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={clases.formD}
                      name="actaTipo"
                      label="Tipo"
                      value={actaTipo}
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={""}>Seleccione un tipo</MenuItem>
                      {[
                        "Informe",
                        "Acta Entrega",
                        "Comunicados",
                        "Oficios",
                      ].map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={clases.formD}
                      name="actaPrioridad"
                      InputProps={{
                        sx: {
                          color:
                            actaPrioridad === "ALTA"
                              ? "blue"
                              : actaPrioridad === "MEDIA"
                              ? "green"
                              : "orange",
                        },
                      }}
                      label="Prioridad"
                      value={actaPrioridad}
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={"ALTA"} sx={{ color: "blue" }}>
                        ALTA
                      </MenuItem>

                      <MenuItem value={"MEDIA"} sx={{ color: "green" }}>
                        MEDIA
                      </MenuItem>

                      <MenuItem value={"BAJA"} sx={{ color: "orange" }}>
                        BAJA
                      </MenuItem>
                    </TextField>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        disabled
                        options={administracionData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={
                          (actaPerNombre + " " + actaPerDocNumero).trim() === ""
                            ? ""
                            : actaPerNombre + " " + actaPerDocNumero
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            try {
                              setitem({
                                ...item,
                                actaPerDocNumero: options[0],
                                actaPerNombre: options[1],
                                actaPerId: options[2],
                              });
                            } catch (error) {}
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="De"
                            error={error.actaPerNombre}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled={editar}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "OTRO") {
                          setguardiasData([]);
                        } else {
                          getClientesData(e.target.value);
                        }
                      }}
                      className={clases.formD}
                      name="actaPerfilPara"
                      label="Perfil"
                      value={actaPerfilPara}
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value={""}>Seleccione un perfil</MenuItem>
                      {[
                        "CLIENTES",
                        "GUARDIAS",
                        "SUPERVISORES",
                        "ADMINISTRACION",
                        "OTRO",
                      ].map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      disabled={editar}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      className={clases.formD}
                      size="small"
                      label="Fecha"
                      value={actaFecha}
                      name="actaFecha"
                      error={error.actaFecha}
                      onChange={(e) => handleChange(e)}
                    />

                    <TextField
                      inputProps={{
                        min: FechaExactaConHoras,
                      }}
                      disabled={
                        editar &&
                        item.actaUser !== usuario.usuario &&
                        !usuario.rol.some(
                          (rol) => rol === "ADMIN" || rol === "ADMINISTRACION"
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      type="datetime-local"
                      className={clases.formD}
                      size="small"
                      label="F. Max Respuesta"
                      value={actaFechaMaxRespuesta.substr(0, 16)}
                      name="actaFechaMaxRespuesta"
                      error={error.actaFechaMaxRespuesta}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={editar}
                      className={clases.formD}
                      size="small"
                      label="Lugar"
                      value={actaLugar}
                      name="actaLugar"
                      error={error.actaLugar}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={editar}
                      className={clases.formD}
                      size="small"
                      label="Asunto"
                      value={actaAsunto}
                      name="actaAsunto"
                      error={error.actaAsunto}
                      onChange={(e) => handleChange(e)}
                    />
                    {actaPerfilPara === "OTRO" ? (
                      <>
                        <FormControl className={clases.formAllW}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle1"
                              sx={{ p: 0, m: 0 }}
                              color={error.actaGuardias ? "error" : "primary"}
                            >
                              Personas:
                            </Typography>
                            <IconButton
                              aria-label=""
                              size="small"
                              onClick={async () => {
                                setitem({
                                  ...item,
                                  actaGuardias: [
                                    ...item.actaGuardias,
                                    {
                                      perFoto: "",
                                      perApellidos: "",
                                      perNombres: "",
                                      perDocNumero: "",
                                      perId: v4(),
                                    },
                                  ],
                                });
                              }}
                            >
                              <AddCircle fontSize="small" color="secondary" />
                            </IconButton>
                          </Box>
                        </FormControl>
                        <Box
                          sx={{
                            width: "100%",
                            maxHeight: "25vh",
                            overflow: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {actaGuardias.map((guardia, index) => {
                            return (
                              <Fragment key={guardia.perId}>
                                <Divider sx={{ width: "100%" }}></Divider>
                                <Box
                                  sx={{
                                    width: "100%",

                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="initial"
                                    fontWeight={"bold"}
                                    className={clases.form20PC}
                                  >
                                    Persona: {index + 1}
                                    <IconButton
                                      aria-label="Boton eliminar"
                                      color="error"
                                      onClick={() => {
                                        setitem({
                                          ...item,
                                          actaGuardias: actaGuardias.filter(
                                            (item, indexItem) =>
                                              item.perId !== guardia.perId
                                          ),
                                        });
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Typography>
                                  <TextField
                                    size="small"
                                    className={clases.form20PC}
                                    value={guardia.perDocNumero}
                                    onChange={(e) => {
                                      setitem({
                                        ...item,
                                        actaGuardias: actaGuardias.map(
                                          (item, indexItem) =>
                                            item.perId === guardia.perId
                                              ? {
                                                  ...item,
                                                  perDocNumero: e.target.value,
                                                }
                                              : item
                                        ),
                                      });
                                    }}
                                    label={`Num. Documento`}
                                  />
                                  <TextField
                                    size="small"
                                    className={clases.form20PC}
                                    value={guardia.perNombres}
                                    onChange={(e) => {
                                      setitem({
                                        ...item,
                                        actaGuardias: actaGuardias.map(
                                          (item, indexItem) =>
                                            item.perId === guardia.perId
                                              ? {
                                                  ...item,
                                                  perNombres: e.target.value,
                                                }
                                              : item
                                        ),
                                      });
                                    }}
                                    label={`Nombre`}
                                  />
                                </Box>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </>
                    ) : (
                      <ListPersonas
                        readOnly={editar}
                        error={error}
                        item={item}
                        setitem={setitem}
                        data={guardiasData}
                        propiedad={actaGuardias}
                        propiedadString={"actaGuardias"}
                      />
                    )}
                  </>
                )}
                {seccion === "CONTENIDO" && (
                  <>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <Box display={"flex"} alignItems={"center"}>
                        AGREGAR CONTENIDO:
                        <IconButton
                          aria-label=""
                          disabled={editar}
                          onClick={() => {
                            const numeroContenido =
                              Object.keys(item.actaContenido).length + 1;

                            setitem({
                              ...item,
                              actaContenido: {
                                ...item.actaContenido,
                                [`contenido${numeroContenido}`]: {
                                  ...contenidoDefault,
                                  titulo: `Contenido ${numeroContenido}`,
                                  id: v4(),
                                },
                              },
                            });
                          }}
                          color="primary"
                        >
                          <AddCircle />
                        </IconButton>
                      </Box>
                    </Divider>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" center"
                      width="100%"
                    >
                      <Box display={"flex"} flexWrap={"wrap"}>
                        {Object.entries(actaContenido).map(
                          ([key, contenido]) => {
                            return (
                              <Button
                                key={key}
                                disableElevation
                                variant={
                                  seccion2 === key
                                    ? "contained"
                                    : false
                                    ? "outlined"
                                    : ""
                                }
                                className={clases.buttonTab}
                                color={false ? "error" : "primary"}
                                onClick={() => setseccion2(key)}
                              >
                                {contenido.titulo}
                              </Button>
                            );
                          }
                        )}
                      </Box>

                      {seccion2 !== "" && (
                        <IconButton
                          aria-label=""
                          onClick={async () => {
                            const entries = Object.entries(
                              actaContenido
                            ).filter(([key, contenido]) => {
                              return key !== seccion2;
                            });

                            setitem({
                              ...item,
                              actaContenido: Object.fromEntries(entries),
                            });
                            try {
                              const formDataLogos = new FormData();
                              let arrayEliminar = [];

                              arrayEliminar.push({
                                nombre: "eliminar",
                                url: item.actaContenido[seccion2].foto,
                              });

                              await formDataLogos.append(
                                "eliminar",
                                JSON.stringify(arrayEliminar)
                              );

                              await clienteAxios.post(
                                "/multiplefiles",
                                formDataLogos
                              );
                            } catch (error) {}
                            setseccion2("");
                          }}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Box>
                    <Divider width="100%" />
                    <Contenido
                      disabled={editar}
                      seccion2={seccion2}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                )}
                {seccion === "DOCUMENTOS" && (
                  <>
                    <CampoDoc
                      disabled={editar}
                      disabledE={editar}
                      setcargando={setcargando}
                      label="Documento 1"
                      propiedad={"actaDocumento1"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    <CampoDoc
                      disabled={editar}
                      disabledE={editar}
                      setcargando={setcargando}
                      label="Documento 2"
                      propiedad={"actaDocumento2"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                  </>
                )}
                {/* actaFotos */}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalActa);
