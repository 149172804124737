import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

import { Download } from "@mui/icons-material";
// eslint-disable-next-line
import Dropzone from "../../components/Dropzone";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControl,
  Tooltip,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Cancel, CloudUpload } from "@mui/icons-material";
import { objectDefault } from "../data";
import { objectDefault as objectDefaultTurnoExtra } from "../../TurnoExtra/data";
import ModalTurnoExtra from "../../TurnoExtra/Componentes/Modal";
import { useModalStyle } from "../../styles/styleModal";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import TableDias from "./TableDias";
import TableTurnos from "./TableTurnos";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = ({
  editar,
  seteditar,
  setopen,
  open,
  ObjectoActivo,
  changeObjetoActivoState,
  socket,
}) => {
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  // eslint-disable-next-line
  const [turnos, setturnos] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const containerRef = React.useRef(null);

  const [openTurnoExtra, setopenTurnoExtra] = useState(false);
  const [editarTurnoExtra, seteditarTurnoExtra] = useState(false);
  //documento
  const [documentoinvausDocumento, setdocumentoinvausDocumento] = useState("");
  const refArchivoinvausDocumento = createRef();
  const focusInputinvausDocumento = () => {
    refArchivoinvausDocumento.current.click();
  };
  const onChangeArchivoinvausDocumento = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoinvausDocumento("");
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      return mostrarAlerta("El archivo debe ser PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (permiDocumento === "") {
        await formDataLogos.append("permiDocumento", e.target.files[0]);
      } else {
        arrayEliminar.push({
          nombre: "eliminar",
          url: permiDocumento,
        });
        await formDataLogos.append("permiDocumento", e.target.files[0]);
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));

      const resDocumentos = await clienteAxios.post(
        "/multiplefiles",
        formDataLogos
      );

      const documentosUrls = {};
      resDocumentos.data.urls.forEach((dataUrl) => {
        if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
          return;
        }
        documentosUrls[dataUrl.nombre] = dataUrl.url;
      });

      setitem({
        ...item,
        permiDocumento: documentosUrls["permiDocumento"],
      });

      setdocumentoinvausDocumento(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoinvausDocumento(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoinvausDocumento(e.target.files[0]);
  };

  // eslint-disable-next-line
  const {
    // permiId,
    // permiIdMulta,
    // permiIdsTurnoExtra,
    permiMotivo,
    permiEstado,
    permiDetalle,
    permiStatusDescripcion,
    permiDocumento,
    permitFotos,
    // permiUser,
    // permiEmpresa,
    // permiFecReg,
    // permiFecUpd,
    // permiIdPersona,
    cedula,
    nombres,
    dias,
    // idCliente,
    // ruc,
    razonSocial,
    // permiIdDOperativo, DESDE HORARIOS
    // ubicacion, DESDE HORARIOS
    // puesto, DESDE HORARIOS
    // permiIdsJornadaLaboral,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  useEffect(() => {
    if (editar) {
      const getTurnos = async () => {
        const res = await clienteAxios.get(
          `/turnos_extras/byIds/${ObjectoActivo.permiIdsTurnoExtra?.join(",")}`
        );

        setturnos(res.data.data);
      };
      getTurnos();
    }
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosPrincipales: true,
    });
    setdocumentoinvausDocumento("");
    setturnos([]);
    setmodificoImagenes(false);
    setcargando(false);
    setimagenes([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const validacion = () => {
    // SI NO TODOS TIENE TURNO DARA TRUE
    const existeDiaSinTurno = !dias.every((dia) => {
      let tieneTurno = false;

      //*DEVOLVER true si existe un dia que no se encuentre en turnos
      turnos.forEach((turno) => {
        const existeEnEsteTurno = turno.dias.some(
          (turnoDia) => turnoDia.id === dia.id
        );
        if (existeEnEsteTurno) {
          tieneTurno = true;
        }
      });
      return tieneTurno;
    });
    if (permiMotivo.trim() === "" || existeDiaSinTurno) {
      seterror({
        permiMotivo: permiMotivo.trim() === "",
        existeDiaSinTurno,
      });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    DatosFechas: false,
    Apelacion: false,
  });
  // eslint-disable-next-line
  const { DatosPrincipales, DatosFechas, Apelacion } = seccionActive;
  // eslint-disable-next-line
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    setcargando(true);
    try {
      const resDocumentos = await subirImagenes(imagenes, permitFotos);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          permiIdsTurnoExtra: turnos.map((turno) => turno.turnId),
          permiIdsJornadaLaboral: dias.map((itemDia) => itemDia.id),

          permitFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          permiUser: usuario.usuario,
          permiEmpresa: usuario.rucempresa,
          tabla: "permiso",
          // idTurno: itemsTurnos.map((itemTurno) => itemTurno.turId),
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, permitFotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          permiIdsTurnoExtra: turnos.map((turno) => turno.turnId),
          permiIdsJornadaLaboral: dias.map((itemDia) => itemDia.id),

          permitFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          permiUser: usuario.usuario,
          permiEmpresa: usuario.rucempresa,
          tabla: "permiso",
          // idTurno: itemsTurnos.map((itemTurno) => itemTurno.turId),
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivoTurnoExtra, setObjetoActivoTurnoExtra] = useState(
    objectDefaultTurnoExtra
  );
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoStateTurnoExtra = (item) => {
    setObjetoActivoTurnoExtra(item);
  };
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
      socket.current.on("server:eliminadoExitoso", (data) => {
        setitemSocket({
          tipo: "eliminar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }
    if (item.tabla !== "turno_extra") {
      return;
    }
    if (usuario.usuario !== item.turnUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    if (tipo === "agregar") {
      setturnos((prev) => [item, ...prev]);
    }
    if (tipo === "editar") {
    }
    if (tipo === "eliminar") {
      setturnos((prev) => prev.filter((turno) => turno.turnId !== item.turnId));
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalTurnoExtra
          open={openTurnoExtra}
          setopen={setopenTurnoExtra}
          editar={editarTurnoExtra}
          seteditar={seteditarTurnoExtra}
          ObjectoActivo={ObjectoActivoTurnoExtra}
          changeObjetoActivoState={changeObjetoActivoStateTurnoExtra}
          socket={socket}
          desdeAusencia={true}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Permiso.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      DatosPrincipales
                        ? "contained"
                        : error.cedula || error.permiMotivo || error.idTurno
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.cedula || error.permiMotivo || error.idTurno
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={
                      DatosFechas
                        ? "contained"
                        : error.ausFechasConsultaDB
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.ausFechasConsultaDB ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosFechas: true })}
                  >
                    Fechas
                  </Button>

                  {editar ? (
                    <Button
                      variant={Apelacion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ Apelacion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>
                <Box>
                  <Typography
                    fontWeight={"bold"}
                    variant="body1"
                    color={error.idTurno ? "error" : "primary"}
                  >
                    Dias necesarios:
                    <Typography component="span">
                      {/* {itemsTurnos.length === 0
                        ? 0
                        : itemsTurnos
                            .map((row) => {
                              return Number(row.turDiasTurno);
                            })
                            .reduce(reducer)}
                      /{ausFechasConsultaDB.length} */}
                    </Typography>
                  </Typography>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                <Fade
                  direction="up"
                  in={DatosPrincipales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    <Box display="flex" width="100%"></Box>
                    {DatosPrincipales ? (
                      <>
                        {/* PERSONAS */}
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            options={personasData.map((item) => {
                              return (
                                item.perDocNumero +
                                "/+/" +
                                item.perApellidos +
                                " " +
                                item.perNombres +
                                "/+/" +
                                item.perId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (cedula + " " + nombres).trim() === ""
                                ? ""
                                : cedula + " " + nombres
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                try {
                                  const itemF = personasData.find(
                                    (itemPersona) => {
                                      return (
                                        itemPersona.perId === Number(options[2])
                                      );
                                    }
                                  );

                                  if (itemF.perDocuCliente === "") {
                                    return mostrarAlerta(
                                      "Es probable que esta persona no tenga cliente",
                                      "warning"
                                    );
                                  }

                                  setitem({
                                    ...item,
                                    cedula: itemF.perDocNumero,
                                    nombres:
                                      itemF.perApellidos +
                                      " " +
                                      itemF.perNombres,
                                    permiIdPersona: itemF.perId,
                                    idCliente: itemF.perIdCliente,
                                    ruc: itemF.perDocuCliente,
                                    razonSocial: itemF.perNombreCliente,
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                size="small"
                                required
                                fullWidth
                                label="Persona"
                                error={error.cedula}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/personas/filtro/0?search=${e.target.value}`
                                    );

                                    setpersonasData(res.data.data);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                      cedula: "",
                                      nombres: "",
                                      permiIdPersona: "",
                                      ausPuesto: [],
                                    });
                                    setpersonasData([]);

                                    return mostrarAlerta(
                                      "No hay personas",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        {/* PUESTOS ACTUALES */}
                        <TextField
                          variant="standard"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="permiMotivo"
                          label="Motivo"
                          value={permiMotivo}
                          name="permiMotivo"
                          error={error.permiMotivo}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                          InputProps={{
                            endAdornment: <React.Fragment></React.Fragment>,
                          }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "ENFERMEDAD IESS",
                            "PERMISO PERSONAL",
                            "PATERNIDAD",
                            "DEFUNCION FAMILIAR",
                            "INJUSTIFICADA",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          disabled
                          inputProps={{
                            className: clases.inputPadding,
                          }}
                          className={clases.formD}
                          size="small"
                          label="Cliente"
                          value={razonSocial}
                          name="razonSocial"
                          error={error.razonSocial}
                          onChange={(e) => handleChange(e)}
                        />

                        <>
                          <input
                            className={clases.ocultar}
                            type="file"
                            name="myImage"
                            onChange={onChangeArchivoinvausDocumento}
                            ref={refArchivoinvausDocumento}
                          />
                          <TextField
                            variant="standard"
                            error={error.permiDocumento}
                            inputProps={{
                              className: clases.inputPadding,
                            }}
                            InputLabelProps={{
                              shrink:
                                documentoinvausDocumento !== "" ||
                                item.permiDocumento !== ""
                                  ? true
                                  : false,
                            }}
                            className={clases.formD}
                            InputProps={{
                              endAdornment: (
                                <Box
                                  htmlFor="contained-button-documento"
                                  fullWidth
                                >
                                  <InputAdornment>
                                    <Tooltip
                                      title="Subir Archivo: Documento"
                                      arrow
                                    >
                                      <Box>
                                        <IconButton
                                          onClick={focusInputinvausDocumento}
                                          aria-label=""
                                        >
                                          <CloudUpload color="secondary" />
                                        </IconButton>
                                      </Box>
                                    </Tooltip>

                                    {item.permiDocumento === "" ? null : (
                                      <Tooltip
                                        title="Descargar Archivo: Documento"
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            color="primary"
                                            onClick={async () => {
                                              window.open(item.permiDocumento);
                                            }}
                                            aria-label=""
                                          >
                                            <Download />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </InputAdornment>
                                </Box>
                              ),
                            }}
                            fullWidth
                            id="permiDocumento"
                            label={`Documento`}
                            value={
                              permiDocumento && !documentoinvausDocumento.name
                                ? permiDocumento.split("/")[
                                    permiDocumento.split("/").length - 1
                                  ]
                                : documentoinvausDocumento.name
                                ? documentoinvausDocumento.name
                                : ""
                            }
                            name="permiDocumento"
                          />
                        </>
                        <TextField
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="permiDetalle"
                          label="Detalle"
                          value={permiDetalle}
                          name="permiDetalle"
                          error={error.permiDetalle}
                          onChange={(e) => handleChange(e)}
                        />
                        <>
                          <Dropzone
                            imagenes={imagenes}
                            setimagenes={(e) => {
                              setmodificoImagenes(true);
                              setimagenes(e);
                            }}
                            fotosItem={permitFotos}
                          />
                        </>
                        {/* 
                      <TextField
                      variant="standard"
                        type={"date"}
                        InputLabelProps={{ shrink: true }}
                      inputProps={{
                          className: clases.inputPadding,
                          min: FechaExacta,
                        }}
                        className={clases.formD}
                        size="small"
                        id="camFecha"
                        label="Fecha"
                        value={camFecha}
                        name="camFecha"
                        error={error.camFecha}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      </>
                    ) : null}
                  </Box>
                </Fade>
                {DatosFechas ? (
                  <>
                    <TableDias
                      dias={dias}
                      itemPermiso={item}
                      socket={socket}
                      turnos={turnos}
                    />
                    {turnos.length > 0 && (
                      <>
                        <Divider className={clases.formAllW}>
                          <Typography variant="body1" color="primary">
                            Turnos Extra{" "}
                            {error.existeDiaSinTurno && (
                              <Typography
                                variant="body2"
                                color="error"
                                component={"span"}
                              >
                                Cada dia seleccionado debe aparecer en un turno
                                extra.
                              </Typography>
                            )}
                          </Typography>
                        </Divider>
                        <TableTurnos turnos={turnos} socket={socket} />
                      </>
                    )}
                  </>
                ) : null}
                <Fade direction="up" in={Apelacion} style={{ width: "100%" }}>
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    <Box display="flex" width="100%"></Box>
                    {Apelacion ? (
                      <>
                        <Box
                          className={clases.formAllW}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Divider style={{ width: "42%" }} />
                          <Typography
                            style={{ width: "100%" }}
                            variant="button"
                            color="primary"
                            align="center"
                            component={"span"}
                          >
                            Verificación
                          </Typography>
                          <Divider style={{ width: "42%" }} />
                        </Box>
                        <TextField
                          disabled={ObjectoActivo.permiEstado === "ASIGNADA"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="permiEstado"
                          label="Estado"
                          value={permiEstado}
                          name="permiEstado"
                          error={error.permiEstado}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem disabled value="">
                            Seleccione.
                          </MenuItem>
                          {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          disabled={ObjectoActivo.permiEstado === "ASIGNADA"}
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="permiStatusDescripcion"
                          label="Status"
                          value={permiStatusDescripcion}
                          name="permiStatusDescripcion"
                          error={error.permiStatusDescripcion}
                          onChange={(e) => handleChange(e)}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    ((ObjectoActivo.permiEstado === "ANULADA" ||
                      ObjectoActivo.permiEstado === "APROBADO") &&
                      editar &&
                      (!usuario.rol.includes("ADMIN") ||
                        (ObjectoActivo.permiEstado === "APROBADO" &&
                          item.permiEstado === "ANULADA")))
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
