import React, { useState, useContext, useEffect, memo } from "react";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton, FormControlLabel, Checkbox } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import clienteAxios from "../../../config/axios";
import Editor from "../../../components/Extra/Editor";
import { useModalStyle } from "../../styles/styleModal";
import Consignas from "../../Personas/Consignas/Consignas";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalData = (props) => {
  // const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [guardiasData, setguardiasData] = useState([]);
  const [puestosData, setpuestosData] = useState([]);
  const [buscador, setbuscador] = useState("");
  const setitemsPersonas = (guardias, supervisor) => {
    return guardias.map((itemGuardia) => {
      return {
        foto: itemGuardia.perFoto,
        nombre: itemGuardia.perApellidos + " " + itemGuardia.perNombres,
        documento: itemGuardia.perDocNumero,
        id: itemGuardia.perId,
        supervisor,
      };
    });
  };
  const [seccionActive, setseccionActive] = useState({
    DatosSeccion: true,
    ObservacionesSeccion: false,
    NoConformidadesSeccion: false,
  });
  const {
    DatosSeccion,
    ObservacionesSeccion,
    NoConformidadesSeccion,
  } = seccionActive;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const [clientesData, setclientesData] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const {
    // fisCliId,
    fisFechaFiscalizacion,
    fisPuestoServicio,
    // fisCumplimientoGuardias,
    // fisCumplimientoSupervisor,
    // fisObservacionesCliente,
    // fisNoConformidades,
    // fisUser,
    // fisEmpresa,
    // fisFecReg,
    fisCliDocNumero,
    fisCliRazonSocial,
    fisGuardias,
    fisNoConformidades,
  } = item;

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [supervisoresData, setsupervisoresData] = useState(null);
  useEffect(() => {
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=SUPERVISORES"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    if (open) {
      const getresguardiasData = async () => {
        try {
          const itemF = await clienteAxios.get(
            `/clientes/filtro/0?search=${ObjectoActivo.fisCliDocNumero}&estado=CLIENTES`
          );
          const res = await clienteAxios.get(
            `/personas/listado/:codigo?docnumero=${ObjectoActivo.fisCliDocNumero}`
          );

          setguardiasData(setitemsPersonas(res.data.data));
          setpuestosData(itemF.data.data[0].cliDatosOperativos);
        } catch (error) {
          setguardiasData([]);
          setpuestosData([]);
        }
      };

      if (editar) {
        getresguardiasData();
      }

      setitem(ObjectoActivo);
    }
    // eslint-disable-next-line
  }, [ObjectoActivo, open]);
  const defaultActive = (args) => {
    changeObjetoActivoState({
      // fisId: 1,
      fisCliId: "",
      fisFechaFiscalizacion: FechaExacta,
      fisPuestoServicio: "",
      fisCumplimientoGuardias: "",
      fisCumplimientoSupervisor: "",
      fisObservacionesCliente: "",
      fisNoConformidades: [],
      fisGuardias: [],
      fisUser: "",
      fisEmpresa: "",
      fisFecReg: "",
      fisCliDocNumero: "",
      fisCliRazonSocial: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setguardiasData([]);
    setpuestosData([]);
    setbuscador("");
    if (fisNoConformidades.length > 0 && args?.eliminarConsigna && !editar) {
      try {
        socket.current.emit("client:eliminarDataSeleccionado", {
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "consigna",
          lista: fisNoConformidades,
        });
      } catch (error) {}
    }
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const validacion = () => {
    if (
      fisCliDocNumero.trim() === "" ||
      fisFechaFiscalizacion.trim() === "" ||
      fisPuestoServicio.trim() === "" ||
      fisGuardias.length === 0
    ) {
      seterror({
        fisCliDocNumero: fisCliDocNumero.trim() === "",
        fisFechaFiscalizacion: fisFechaFiscalizacion.trim() === "",
        fisPuestoServicio: fisPuestoServicio.trim() === "",
        fisGuardias: fisGuardias.length === 0,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      // let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "fiscalizacion",
          fisUser: usuario.usuario,
          fisEmpresa: usuario.rucempresa,
          // exaFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      // let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "fiscalizacion",
          fisUser: usuario.usuario,
          fisEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
 

  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" FOP"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive({ eliminarConsigna: true });
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      DatosSeccion
                        ? "contained"
                        : error.fisCliDocNumero ||
                          error.fisFechaFiscalizacion ||
                          error.fisPuestoServicio ||
                          error.fisGuardias
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.fisCliDocNumero ||
                      error.fisFechaFiscalizacion ||
                      error.fisPuestoServicio ||
                      error.fisGuardias
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosSeccion: true })}
                  >
                    Datos
                  </Button>
                  <Button
                    variant={
                      ObservacionesSeccion
                        ? "contained"
                        : error.fisCumplimientoGuardias ||
                          error.fisCumplimientoSupervisor ||
                          error.fisObservacionesCliente
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.fisCumplimientoGuardias ||
                      error.fisCumplimientoSupervisor ||
                      error.fisObservacionesCliente
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ ObservacionesSeccion: true })
                    }
                  >
                    Observaciones
                  </Button>
                  <Button
                    variant={NoConformidadesSeccion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.invBodega ? "error" : "secondary"}
                    onClick={() =>
                      setseccionActive({ NoConformidadesSeccion: true })
                    }
                  >
                    No Conformidades
                  </Button>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mt={1}
                maxHeight={"60vh"}
                overflow="scroll"
                sx={{ overflowX: "hidden" }}
              >
                {DatosSeccion ? (
                  <>
                    {/* CLIENTES */}
                    <FormControl
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Autocomplete
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          (fisCliRazonSocial + " " + fisCliDocNumero).trim() ===
                          ""
                            ? ""
                            : fisCliRazonSocial + " " + fisCliDocNumero
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = clientesData.find(
                              (itemCliente) =>
                                itemCliente.cliId === Number(options[2])
                            );
                            const res = await clienteAxios.get(
                              `/personas/listado/:codigo?docnumero=${options[1]}`
                            );

                            setbuscador("");
                            setguardiasData(setitemsPersonas(res.data.data));
                            setpuestosData(itemF.cliDatosOperativos);
                            try {
                              setitem({
                                ...item,
                                fisCliId: options[2],
                                fisCliDocNumero: options[1],
                                fisCliRazonSocial: options[0],
                                fisGuardias: [],
                                fisPuestoServicio: "",
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Selección   Cliente"
                            error={error.fisCliDocNumero}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* fisCliId */}
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="F. Fiscalizacion"
                      value={fisFechaFiscalizacion}
                      name="fisFechaFiscalizacion"
                      error={error.fisFechaFiscalizacion}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    {/* CLIENTES */}
                    <FormControl
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Autocomplete
                        disableClearable={true}
                        options={puestosData.map((item) => {
                          return item.ubicacion + " - " + item.puesto;
                        })}
                        id="controlled-demo"
                        value={fisPuestoServicio}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              setitem({
                                ...item,
                                fisPuestoServicio: newValue,
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Puesto"
                            error={error.fisCliDocNumero}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Divider className={clases.formAllW} />
                    <Box
                      display={"flex"}
                      alignItems="center"
                      className={clases.formAllW}
                    >
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent={"center"}
                        mt={1}
                        maxHeight={"60vh"}
                        overflow="scroll"
                        sx={{ overflowX: "hidden" }}
                        className={clases.formD}
                      >
                        <Typography
                          variant="body1"
                          color={error.fisGuardias ? "error" : "initial"}
                          className={clases.formD}
                        >
                          {fisGuardias.length === 0 || fisGuardias.length === 1
                            ? "Seleccionado: "
                            : "Seleccionados: "}
                          {fisGuardias.length}{" "}
                          <FormControlLabel
                            label=""
                            control={
                              <Checkbox
                                checked={
                                  [...guardiasData, ...supervisoresData]
                                    .length === 0
                                    ? false
                                    : buscador.trim() === ""
                                    ? [...guardiasData, ...supervisoresData]
                                        .map((itemGuardia) => itemGuardia.id)
                                        .every((elemento) =>
                                          fisGuardias
                                            .map(
                                              (itemelemento) => itemelemento.id
                                            )
                                            .includes(elemento)
                                        )
                                    : [...guardiasData, ...supervisoresData]
                                        .filter(
                                          (itemGuardiaData) =>
                                            itemGuardiaData.nombre
                                              .toLowerCase()
                                              .includes(
                                                buscador.toLowerCase()
                                              ) ||
                                            itemGuardiaData.documento
                                              .toLowerCase()
                                              .includes(buscador.toLowerCase())
                                        )
                                        .map((itemGuardia) => itemGuardia.id)
                                        .every((elemento) =>
                                          fisGuardias
                                            .map(
                                              (itemelemento) => itemelemento.id
                                            )
                                            .includes(elemento)
                                        )
                                }
                                onChange={(e) => {
                                  if (buscador.trim() === "") {
                                    if (e.target.checked) {
                                      setitem({
                                        ...item,
                                        fisGuardias: [
                                          ...guardiasData,
                                          ...supervisoresData,
                                        ],
                                      });
                                    } else {
                                      setitem({
                                        ...item,
                                        fisGuardias: [],
                                      });
                                    }
                                  } else {
                                    if (e.target.checked) {
                                      setitem({
                                        ...item,
                                        fisGuardias: [
                                          ...guardiasData,
                                          ...supervisoresData,
                                        ].filter(
                                          (itemGuardiaData) =>
                                            itemGuardiaData.nombre
                                              .toLowerCase()
                                              .includes(
                                                buscador.toLowerCase()
                                              ) ||
                                            itemGuardiaData.documento
                                              .toLowerCase()
                                              .includes(buscador.toLowerCase())
                                        ),
                                      });
                                    } else {
                                      setitem({
                                        ...item,
                                        fisGuardias: [],
                                      });
                                    }
                                  }
                                }}
                                color="primary"
                              />
                            }
                          />
                        </Typography>
                      </Box>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Buscar"
                        value={buscador}
                        onChange={(e) => {
                          setbuscador(e.target.value);
                        }}
                      />
                    </Box>
                    <List className={clases.formAllW} dense>
                      {buscador.trim() === ""
                        ? [...guardiasData, ...supervisoresData].map(
                            (value) => {
                              const checked = fisGuardias.some(
                                (itemGuardia) => itemGuardia.id === value.id
                              );
                              const labelId = `checkbox-list-secondary-label-${value}`;
                              return (
                                <ListItem
                                  key={value.id}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setitem({
                                            ...item,
                                            fisGuardias: [
                                              ...item.fisGuardias,
                                              value,
                                            ],
                                          });
                                        } else {
                                          setitem({
                                            ...item,
                                            fisGuardias: item.fisGuardias.filter(
                                              (itemFisguardia) =>
                                                itemFisguardia.id !== value.id
                                            ),
                                          });
                                        }
                                      }}
                                      checked={checked}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={`Avatar n°${value + 1}`}
                                        src={`${value.foto}`}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      id={labelId}
                                      primary={`${value.nombre}`}
                                      primaryTypographyProps={{
                                        color: value.supervisor
                                          ? "primary"
                                          : "secondary",
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            }
                          )
                        : [...guardiasData, ...supervisoresData]
                            .filter(
                              (itemGuardiaData) =>
                                itemGuardiaData.nombre
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase()) ||
                                itemGuardiaData.documento
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase())
                            )
                            .map((value) => {
                              const checked = fisGuardias.some(
                                (itemGuardia) => itemGuardia.id === value.id
                              );
                              const labelId = `checkbox-list-secondary-label-${value}`;
                              return (
                                <ListItem
                                  key={value.id}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setitem({
                                            ...item,
                                            fisGuardias: [
                                              ...item.fisGuardias,
                                              value,
                                            ],
                                          });
                                        } else {
                                          setitem({
                                            ...item,
                                            fisGuardias: item.fisGuardias.filter(
                                              (itemFisguardia) =>
                                                itemFisguardia.id !== value.id
                                            ),
                                          });
                                        }
                                      }}
                                      checked={checked}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={`Avatar n°${value + 1}`}
                                        src={`${value.foto}`}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      id={labelId}
                                      primary={`${value.nombre}`}
                                      primaryTypographyProps={{
                                        color: value.supervisor
                                          ? "primary"
                                          : "secondary",
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                    </List>
                  </>
                ) : null}
                {ObservacionesSeccion ? (
                  <>
                    <Editor
                      label={
                        error.fisCumplimientoGuardias ? (
                          <>
                            <Typography
                              variant="body1"
                              color="error"
                              component={"span"}
                            >
                              Cumplimiento Guardias
                            </Typography>
                          </>
                        ) : (
                          "Cumplimiento Guardias"
                        )
                      }
                      propiedad={"fisCumplimientoGuardias"}
                      item={item}
                      setitem={setitem}
                    />
                    <Editor
                      label={
                        error.fisCumplimientoSupervisor ? (
                          <>
                            <Typography
                              variant="body1"
                              color="error"
                              component={"span"}
                            >
                              Cumplimiento Supervisor
                            </Typography>
                          </>
                        ) : (
                          "Cumplimiento Supervisor"
                        )
                      }
                      propiedad={"fisCumplimientoSupervisor"}
                      item={item}
                      setitem={setitem}
                    />
                    <Editor
                      label={
                        error.fisObservacionesCliente ? (
                          <>
                            <Typography
                              variant="body1"
                              color="error"
                              component={"span"}
                            >
                              Observaciones Cliente
                            </Typography>
                          </>
                        ) : (
                          "Observaciones Cliente"
                        )
                      }
                      propiedad={"fisObservacionesCliente"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                ) : null}
                {NoConformidadesSeccion ? (
                  <>
                    <Consignas
                      fromFOP={true}
                      setitemFOP={setitem}
                      itemFOP={item}
                      socketProp={socket}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                ></Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        // if (!tienePermiso("editar")) {
                        // return alertaPermiso("editar");
                        // }
                        handleEditar();
                      } else {
                        // if (!tienePermiso("agregar")) {
                        //   return alertaPermiso("agregar");
                        // }
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
