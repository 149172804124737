import React, { useContext } from "react";

// COMPONENTES
// import guardiaPng from "../../../assets/img/guardiaPng.png";
// import sigeopLogo from "../../../assets/img/sigeoplogo.png";
// CONTEXT
// METERIAL
import { IconButton, Toolbar, Box } from "@mui/material";

import { Menu } from "@mui/icons-material";
import NotificactionsButtons from "./NotificactionsButtons";
import AuthContext from "../../../Context/Auth/AuthContext";
const NavbarDesktop = (props) => {
  const { usuario } = useContext(AuthContext);
  const { handleDrawerToggle } = props; 
  return (
    <Toolbar
      sx={{
        background: (theme) =>
          `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 20%, ${theme.palette.secondary.light} 40%,  ${theme.palette.secondary.main} 75%, ${theme.palette.secondary.dark} 100%)`,
      }}
    >
      <IconButton
        color="secondary"
        aria-label="open drawer"
        edge="start"
        onClick={() => handleDrawerToggle()}
        sx={{ mr: 2, color: usuario.colorDrawer }}
      >
        <Menu />
      </IconButton>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <NotificactionsButtons mobile />
      </Box>
    </Toolbar>
  );
};

export default NavbarDesktop;
