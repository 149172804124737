import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import RowAgregarProveedor from "./RowsProveedor/RowAgregarProveedor";
import RowProveedor from "./RowsProveedor/RowProveedor";
import { AddCircle, Download } from "@mui/icons-material";
import { Autocomplete, Button, FormControl } from "@mui/material";
import RowAgregarMovimiento from "./RowsMovimiento/RowAgregarMovimiento";
import RowMovimiento from "./RowsMovimiento/RowMovimiento";
import RowAgregarMantenimiento from "./RowsMantenimiento/RowAgregarMantenimiento";
import RowMantenimiento from "./RowsMantenimiento/RowMantenimiento";
import Dropzone from "../../components//Dropzone";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  TableHead,
  Table,
  Paper,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { Cancel, CloudUpload, DeleteForever } from "@mui/icons-material";
import TablaCabecera from "../../components/TablaCabecera";
import ToolBarTable from "../../components/ToolBarTable";
import { useModalStyle } from "../../styles/styleModal";
import RowStock from "./Stock/RowStock";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import RowAgregarStock from "./Stock/RowAgregarStock";
const ModalAsistencia = (props) => {
  const [bodegaData, setbodegaData] = useState(null);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  const [mantenimientosData, setmantenimientosData] = useState([]);
  const [movimientosData, setmovimientosData] = useState([]);
  // eslint-disable-next-line
  const {
    agregandoStock,
    setagregandoStock,
    pageStock,
    // setPageStock,
    rowsPerPageStock,
    // setRowsPerPageStock,
    handleChangePageStock,
    handleChangeRowsPerPageStock,
  } = useSimpleTable("Stock");
  const [agregandoBodega, setagregandoBodega] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //ARMAS

  const [agregandoArmas, setagregandoArmas] = useState(false);
  const [pageArmas, setPageArmas] = React.useState(0);
  const [rowsPerPageArmas, setRowsPerPageArmas] = React.useState(10);
  const handleChangePageArmas = async (event, newPage) => {
    setPageArmas(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPageArmas = (event) => {
    setRowsPerPageArmas(+event.target.value);
    setPageArmas(0);
  };

  //MUNICION

  const [agregandoMuniciones, setagregandoMuniciones] = useState(false);
  const [pageMuniciones, setPageMuniciones] = React.useState(0);
  const [rowsPerPageMuniciones, setRowsPerPageMuniciones] = React.useState(10);
  const handleChangePageMuniciones = async (event, newPage) => {
    setPageMuniciones(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPageMuniciones = (event) => {
    setRowsPerPageMuniciones(+event.target.value);
    setPageMuniciones(0);
  };

  // invComprobantePdf
  const [documentoinvComprobantePdf, setdocumentoinvComprobantePdf] = useState(
    ""
  );
  const refArchivoinvComprobantePdf = createRef();
  const focusInputinvComprobantePdf = () => {
    refArchivoinvComprobantePdf.current.click();
  };
  const onChangeArchivoinvComprobantePdf = (e) => {
    if (!e.target.files[0]) {
      return setdocumentoinvComprobantePdf("");
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoinvComprobantePdf("");
      return mostrarAlerta("El archivo debe ser PDF", "error");
    }

    setdocumentoinvComprobantePdf(e.target.files[0]);
  };

  // invPermisoPdf
  const [documentoinvPermisoPdf, setdocumentoinvPermisoPdf] = useState("");
  const refArchivoinvPermisoPdf = createRef();
  const focusInputinvPermisoPdf = () => {
    refArchivoinvPermisoPdf.current.click();
  };
  const onChangeArchivoinvPermisoPdf = (e) => {
    if (!e.target.files[0]) {
      return setdocumentoinvPermisoPdf("");
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoinvPermisoPdf("");
      return mostrarAlerta("El archivo debe ser PDF", "error");
    }

    setdocumentoinvPermisoPdf(e.target.files[0]);
  };

  //documento
  const [documentoinvDocumento, setdocumentoinvDocumento] = useState("");
  const refArchivoinvDocumento = createRef();
  const focusInputinvDocumento = () => {
    refArchivoinvDocumento.current.click();
  };
  const onChangeArchivoinvDocumento = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoinvDocumento("");
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      return mostrarAlerta("El archivo debe ser PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (invDocumento === "") {
        await formDataLogos.append("invDocumento", e.target.files[0]);
      } else {
        arrayEliminar.push({
          nombre: "eliminar",
          url: invDocumento,
        });
        await formDataLogos.append("invDocumento", e.target.files[0]);
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));

      const resDocumentos = await clienteAxios.post(
        "/multiplefiles",
        formDataLogos
      );

      const documentosUrls = {};
      resDocumentos.data.urls.forEach((dataUrl) => {
        if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
          return;
        }
        documentosUrls[dataUrl.nombre] = dataUrl.url;
      });

      setitem({
        ...item,
        invDocumento: documentosUrls["invDocumento"],
      });

      setdocumentoinvDocumento(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoinvDocumento(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoinvDocumento(e.target.files[0]);
  };
  // eslint-disable-next-line
  const {
    invPrecioUnitario,
    invTipo,
    invSerie,
    invNombre,
    // invProductoEstado,
    invMarca,
    invModelo,
    // invStock,
    // invDisponible,
    invTalla,
    invClase,
    invTipoArma,
    invCalibre,
    invColor,
    invUso,
    invStatus,
    invComprobantePdf,
    invPermisoPdf,
    invFecValidacion,
    invFecCaducidad,
    invEstado,
    invProveedor,
    invFotos,
    invMaterial,
    invObservacion,
    invDocumento,
    // invBodId,
    invNomBodega,
    invTotalesStock,
  } = item;

  // eslint-disable-next-line
  const [seccionTabla, setseccionTabla] = useState({
    mantenimiento: true,
    movimiento: false,
  });
  const { mantenimiento, movimiento } = seccionTabla;
  const [error, seterror] = useState({
    // invPrecioUnitario: false,
    // invTipo: false,
    invSerie: false,
    invNombre: false,
    // invProductoEstado: false,
    // invMarca: false,
    // invModelo: false,
    // invStock:false,
    // invDisponible:false,
    // invTalla: false,
    // invClase: false,
    // invTipoArma: false,
    // invCalibre: false,
    // invColor: false,
    // invUso: false,
    // invStatus: false,
    // invComprobantePdf: false,
    // invPermisoPdf: false,
    // invFecValidacion: false,
    // invFecCaducidad: false,
    // invEstado: false,
    // invProveedor: false,
  });

  useEffect(() => {
    const getData = async () => {
      const resBodegas = await clienteAxios.get(
        `bodegas/filtro/0?empresa=${usuario.rucempresa}`
      );

      setbodegaData(resBodegas.data.data);
    };

    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (editar && open) {
      const getData = async () => {
        const resHistorial = await clienteAxios.get(
          `/historialinventario/${ObjectoActivo.invId}`
        );
        setmantenimientosData(resHistorial.data.data.mantenimiento);
        setmovimientosData(resHistorial.data.data.movimiento);
      };

      getData();
    }
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState({
      //   invId: 1,
      invObservacion: "",
      invMaterial: "",
      invPrecioUnitario: "1",
      invTipo: "VESTIMENTAS", // DE ACUERDO AL TAB
      invSerie: "0", // text
      invNombre: "", // TEXTAREA
      invProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
      invMarca: "",
      invModelo: "",
      invStock: "",
      invDisponible: "",
      invTalla: "40.00", // SELECT => 36,38,40,42,44,46
      invClase: "", // NO
      invTipoArma: "", // NO
      invCalibre: "", // NO
      invColor: "", // NO
      invUso: "", // NO
      invStatus: "", // NO
      invProveedor: [
        // TAB (PROVEEDOR) => TABLA
        // {
        //   id: "12",
        //   numDocumento: "0302626288001",
        //   nombres: "Hugo",
        //   numFactura: "32-32-3-32323",
        //   cantidad: "10",
        //   fecFactura: "2022-03-02",
        // },
      ],
      invBodega: [],
      invFotos: [],
      invComprobantePdf: "", // TAB (DOCUMENTOS)
      invPermisoPdf: "", // NO
      invFecValidacion: "", // NO
      invFecCaducidad: "", // DATE
      invEstado: "ACTIVA", // SWITCH
      invEmpresa: "",
      invUser: "",
      invFecReg: "",
      invDocumento: "",
      invTotalesStock: [
        {
          estado: "NUEVO",
          cantidad: 0,
        },
        {
          estado: "USADO",
          cantidad: 0,
        },
        {
          estado: "OPERABLE",
          cantidad: 0,
        },
        {
          estado: "DEFECTUOSO",
          cantidad: 0,
        },
      ],
    });
    setopen(false);
    seteditar(false);
    seterror({
      // invPrecioUnitario: false,
      // invTipo: false,
      invSerie: false,
      invNombre: false,
      // invProductoEstado: false,
      // invMarca: false,
      // invModelo: false,
      // invStock:false,
      // invDisponible:false,
      // invTalla: false,
      // invClase: false,
      // invTipoArma: false,
      // invCalibre: false,
      // invColor: false,
      // invUso: false,
      // invStatus: false,
      // invComprobantePdf: false,
      // invPermisoPdf: false,
      // invFecValidacion: false,
      // invFecCaducidad: false,
      // invEstado: false,
      // invProveedor: false,
    });
    setseccionActive({
      DatosGenerales: true,
      DatosAsignacion: false,
      DatosLogistica: false,
      Adjuntos: false,
    });
    setcargando(false);
    setdocumentoinvComprobantePdf("");
    setdocumentoinvPermisoPdf("");
    setagregandoBodega(false);
    setdocumentoinvDocumento("");
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();

  const validacion = () => {
    if (invTipo === "VESTIMENTAS" || invTipo === "UTILITARIOS") {
      if (
        invPrecioUnitario.trim() === "" ||
        invSerie.trim() === "" ||
        invNombre.trim() === "" ||
        // invProductoEstado.trim() === "" ||
        invMarca.trim() === "" ||
        invModelo.trim() === "" ||
        invTalla.trim() === "" ||
        invColor.trim() === "" ||
        invProveedor.length === 0 ||
        invNomBodega.trim() === ""
      ) {
        seterror({
          // seccion DatosGenerales
          invPrecioUnitario: invPrecioUnitario.trim() === "" ? true : false,
          invSerie: invSerie.trim() === "" ? true : false,
          invNombre: invNombre.trim() === "" ? true : false,
          // invProductoEstado: invProductoEstado.trim() === "" ? true : false,
          invMarca: invMarca.trim() === "" ? true : false,
          invModelo: invModelo.trim() === "" ? true : false,
          invTalla: invTalla.trim() === "" ? true : false,
          invColor: invColor.trim() === "" ? true : false,
          // seccion DatosProcedencia
          invProveedor: invProveedor.length === 0 ? true : false,
          invNomBodega: invNomBodega.trim() === "" ? true : false,
        });
        return true;
      } else {
        return false;
      }
    }

    if (invTipo === "ARMAS") {
      if (
        invPrecioUnitario.trim() === "" ||
        invSerie.trim() === "" ||
        invNombre.trim() === "" ||
        // invProductoEstado.trim() === "" ||
        invMarca.trim() === "" ||
        invModelo.trim() === "" ||
        invClase.trim() === "" ||
        invTipoArma.trim() === "" ||
        invCalibre.trim() === "" ||
        invColor.trim() === "" ||
        invUso.trim() === "" ||
        invStatus.trim() === "" ||
        invProveedor.length === 0 ||
        invNomBodega.trim() === ""
      ) {
        seterror({
          // seccion DatosGenerales
          invPrecioUnitario: invPrecioUnitario.trim() === "" ? true : false,
          invSerie: invSerie.trim() === "" ? true : false,
          invNombre: invNombre.trim() === "" ? true : false,
          // invProductoEstado: invProductoEstado.trim() === "" ? true : false,
          invMarca: invMarca.trim() === "" ? true : false,
          invModelo: invModelo.trim() === "" ? true : false,
          invClase: invClase.trim() === "" ? true : false,
          invTipoArma: invTipoArma.trim() === "" ? true : false,
          invCalibre: invCalibre.trim() === "" ? true : false,
          invColor: invColor.trim() === "" ? true : false,
          invUso: invUso.trim() === "" ? true : false,
          invStatus: invStatus.trim() === "" ? true : false,
          // seccion DatosProcedencia
          invProveedor: invProveedor.length === 0 ? true : false,
          invNomBodega: invNomBodega.trim() === "" ? true : false,
        });
        return true;
      } else {
        return false;
      }
    }
    if (invTipo === "MUNICIONES") {
      if (
        invSerie.trim() === "" ||
        invNombre.trim() === "" ||
        invPrecioUnitario.trim() === "" ||
        // invProductoEstado.trim() === "" ||
        invMarca.trim() === "" ||
        invClase.trim() === "" ||
        invMaterial.trim() === "" ||
        invCalibre.trim() === "" ||
        invProveedor.length === 0 ||
        invNomBodega.trim() === ""
      ) {
        const error = {
          invSerie: invSerie.trim() === "" ? true : false,
          invNombre: invNombre.trim() === "" ? true : false,
          invPrecioUnitario: invPrecioUnitario.trim() === "" ? true : false,
          // invProductoEstado: invProductoEstado.trim() === "" ? true : false,
          invMarca: invMarca.trim() === "" ? true : false,
          invClase: invClase.trim() === "" ? true : false,
          invMaterial: invMaterial.trim() === "" ? true : false,
          invCalibre: invCalibre.trim() === "" ? true : false,
          invProveedor: invProveedor.length === 0 ? true : false,
          invNomBodega: invNomBodega.trim() === "" ? true : false,
        };

        seterror(error);
        return true;
      } else {
        return false;
      }
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    DatosStock: false,
    DatosProcedencia: false,
    DatosBodega: false,
    DatosDocumentos: false,
  });
  // eslint-disable-next-line
  const {
    DatosGenerales,
    DatosStock,
    DatosProcedencia,
    DatosDocumentos,
    DatosHistorial,
  } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    // if (
    //   invProveedor.length === 0 ||
    //   Number(totalStock) !==
    //     invProveedor.map((proveedor) => proveedor.cantidad).reduce(reducer, 0)
    // ) {
    //   return mostrarAlerta(
    //     `El stock debe ser igual a la cantidad del proveedor: ${Number(
    //       totalStock
    //     )} / ${invProveedor
    //       .map((proveedor) => proveedor.cantidad)
    //       .reduce(reducer, 0)}`,
    //     "error"
    //   );
    // }
    try {
      setcargando(true);
      const formDataLogos = new FormData();

      //docs
      formDataLogos.append("comprobanteinventario", documentoinvComprobantePdf);
      formDataLogos.append("permisoinventario", documentoinvPermisoPdf);
      //anteriores
      formDataLogos.append("anteriorcomprobanteinventario", invComprobantePdf);
      formDataLogos.append("anteriorpermisoinventario", invPermisoPdf);
      let resDocumentos = {
        data: {
          urls: [],
        },
      };

      if (documentoinvComprobantePdf !== "" || documentoinvPermisoPdf !== "") {
        resDocumentos = await clienteAxios.post(
          "/multiplefiles",
          formDataLogos
        );
      }

      let invComprobantePdfurl =
        resDocumentos.data.urls.find((url) => {
          return url.nombre === "comprobanteinventario";
        }) || invComprobantePdf;
      let invPermisoPdfurl =
        resDocumentos.data.urls.find((url) => {
          return url.nombre === "permisoinventario";
        }) || invPermisoPdf;

      const resFotos = await subirImagenes(imagenes, invFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          invFotos: resFotos,
          invComprobantePdf: invComprobantePdfurl.url
            ? invComprobantePdfurl.url
            : invComprobantePdfurl,
          invPermisoPdf: invPermisoPdfurl.url
            ? invPermisoPdfurl.url
            : invPermisoPdfurl,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (documentoinvComprobantePdf && invComprobantePdf !== "") {
        arrayEliminar.push({
          nombre: "invComprobantePdf",
          url: invComprobantePdf,
        });
      }
      if (documentoinvPermisoPdf && invPermisoPdf !== "") {
        arrayEliminar.push({
          nombre: "invPermisoPdf",
          url: invPermisoPdf,
        });
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));
      //docs
      formDataLogos.append("comprobanteinventario", documentoinvComprobantePdf);
      formDataLogos.append("permisoinventario", documentoinvPermisoPdf);
      //anteriores
      formDataLogos.append("anteriorcomprobanteinventario", invComprobantePdf);
      formDataLogos.append("anteriorpermisoinventario", invPermisoPdf);
      let resDocumentos = {
        data: {
          urls: [],
        },
      };

      if (documentoinvComprobantePdf !== "" || documentoinvPermisoPdf !== "") {
        resDocumentos = await clienteAxios.post(
          "/multiplefiles",
          formDataLogos
        );
      }

      let invComprobantePdfurl =
        resDocumentos.data.urls.find((url) => {
          return url.nombre === "comprobanteinventario";
        }) || invComprobantePdf;
      let invPermisoPdfurl =
        resDocumentos.data.urls.find((url) => {
          return url.nombre === "permisoinventario";
        }) || invPermisoPdf;

      const resFotos = await subirImagenes(imagenes, invFotos);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          invFotos: resFotos,
          invComprobantePdf: invComprobantePdfurl.url
            ? invComprobantePdfurl.url
            : invComprobantePdfurl,
          invPermisoPdf: invPermisoPdfurl.url
            ? invPermisoPdfurl.url
            : invPermisoPdfurl,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }
  if (!bodegaData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}{" "}
                  {invTipo === "VESTIMENTAS"
                    ? "Vestimenta"
                    : invTipo === "UTILITARIOS"
                    ? "Utilitario"
                    : invTipo === "ARMAS"
                    ? "Arma"
                    : "Munición"}
                  .
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color={
                      invTipo === "VESTIMENTAS" || invTipo === "UTILITARIOS"
                        ? error.invPrecioUnitario ||
                          error.invSerie ||
                          error.invNombre ||
                          error.invProductoEstado ||
                          error.invMarca ||
                          error.invModelo ||
                          error.invTalla ||
                          error.invColor
                          ? "error"
                          : "secondary"
                        : invTipo === "ARMAS"
                        ? error.invPrecioUnitario ||
                          error.invSerie ||
                          error.invNombre ||
                          error.invProductoEstado ||
                          error.invMarca ||
                          error.invModelo ||
                          error.invClase ||
                          error.invTipoArma ||
                          error.invCalibre ||
                          error.invColor ||
                          error.invUso ||
                          error.invStatus
                          ? "error"
                          : "secondary"
                        : invTipo === "MUNICIONES"
                        ? error.invSerie ||
                          error.invNombre ||
                          error.invPrecioUnitario ||
                          error.invProductoEstado ||
                          error.invMarca ||
                          error.invClase ||
                          error.invMaterial ||
                          error.invCalibre
                          ? "error"
                          : "secondary"
                        : "primary"
                    }
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    variant={DatosProcedencia ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color={error.invProveedor ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosProcedencia: true })}
                  >
                    Procedencia
                  </Button>
                  <Button
                    variant={DatosDocumentos ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosDocumentos: true })}
                  >
                    Documentos
                  </Button>
                  {editar ? (
                    <Button
                      variant={DatosHistorial ? "contained" : null}
                      disableElevation
                      className={clases.buttonTab}
                      color="secondary"
                      onClick={() => setseccionActive({ DatosHistorial: true })}
                    >
                      Historial
                    </Button>
                  ) : null}{" "}
                  <Button
                    variant={DatosStock ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color={error.invProveedor ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosStock: true })}
                  >
                    Stock
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          invEstado === "ACTIVO" || invEstado === "ACTIVA"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setitem({
                            ...item,
                            invEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                        color="primary"
                      />
                    }
                    label="Estado"
                  />
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosGenerales ? (
                  <>
                    <>
                      {invTipo === "VESTIMENTAS" ||
                      invTipo === "UTILITARIOS" ? (
                        <>
                          {/* : "",+ // text */}

                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invSerie"
                            label="Serie"
                            value={invSerie}
                            name="invSerie"
                            error={error.invSerie}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "",+ // TEXTAREA */}
                          <TextField
                            multiline
                            rows={1}
                            className={clases.formD}
                            size="small"
                            id="invNombre"
                            label="Nombre"
                            value={invNombre}
                            name="invNombre"
                            error={error.invNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NU+EVO", // SELECT => NUEVO,USADO */}
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            id="invProductoEstado"
                            label="Producto Estado"
                            value={invProductoEstado}
                            name="invProductoEstado"
                            error={error.invProductoEstado}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                          </TextField> */}
                          {/* : "",+ */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="invPrecioUnitario"
                            label="Precio Unitario"
                            value={invPrecioUnitario}
                            name="invPrecioUnitario"
                            error={error.invPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invMarca"
                            label="Marca"
                            value={invMarca}
                            name="invMarca"
                            error={error.invMarca}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "",+ */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invModelo"
                            label="Modelo"
                            value={invModelo}
                            name="invModelo"
                            error={error.invModelo}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "40+", // SELECT => 36,38,40,42,44,46 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invTalla"
                            label="Talla"
                            value={invTalla}
                            name="invTalla"
                            error={error.invTalla}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "0",
                              "36",
                              "37",
                              "38",
                              "39",
                              "40",
                              "41",
                              "42",
                              "43",
                              "44",
                              "46",
                              "48",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invColor"
                            label="Color"
                            value={invColor}
                            name="invColor"
                            error={error.invColor}
                            onChange={(e) => handleChange(e)}
                          />
                        </>
                      ) : null}
                    </>
                    <>
                      {invTipo === "ARMAS" ? (
                        <>
                          {/* : "", */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invSerie"
                            label="Serie"
                            value={invSerie}
                            name="invSerie"
                            error={error.invSerie}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  e.target.value +
                                  " - " +
                                  invMarca +
                                  " - " +
                                  invTipoArma +
                                  " - " +
                                  invCalibre,
                              });
                            }}
                          />
                          {/* : "", */}
                          <TextField
                            disabled
                            className={clases.formD}
                            size="small"
                            id="invNombre"
                            label="Nombre"
                            value={invNombre}
                            name="invNombre"
                            error={error.invNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NUEVO", */}
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            id="invProductoEstado"
                            label="Producto Estado"
                            value={invProductoEstado}
                            name="invProductoEstado"
                            error={error.invProductoEstado}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                          </TextField> */}
                          {/* : "", // TEXT */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="invPrecioUnitario"
                            label="Precio Unitario"
                            value={invPrecioUnitario}
                            name="invPrecioUnitario"
                            error={error.invPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invMarca"
                            label="Marca"
                            value={invMarca}
                            name="invMarca"
                            error={error.invMarca}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  invTipoArma +
                                  " - " +
                                  invCalibre,
                              });
                            }}
                          />
                          {/* : "", // TEXT */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invModelo"
                            label="Modelo"
                            value={invModelo}
                            name="invModelo"
                            error={error.invModelo}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "LETAL", // SELECT => LETAL,NO LETAL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invClase"
                            label="Clase"
                            value={invClase}
                            name="invClase"
                            error={error.invClase}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["LETAL", "NO LETAL"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invTipoArma"
                            label="Tipo Arma"
                            value={invTipoArma}
                            name="invTipoArma"
                            error={error.invTipoArma}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  invMarca +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  invCalibre,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "REVOLVER",
                              "PISTOLA",
                              "ESCOPETA",
                              "FUSIL",
                              "MUNICION",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invCalibre"
                            label="Calibre"
                            value={invCalibre}
                            name="invCalibre"
                            error={error.invCalibre}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  invMarca +
                                  " - " +
                                  invTipoArma +
                                  " - " +
                                  e.target.value,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              ".38",
                              "9MM",
                              ".32/CAL 12",
                              "CAL 16",
                              "CAL 22",
                              "12GA",
                              "20GA",
                              "16GA",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "", // TEXT */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invColor"
                            label="Color"
                            value={invColor}
                            name="invColor"
                            error={error.invColor}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "FIJA", // SELECT => FIJA,MOVIL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invUso"
                            label="Uso"
                            value={invUso}
                            name="invUso"
                            error={error.invUso}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["FIJA", "MOVIL"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "APTA", // SELECT => APTA,DESCOMISADA,PERDIDA */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invStatus"
                            label="Status"
                            value={invStatus}
                            name="invStatus"
                            error={error.invStatus}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "APTA",
                              "DESCOMISADA",
                              "PERDIDA",
                              "MANTENIMIENTO",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        </>
                      ) : null}
                    </>
                    <>
                      {invTipo === "MUNICIONES" ? (
                        <>
                          {/* : "", */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invSerie"
                            label="Serie"
                            value={invSerie}
                            name="invSerie"
                            error={error.invSerie}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  e.target.value +
                                  " - " +
                                  invCalibre +
                                  " - " +
                                  invClase +
                                  " - " +
                                  item.invMaterial,
                              });
                            }}
                          />
                          {/* : "", */}
                          <TextField
                            disabled
                            className={clases.formD}
                            size="small"
                            id="invNombre"
                            label="Nombre"
                            value={invNombre}
                            name="invNombre"
                            error={error.invNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NUEVO", */}
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            id="invProductoEstado"
                            label="Producto Estado"
                            value={invProductoEstado}
                            name="invProductoEstado"
                            error={error.invProductoEstado}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                          </TextField> */}
                          {/* : "", // TEXT */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="invPrecioUnitario"
                            label="Precio Unitario"
                            value={invPrecioUnitario}
                            name="invPrecioUnitario"
                            error={error.invPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invMarca"
                            label="Marca"
                            value={invMarca}
                            name="invMarca"
                            error={error.invMarca}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                          {/* : "", // TEXT */}
                          {/* : "LETAL", // SELECT => LETAL,NO LETAL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invClase"
                            label="Clase"
                            value={invClase}
                            name="invClase"
                            error={error.invClase}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  invCalibre +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  item.invMaterial,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["LETAL", "FOGUEO", "TRAUMATICA"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL */}
                          {/* : "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invCalibre"
                            label="Calibre"
                            value={invCalibre}
                            name="invCalibre"
                            error={error.invCalibre}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  invClase +
                                  " - " +
                                  item.invMaterial,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              ".38",
                              "9MM",
                              ".32",
                              "CAL 12",
                              "CAL 16",
                              "CAL 22",
                              "12GA",
                              "20GA",
                              "16GA",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="invMaterial"
                            label="Material"
                            value={item.invMaterial}
                            name="invMaterial"
                            error={error.invMaterial}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                invNombre:
                                  invSerie +
                                  " - " +
                                  invCalibre +
                                  " - " +
                                  invClase +
                                  " - " +
                                  e.target.value,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["PLOMO", "ACERO"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "", // TEXT */}
                        </>
                      ) : null}
                    </>
                    {editar && (
                      <TextField
                        disabled
                        type="number"
                        className={clases.formD}
                        size="small"
                        label="Stock"
                        value={item.invStock}
                        name="invStock"
                        error={error.invStock}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                    <TextField
                      multiline
                      rows={3}
                      className={clases.formAllW}
                      variant="outlined"
                      size="small"
                      id="invObservacion"
                      label="Observación"
                      value={invObservacion}
                      name="invObservacion"
                      error={error.invObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {DatosStock && (
                  <Paper sx={{ width: "100%" }}>
                    <ToolBarTable
                      count={invTotalesStock.length}
                      rowsPerPage={rowsPerPageStock}
                      page={pageStock}
                      handleChangePage={handleChangePageStock}
                      handleChangeRowsPerPage={handleChangeRowsPerPageStock}
                      ocultarPaginacion={false}
                      simplePagination={true}
                      botonesIzquierdos={[
                        {
                          tooltip: "Agregar",
                          texto: "Agregar",
                          funcion: () => {
                            setagregandoStock(true);
                          },
                          disabled: false,
                          Icon: AddCircle,
                          color: "primary",
                          id: 1,
                          ocultar: false,
                          tipo: "boton",
                          variante: "contained",
                          size: "medium",
                          sx: {},
                        },
                      ]}
                      botonesDerechos={[]}
                    />

                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                      >
                        <TableHead>
                          <TablaCabecera
                            columns={[
                              {
                                id: "estado",
                                align: "center",
                                minWidth: 200,
                                tipo: "string",
                                label: "estado",
                              },
                              {
                                id: "cantidad",
                                align: "center",
                                minWidth: 200,
                                tipo: "string",
                                label: "cantidad",
                              },
                            ]}
                            habilitarOrdenar={false}
                          />
                        </TableHead>

                        <TableBody component={"div"}>
                          {agregandoStock ? (
                            <RowAgregarStock
                              setagregar={setagregandoStock}
                              setitemPadre={setitem}
                              itemPadre={item}
                            />
                          ) : null}

                          {invTotalesStock
                            .slice(
                              pageStock * rowsPerPageStock,
                              pageStock * rowsPerPageStock + rowsPerPageStock
                            )
                            .map((itemHijo, index) => {
                              return (
                                <RowStock
                                  editar={editar}
                                  setitemPadre={setitem}
                                  itemPadre={item}
                                  itemHijo={itemHijo}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}
                {DatosProcedencia ? (
                  <>
                    <FormControl className={clases.formAllW} fullWidth>
                      <Autocomplete
                        disableClearable={true}
                        options={bodegaData.map((item) => {
                          return item.bodNombre + "/+/" + item.bodId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={invNomBodega}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            setitem({
                              ...item,
                              invNomBodega: options[0],
                              invBodId: options[1],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="bodega"
                            error={error.invNomBodega}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {error.invProveedor ? (
                      <Typography
                        variant="caption"
                        color="error"
                        fontWeight="bold"
                      >
                        INGRESE AL MENOS UN PROVEEDOR.
                      </Typography>
                    ) : null}
                    <Paper sx={{ width: "100%" }}>
                      <ToolBarTable
                        ocultarPaginacion={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Proveedores",
                            texto: "Proveedores",
                            funcion: () => {
                              setagregandoBodega(true);
                            },
                            disabled: false,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={invProveedor.length}
                                rowsPerPage={rowsPerPage}
                                nextIconButtonText="Siguiente página"
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </caption>

                          {/* "": "0302626288001", */}
                          {/* "": "Hugo", */}
                          {/* "": "32-32-3-32323", */}
                          {/* "": "10", */}
                          {/* "": "2022-03-02" */}
                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "numDocumento",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Documento",
                                },
                                {
                                  id: "nombres",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Nombres",
                                },
                                {
                                  id: "numFactura",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Num. Factura",
                                },
                                {
                                  id: "fecFactura",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Fecha",
                                },
                                {
                                  id: "estado",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Estado",
                                },
                                {
                                  id: "cantidad",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Cantidad",
                                },
                                {
                                  id: "minimo",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Minimo",
                                },
                                {
                                  id: "maximo",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Maximo",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoBodega ? (
                              <RowAgregarProveedor
                                estadoRequest={estadoRequest}
                                setagregar={setagregandoBodega}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {invProveedor.map((row, index) => {
                              return (
                                <RowProveedor
                                  estadoRequest={estadoRequest}
                                  deshabilitarbotones={false}
                                  item={item}
                                  setitem={setitem}
                                  row={row}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}

                {DatosDocumentos ? (
                  <>
                    <>
                      <input
                        className={clases.ocultar}
                        type="file"
                        name="myImage"
                        onChange={onChangeArchivoinvComprobantePdf}
                        ref={refArchivoinvComprobantePdf}
                      />

                      <TextField
                        error={error.invComprobantePdf}
                        inputProps={{
                          className: clases.inputPadding,
                        }}
                        InputLabelProps={{
                          shrink:
                            documentoinvComprobantePdf !== "" ||
                            item.invComprobantePdf !== ""
                              ? true
                              : false,
                        }}
                        className={clases.formAllW}
                        InputProps={{
                          endAdornment: (
                            <Box htmlFor="contained-button-documento" fullWidth>
                              <InputAdornment>
                                {invComprobantePdf === "" ? null : (
                                  <Tooltip
                                    title="Eliminar Archivo: Comprobante PDF"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        onClick={async () => {
                                          const formDataLogos = new FormData();
                                          const urls = [
                                            {
                                              url: invComprobantePdf,
                                            },
                                          ];

                                          //docs
                                          formDataLogos.append(
                                            "eliminar",
                                            JSON.stringify(urls)
                                          );
                                          await clienteAxios.post(
                                            "/multiplefiles",
                                            formDataLogos
                                          );

                                          setdocumentoinvComprobantePdf("");
                                          setitem({
                                            ...item,
                                            invComprobantePdf: "",
                                          });
                                        }}
                                      >
                                        <DeleteForever color="error" />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  title="Subir Archivo: Comprobante PDF"
                                  arrow
                                >
                                  <Box>
                                    <IconButton
                                      onClick={focusInputinvComprobantePdf}
                                      aria-label=""
                                    >
                                      <CloudUpload color="secondary" />
                                    </IconButton>
                                  </Box>
                                </Tooltip>

                                {invComprobantePdf === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Comprobante PDF"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          window.open(item.invComprobantePdf);
                                        }}
                                        aria-label=""
                                      >
                                        <Download />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            </Box>
                          ),
                        }}
                        fullWidth
                        id="invComprobantePdf"
                        label={`Factura`}
                        value={
                          item.invComprobantePdf &&
                          !documentoinvComprobantePdf.name
                            ? item.invComprobantePdf.split("/")[
                                item.invComprobantePdf.split("/").length - 1
                              ]
                            : documentoinvComprobantePdf.name
                            ? documentoinvComprobantePdf.name
                            : ""
                        }
                        name="invComprobantePdf"
                      />
                    </>
                    {invTipo === "ARMAS" ? (
                      <>
                        <>
                          <input
                            className={clases.ocultar}
                            type="file"
                            name="myImage"
                            onChange={onChangeArchivoinvPermisoPdf}
                            ref={refArchivoinvPermisoPdf}
                          />
                          <TextField
                            error={error.invPermisoPdf}
                            inputProps={{
                              className: clases.inputPadding,
                            }}
                            InputLabelProps={{
                              shrink:
                                documentoinvPermisoPdf !== "" ||
                                item.invPermisoPdf !== ""
                                  ? true
                                  : false,
                            }}
                            className={clases.formAllW}
                            InputProps={{
                              endAdornment: (
                                <Box
                                  htmlFor="contained-button-documento"
                                  fullWidth
                                >
                                  <InputAdornment>
                                    {item.invPermisoPdf === "" ? null : (
                                      <Tooltip
                                        title="Eliminar Archivo: Comprobante PDF"
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            onClick={async () => {
                                              const formDataLogos = new FormData();
                                              const urls = [
                                                {
                                                  url: invPermisoPdf,
                                                },
                                              ];

                                              //docs
                                              formDataLogos.append(
                                                "eliminar",
                                                JSON.stringify(urls)
                                              );

                                              await clienteAxios.post(
                                                "/multiplefiles",
                                                formDataLogos
                                              );

                                              setdocumentoinvPermisoPdf("");
                                              setitem({
                                                ...item,
                                                invPermisoPdf: "",
                                              });
                                            }}
                                          >
                                            <DeleteForever color="error" />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                    )}
                                    <Tooltip
                                      title="Subir Archivo: Permiso PDF"
                                      arrow
                                    >
                                      <Box>
                                        <IconButton
                                          onClick={focusInputinvPermisoPdf}
                                          aria-label=""
                                        >
                                          <CloudUpload color="secondary" />
                                        </IconButton>
                                      </Box>
                                    </Tooltip>

                                    {item.invPermisoPdf === "" ? null : (
                                      <Tooltip
                                        title="Descargar Archivo: Permiso PDF"
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            color="primary"
                                            onClick={async () => {
                                              window.open(item.invPermisoPdf);
                                            }}
                                            aria-label=""
                                          >
                                            <Download />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </InputAdornment>
                                </Box>
                              ),
                            }}
                            fullWidth
                            id="invPermisoPdf"
                            label={`Permiso`}
                            value={
                              item.invPermisoPdf && !documentoinvPermisoPdf.name
                                ? item.invPermisoPdf.split("/")[
                                    item.invPermisoPdf.split("/").length - 1
                                  ]
                                : documentoinvPermisoPdf.name
                                ? documentoinvPermisoPdf.name
                                : ""
                            }
                            name="invPermisoPdf"
                          />
                        </>
                        <TextField
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            className: clases.inputPadding,
                            min: FechaExacta,
                          }}
                          className={clases.formD}
                          size="small"
                          id="invFecValidacion"
                          label="Fec. Permiso"
                          value={invFecValidacion}
                          name="invFecValidacion"
                          error={error.invFecValidacion}
                          onChange={(e) => handleChange(e)}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        className: clases.inputPadding,
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      size="small"
                      id="invFecCaducidad"
                      label="Fec. Caducidad"
                      value={invFecCaducidad}
                      name="invFecCaducidad"
                      error={error.invFecCaducidad}
                      onChange={(e) => handleChange(e)}
                    />
                    <>
                      <input
                        className={clases.ocultar}
                        type="file"
                        name="myImage"
                        onChange={onChangeArchivoinvDocumento}
                        ref={refArchivoinvDocumento}
                      />
                      <TextField
                        error={error.invDocumento}
                        inputProps={{
                          className: clases.inputPadding,
                        }}
                        InputLabelProps={{
                          shrink:
                            documentoinvDocumento !== "" ||
                            item.invDocumento !== ""
                              ? true
                              : false,
                        }}
                        className={clases.formD}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <Box htmlFor="contained-button-documento" fullWidth>
                              <InputAdornment>
                                <Tooltip title="Subir Archivo: Documento" arrow>
                                  <Box>
                                    <IconButton
                                      onClick={focusInputinvDocumento}
                                      aria-label=""
                                    >
                                      <CloudUpload color="secondary" />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                {item.invDocumento === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          try {
                                            const formDataLogos = new FormData();
                                            let arrayEliminar = [];

                                            arrayEliminar.push({
                                              nombre: "eliminar",
                                              url: invDocumento,
                                            });

                                            await formDataLogos.append(
                                              "eliminar",
                                              JSON.stringify(arrayEliminar)
                                            );
                                            setitem({
                                              ...item,
                                              invDocumento: "",
                                            });
                                            setdocumentoinvDocumento("");

                                            await clienteAxios.post(
                                              "/multiplefiles",
                                              formDataLogos
                                            );
                                          } catch (error) {}
                                        }}
                                        aria-label=""
                                      >
                                        <DeleteForever color="error" />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                                {item.invDocumento === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          window.open(item.invDocumento);
                                        }}
                                        aria-label=""
                                      >
                                        <Download />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            </Box>
                          ),
                        }}
                        fullWidth
                        id="invDocumento"
                        label={`Documento`}
                        value={
                          invDocumento && !documentoinvDocumento.name
                            ? invDocumento.split("/")[
                                invDocumento.split("/").length - 1
                              ]
                            : documentoinvDocumento.name
                            ? documentoinvDocumento.name
                            : ""
                        }
                        name="invDocumento"
                      />
                    </>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={invFotos}
                    />
                  </>
                ) : null}

                {DatosHistorial ? (
                  <>
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" stretch"
                      width="100%"
                    >
                      <Box>
                        <Button
                          size="small"
                          variant={mantenimiento ? "contained" : null}
                          onClick={async () => {
                            setseccionTabla({ mantenimiento: true });
                          }}
                          disableElevation
                          style={{
                            padding: ".5rem",
                            borderRadius: "15px 15px  0px 0px",
                          }}
                          color="primary"
                        >
                          Mantenimiento
                        </Button>
                        <Button
                          size="small"
                          variant={movimiento ? "contained" : null}
                          onClick={async () => {
                            setseccionTabla({ movimiento: true });
                          }}
                          disableElevation
                          style={{
                            padding: ".5rem",
                            borderRadius: "15px 15px  0px 0px",
                          }}
                          color="primary"
                        >
                          movimiento
                        </Button>
                      </Box>
                    </Box>
                    {mantenimiento ? (
                      <Paper sx={{ width: "100%" }}>
                        <ToolBarTable
                          ocultarPaginacion={true}
                          botonesIzquierdos={[
                            {
                              tooltip: "Mantenimiento",
                              texto: "Mantenimiento",
                              funcion: () => {
                                setagregandoMuniciones(true);
                              },
                              disabled: false,
                              Icon: AddCircle,
                              color: "secondary",
                              id: 1,
                              ocultar: false,
                              tipo: "boton",
                              variante: "contained",
                              size: "medium",
                              sx: {},
                            },
                          ]}
                          botonesDerechos={[]}
                        />
                        <TableContainer className={clases.container}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            id="tabla"
                            size="small"
                          >
                            <caption
                              style={{
                                padding: "0px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TablePagination
                                  labelRowsPerPage="Filas por pagina"
                                  rowsPerPageOptions={[10, 20, 50, 100]}
                                  component="div"
                                  count={mantenimientosData.length}
                                  rowsPerPage={rowsPerPageMuniciones}
                                  nextIconButtonText="Siguiente página"
                                  page={pageMuniciones}
                                  onPageChange={handleChangePageMuniciones}
                                  onRowsPerPageChange={
                                    handleChangeRowsPerPageMuniciones
                                  }
                                />
                              </Box>
                            </caption>

                            <TableHead>
                              <TablaCabecera
                                columns={[
                                  {
                                    id: "foto",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Foto",
                                  },
                                  {
                                    id: "tipo",
                                    align: "center",
                                    minWidth: 150,
                                    tipo: "string",
                                    label: "Tipo",
                                  },
                                  {
                                    id: "cantidad",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Cantidad",
                                  },
                                  {
                                    id: "fecha",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Fecha",
                                  },

                                  {
                                    id: "responsable",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Responsable",
                                  },

                                  {
                                    id: "detalle",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Detalle",
                                  },
                                ]}
                                habilitarOrdenar={false}
                              />
                            </TableHead>

                            <TableBody>
                              {agregandoMuniciones ? (
                                <RowAgregarMantenimiento
                                  agregar={agregandoMuniciones}
                                  setagregar={setagregandoMuniciones}
                                  setitem={setitem}
                                  setmantenimientosData={setmantenimientosData}
                                  mantenimientosData={mantenimientosData}
                                  item={item}
                                />
                              ) : null}
                              {mantenimientosData
                                .slice(
                                  pageMuniciones * rowsPerPageMuniciones,
                                  pageMuniciones * rowsPerPageMuniciones +
                                    rowsPerPageMuniciones
                                )
                                .map((row, index) => {
                                  return (
                                    <RowMantenimiento
                                      deshabilitarbotones={false}
                                      setmantenimientosData={
                                        setmantenimientosData
                                      }
                                      mantenimientosData={mantenimientosData}
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={row.hisId}
                                    />
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    ) : null}
                    {movimiento ? (
                      <Paper sx={{ width: "100%" }}>
                        <ToolBarTable
                          ocultarPaginacion={true}
                          botonesIzquierdos={[
                            {
                              tooltip: "Movimiento",
                              texto: "Movimiento",
                              funcion: () => {
                                setagregandoArmas(true);
                              },
                              disabled: false,
                              Icon: AddCircle,
                              color: "secondary",
                              id: 1,
                              ocultar: false,
                              tipo: "boton",
                              variante: "contained",
                              size: "medium",
                              sx: {},
                            },
                          ]}
                          botonesDerechos={[]}
                        />
                        <TableContainer className={clases.container}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            id="tabla"
                            size="small"
                          >
                            <caption
                              style={{
                                padding: "0px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TablePagination
                                  labelRowsPerPage="Filas por pagina"
                                  rowsPerPageOptions={[10, 20, 50, 100]}
                                  component="div"
                                  count={mantenimientosData.length}
                                  rowsPerPage={rowsPerPageArmas}
                                  nextIconButtonText="Siguiente página"
                                  page={pageArmas}
                                  onPageChange={handleChangePageArmas}
                                  onRowsPerPageChange={
                                    handleChangeRowsPerPageArmas
                                  }
                                />
                              </Box>
                            </caption>

                            <TableHead>
                              <TablaCabecera
                                columns={[
                                  {
                                    id: "foto",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "foto",
                                  },
                                  {
                                    id: "tipo",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Tipo",
                                  },
                                  {
                                    id: "cantidad",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Cantidad",
                                  },
                                  {
                                    id: "Fecha",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Fecha",
                                  },

                                  {
                                    id: "Responsable",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Responsable",
                                  },

                                  {
                                    id: "Detalle",
                                    align: "center",
                                    minWidth: 50,
                                    tipo: "string",
                                    label: "Detalle",
                                  },
                                ]}
                                habilitarOrdenar={false}
                              />
                            </TableHead>

                            <TableBody>
                              {agregandoArmas ? (
                                <RowAgregarMovimiento
                                  agregar={agregandoArmas}
                                  setagregar={setagregandoArmas}
                                  setitem={setitem}
                                  item={item}
                                  setmovimientosData={setmovimientosData}
                                  movimientosData={movimientosData}
                                />
                              ) : null}
                              {movimientosData
                                .slice(
                                  pageArmas * rowsPerPageArmas,
                                  pageArmas * rowsPerPageArmas +
                                    rowsPerPageArmas
                                )
                                .map((row, index) => {
                                  return (
                                    <RowMovimiento
                                      deshabilitarbotones={false}
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={row.hisId}
                                      setmovimientosData={setmovimientosData}
                                      movimientosData={movimientosData}
                                    />
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    ) : null}
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
