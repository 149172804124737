import React, {
  useState,
  useContext,
  useEffect,
  memo,
  useRef,
  useMemo,
} from "react";
import {
  Modal,
  Box,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableRow,
  TableCell,
  Autocomplete,
  InputAdornment,
} from "@mui/material";

import {
  AddCircle,
  AddCircleOutlineTwoTone,
  Cancel,
  Delete,
  Save,
} from "@mui/icons-material";
// import { usePermiso } from "../../hooks/usePermiso";
import { calcularProducto } from "./helpers";
import { calcularTotales } from "./helpers/calcularTotales";
import { MonetizationOn } from "@mui/icons-material";
import { objectDefault } from "../../Personas/Personas/data";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";
import { useDraggable } from "../../hooks/useDraggable";
import { useModalStyle } from "../../styles/styleModal";
import { validarEmail } from "../../../config/validaciones";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import ConfirmacionDialog from "./ConfirmacionDialog";
import Draggable from "react-draggable";
import ModalPersona from "../../Personas/Personas/Componentes/Modal";
import RowAgregarProducto from "./Productos/RowAgregarProducto";
import RowProducto from "./Productos/RowProducto";
import TablaCabecera from "../../../components/Extra/TablaCabecera";
import ToolBarTable from "../../../components/Extra/ToolBarTable";
// eslint-disable-next-line
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const ModalAsistencia = (props) => {
  // const { alertaPermiso, tienePermiso } = usePermiso("Facturacion");
  const [dinero, setdinero] = useState("0");
  const inputRef = useRef();
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  const [codigoBarra, setcodigoBarra] = useState("");
  const [focusCantidad, setfocusCantidad] = useState(false);
  const [otrosData, setotrosData] = useState([]);
  const [subcategoriasData, setsubcategoriasData] = useState(null);

  const [producto, setproducto] = useState({
    invSerie: "",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "",
    invIncluyeIva: "",
    invEstado: "",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "",
    invFecReg: "",
  });
  const [itemRow, setItemRow] = useState({
    stock: 0,
    subcategoria: "",
    limiteStock: false,
    cantidad: "1",
    codigo: "",
    descripcion: "",
    valUnitarioInterno: "",
    valorUnitario: "",
    recargoPorcentaje: "",
    recargo: "",
    descPorcentaje: "",
    descuento: "",
    precioSubTotalProducto: "",
    valorIva: "",
    costoProduccion: "",
    llevaIva: "SI",
    incluyeIva: "NO",
  });

  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    setopenInventario,
    setOpenProveedor,
    estadoRequest,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdePeluqueria,
    seteditarInventario,
    changeObjetoActivoStateInventario,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [agregarProducto, setagregarProducto] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [openConfirmacionDialog, setopenConfirmacionDialog] = useState(false);
  const [clientesData, setclientesData] = useState([]);

  // eslint-disable-next-line

  const {
    // venTipoDocumento,
    venIdCliente,
    venRucCliente,
    // venTipoDocuCliente,
    venNomCliente,
    // venEmailCliente,
    // venTelfCliente,
    // venCeluCliente,
    // venDirCliente,
    // venEmpRuc,
    // venEmpNombre,
    // venEmpComercial,
    // venEmpDireccion,
    // venEmpTelefono,
    // venEmpEmail,
    // venEmpObligado,
    venFormaPago,
    venNumero,
    venFacturaCredito,
    venDias,
    venAbono,
    venDescPorcentaje,
    venOtrosDetalles,
    venObservacion,
    venSubTotal12,
    venSubtotal0,
    venDescuento,
    venSubTotal,
    venTotalIva,
    venTotal,
    // venCostoProduccion,
    // venUser,
    venFechaFactura,
    venNumFactura,
    // venNumFacturaAnterior,
    // venAutorizacion,
    // venFechaAutorizacion,
    // venErrorAutorizacion,
    venEstado,
    // venEnvio,
    // fechaSustentoFactura,
    // venEmpresa,
    venProductos,
    venEmailCliente,
    venKilometrajeInicio,
    venKilometrajeFinal,
  } = item;
  const [error, seterror] = useState({
    venIdCliente: false,
    venRucCliente: false,
    venNomCliente: false,
    venEmailCliente: false,
  });
  const [secuencia, setsecuencia] = useState("");

  useEffect(() => {
    const getDataState = async () => {
      try {
        const res = await clienteAxios.get(
          `/secuencias/filtro/0?empresa=${usuario.rucempresa}&search=${
            estadoRequest === "FACTURAS"
              ? "FACTURA"
              : estadoRequest === "NOTA VENTAS"
              ? "NOTA DE VENTA"
              : estadoRequest === "PROFORMAS"
              ? "PROFORMA"
              : estadoRequest === "NOTA CREDITOS"
              ? "NOTA DE CREDITO"
              : ObjectoActivo.venTipoDocumento === "F"
              ? "FACTURA"
              : ObjectoActivo.venTipoDocumento === "NC"
              ? "NOTA DE CREDITO"
              : ""
          }`
        );
        if (!res.data.secuencia && estadoRequest !== "ANULADAS" && open) {
          mostrarAlerta("CONFIGURE EL NÚMERO DE SECUENCIA", "error");
          return setopen(false);
        }

        setsecuencia(res.data.secuencia);
      } catch (error) {}
    };
    getDataState();

    setotrosData(
      ObjectoActivo.venOtrosDetalles ? [ObjectoActivo.venOtrosDetalles] : []
    );
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [ObjectoActivo]);

  // const [ItemOtroLugarProveedor, setItemOtroLugarProveedor] = useState({});
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    itemSocketData: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        itemSocketData: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        itemSocketData: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  const [guardiasData, setguardiasData] = useState([]);
  useEffect(() => {
    const getClientesData = async (tipo) => {
      try {
        const res = await clienteAxios.get(
          `/clientes/filtro/0?search&estado=${"CLIENTES"}`
        );
        setguardiasData(res.data.data, tipo);
      } catch (error) {}
    };
    getClientesData();
  }, []);

  useEffect(() => {
    const { itemSocketData, tipo } = itemSocket;

    if (!open) {
      return;
    }

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== itemSocketData.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (itemSocketData.msg === "") {
        return null;
      }
      if (!itemSocketData.msg) {
        return null;
      }
      mostrarAlerta(
        itemSocketData.msg,
        itemSocketData.alert ? itemSocketData.alert : "success"
      );
    };

    if (tipo === "agregar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario === itemSocketData.perUser) {
          setitem({
            ...item,
            venRucCliente: itemSocketData.perDocNumero,
            venNomCliente: itemSocketData.perNombre,
            venIdCliente: itemSocketData.perId,
            venTipoDocuCliente: itemSocketData.perDocTipo,
            venEmailCliente: itemSocketData.perEmail,
            venTelfCliente: itemSocketData.perTelefono,
            venCeluCliente: itemSocketData.perCelular,
            venDirCliente: itemSocketData.perDireccion,
            venOtrosDetalles:
              itemSocketData.perOtros.length === 0
                ? ""
                : itemSocketData.perOtros[0],
          });
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }

    if (tipo === "editar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario === itemSocketData.perUser) {
          setitem({
            ...item,
            venRucCliente: itemSocketData.perDocNumero,
            venNomCliente: itemSocketData.perNombre,
            venIdCliente: itemSocketData.perId,
            venTipoDocuCliente: itemSocketData.perDocTipo,
            venEmailCliente: itemSocketData.perEmail,
            venTelfCliente: itemSocketData.perTelefono,
            venCeluCliente: itemSocketData.perCelular,
            venDirCliente: itemSocketData.perDireccion,
            venOtrosDetalles:
              itemSocketData.perOtros.length === 0
                ? ""
                : itemSocketData.perOtros[0],
          });
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }
    funcionAalerta();
    setitemSocket({ tipo: "", itemSocketData: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const defaultActive = () => {
    // 1 nuevo
    // 2 copia
    // 3 editar
    // 4 nota de credito
    changeObjetoActivoState({
      venKilometrajeInicio: "",
      venKilometrajeFinal: "",
      venEmpRegimen: "",
      venProductosAntiguos: [],
      optionDocumento: "",
      venTotalRetencion: "0.00",
      venOption: "1",
      venTipoDocumento: "N",
      venIdCliente: "1",
      venRucCliente: "9999999999999",
      venTipoDocuCliente: "RUC",
      venNomCliente: "CONSUMIDOR FINAL",
      venEmailCliente: ["sin@sincorreo.com"],
      venTelfCliente: "0000000001",
      venCeluCliente: "0000000001",
      venDirCliente: "s/n",
      venEmpRuc: "",
      venEmpNombre: "",
      venEmpComercial: "",
      venEmpDireccion: "",
      venEmpTelefono: "",
      venEmpEmail: "",
      venEmpObligado: "",
      venFormaPago: "TRANSFERENCIA",
      venNumero: "0",
      venFacturaCredito: "NO",
      venDias: "0",
      venAbono: "0",
      venDescPorcentaje: "0",
      venOtrosDetalles: "",
      venObservacion: "",
      venSubTotal12: "",
      venSubtotal0: "",
      venDescuento: "",
      venSubTotal: "",
      venTotalIva: "",
      venTotal: "0.00",
      venCostoProduccion: "",
      venUser: "",
      venFechaFactura: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
      venNumFactura: "",
      venNumFacturaAnterior: "",
      venAutorizacion: "0",
      venFechaAutorizacion: "",
      venErrorAutorizacion: "NO",
      venEstado: "EN PROCESO",
      venEnvio: "NO",
      fechaSustentoFactura: "",
      venEmpresa: "",
      venProductos: [],
    });
    setopen(false);
    seteditar(false);
    seterror({
      venIdCliente: false,
      venRucCliente: false,
      venNomCliente: false,
    });
    setdinero("0");
    setcargando(false);
    setagregarProducto(false);
    setotrosData([]);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();
  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    if (
      item.venIdCliente === "" ||
      item.venRucCliente === "" ||
      item.venNomCliente === "" ||
      venEmailCliente.some((itemCorreo) => {
        return validarEmail(itemCorreo) || itemCorreo.trim() === "";
      }) ||
      (usuario.rucempresa === "JEFF2023" &&
        (venKilometrajeInicio === "" || venKilometrajeFinal === ""))
    ) {
      seterror({
        venIdCliente: item.venIdCliente === "" ? true : false,
        venRucCliente: item.venRucCliente === "" ? true : false,
        venNomCliente: item.venNomCliente === "" ? true : false,
        venEmailCliente: venEmailCliente.some((itemCorreo) => {
          return validarEmail(itemCorreo) || itemCorreo.trim() === "";
        }),
        venKilometrajeInicio:
          usuario.rucempresa === "JEFF2023" && venKilometrajeInicio === "",
        venKilometrajeFinal:
          usuario.rucempresa === "JEFF2023" && venKilometrajeFinal === "",
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async (optionDocumento) => {
    // if (!tienePermiso("agregar")) {
    //   return alertaPermiso("agregar");
    // }
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }
    if (
      (venRucCliente === "9999999999999" || venRucCliente === "9999999999") &&
      (venFacturaCredito === "SI" || venAbono > 0)
    ) {
      return mostrarAlerta("Ingrese datos de un cliente", "error");
    }
    if (Number(venTotal) === Number(venAbono) && venFacturaCredito === "SI") {
      return mostrarAlerta("No puede registrar como Factura/Crédito", "error");
    }
    if (venProductos.length === 0) {
      return mostrarAlerta("Ingrese al menos un producto", "error");
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          venEnvio: "NO",
          tabla: "venta",
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          venEnvio: "NO",
          tabla: "venta",
        })
      );
      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  const handleEditar = async (optionDocumento) => {
    // if (!tienePermiso("editar")) {
    //   return alertaPermiso("editar");
    // }
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }
    if (
      (venRucCliente === "9999999999999" || venRucCliente === "9999999999") &&
      (venFacturaCredito === "SI" || venAbono > 0)
    ) {
      return mostrarAlerta("Ingrese datos de un cliente", "error");
    }
    if (Number(venTotal) === Number(venAbono) && venFacturaCredito === "SI") {
      return mostrarAlerta("No puede registrar como Factura/Crédito", "error");
    }
    if (venProductos.length === 0) {
      return mostrarAlerta("Ingrese al menos un producto", "error");
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          venEnvio: "NO",
          tabla: "venta",
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          venEnvio: "NO",
          tabla: "venta",
        })
      );
      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  const deshabilitar = useMemo(
    () =>
      ObjectoActivo.venEstado === "APROBADO" ||
      ObjectoActivo.venEstado === "ANULADO",
    [ObjectoActivo]
  );

  const funcion3 = () => {
    if (editar) {
      handleEditar("P");
    } else {
      handleCrear("P");
    }
  };

  const [openPersona, setopenPersona] = useState(false);
  // const [editarPersona, seteditarPersona] = useState(false);
  const [ObjectoActivoPersona, setObjetoActivoPersona] = useState(
    objectDefault
  );
  if (!open) {
    return null;
  }

  return (
    <>
      <ConfirmacionDialog
        categoria={"success"}
        titulo={`¿Desea ${editar ? "editar" : "guardar"} la proforma?`}
        open={openConfirmacionDialog}
        setopen={setopenConfirmacionDialog}
        funcion={() => {
          funcion3();
        }}
        funcionNo={() => {
          setopenConfirmacionDialog(false);
        }}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handleProforma">
            <Box display="flex" justifyContent="center">
              <ModalPersona
                editar={false}
                seteditar={() => {}}
                setopen={setopenPersona}
                open={openPersona}
                ObjectoActivo={ObjectoActivoPersona}
                changeObjetoActivoState={setObjetoActivoPersona}
                socket={socket}
                estadoRequest={"GUARDIAS"}
              />
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handleProforma"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {editar ? "Editar " : "Nueva "}
                    {ObjectoActivo.venTipoDocumento === "F"
                      ? "Factura"
                      : ObjectoActivo.venTipoDocumento === "N"
                      ? "Prefactura"
                      : ObjectoActivo.venTipoDocumento === "NC"
                      ? "Nota Crédito"
                      : "Proforma"}
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      component={"span"}
                    >
                      {editar ? ` - #${venNumFactura}.` : ` - #${secuencia}.`}
                    </Typography>
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {editar ? (
                      <TextField
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD25}
                        id="venEstado"
                        label="Estado"
                        value={venEstado}
                        name="venEstado"
                        error={error.venEstado}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        sx={{
                          color:
                            venEstado === "EN PROCESO"
                              ? "orange"
                              : venEstado === "APROBADO"
                              ? "green"
                              : venEstado === "NO APROBADO"
                              ? "blue"
                              : venEstado === "ANULADO"
                              ? "red"
                              : "black",
                        }}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        <MenuItem value="EN PROCESO" sx={{ color: "tomato" }}>
                          EN PROCESO
                        </MenuItem>
                        <MenuItem value="APROBADO" sx={{ color: "green" }}>
                          APROBADO
                        </MenuItem>
                        <MenuItem value="NO APROBADO" sx={{ color: "blue" }}>
                          NO APROBADO
                        </MenuItem>
                        <MenuItem value="ANULADO" sx={{ color: "red" }}>
                          ANULADO
                        </MenuItem>
                      </TextField>
                    ) : null}
                    <IconButton
                      aria-label=""
                      color="primary"
                      disabled={cargando || deshabilitar}
                      onClick={() => {
                        if (editar) {
                          setopenConfirmacionDialog(true);
                        } else {
                          setopenConfirmacionDialog(true);
                        }
                      }}
                    >
                      <Save />
                    </IconButton>
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box></Box>
                </Box>

                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-end"
                  borderRadius="0px 50px 50px 0px"
                  p={1}
                >
                  {/* <Box
                className={clases.formAllW}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider style={{ width: "42%" }} />
                <Typography
                  style={{ width: "100%" }}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  component={"span"}
                >
                  CLIENTE / PAGO
                </Typography>
                <Divider style={{ width: "42%" }} />
              </Box> */}
                  <>
                    <FormControl fullWidth className={clases.formD25}>
                      <Autocomplete
                        options={guardiasData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        disabled={deshabilitar}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={venRucCliente + " ** " + venIdCliente}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const itemF = guardiasData.find((itemF) => {
                              return itemF.cliId === Number(options[2]);
                            });
                            console.log(itemF);
                            setotrosData(itemF.perOtros);
                            setitem({
                              ...item,
                              venNomCliente: itemF.cliRazonSocial,
                              venRucCliente: itemF.cliDocNumero,
                              venIdCliente: itemF.cliId,
                              venTipoDocuCliente: itemF.cliDocTipo,
                              venEmailCliente: itemF.perEmail,
                              venTelfCliente: itemF.cliTelefono,
                              venCeluCliente: itemF.cliCelular,
                              venDirCliente: itemF.cliDireccion,
                              venOtrosDetalles:
                                itemF.perOtros.length === 0
                                  ? ""
                                  : itemF.perOtros[0],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Ruc Cliente"
                            fullWidth
                            error={error.venRucCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un Nombre o Documento",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"CLIENTE"}&tabla=ventas`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    disabled={editar}
                                    aria-label=""
                                    onClick={async () => {
                                      setopenPersona(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      className={clases.formD25}
                      id="venNomCliente"
                      label="Cliente"
                      value={venNomCliente}
                      name="venNomCliente"
                      error={error.venNomCliente}
                      onChange={(e) => handleChange(e)}
                    />
                  </>

                  <>
                    {venFormaPago !== "EFECTIVO" && venFormaPago !== "" ? (
                      <TextField
                        disabled={deshabilitar}
                        type="number"
                        className={clases.formD25}
                        id="venNumero"
                        label="Número"
                        value={venNumero}
                        name="venNumero"
                        error={error.venNumero}
                        onChange={(e) => handleChange(e)}
                      />
                    ) : null}
                    <FormControl
                      component="fieldset"
                      className={clases.formD25}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          disabled={deshabilitar}
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                          className={
                            error.venFacturaCredito ? clases.error : ""
                          }
                        >
                          Crédito
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="Factura/Crédito"
                          value={venFacturaCredito}
                          name="venFacturaCredito"
                        >
                          <FormControlLabel
                            value="SI"
                            disabled={deshabilitar}
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            disabled={deshabilitar}
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    {venFacturaCredito === "SI" ? (
                      <TextField
                        tyoe="number"
                        disabled={deshabilitar}
                        className={clases.formD25}
                        id="venDias"
                        label="Dias"
                        value={venDias}
                        name="venDias"
                        error={error.venDias}
                        onChange={(e) => handleChange(e)}
                      />
                    ) : null}
                    <TextField
                      type="number"
                      disabled={deshabilitar}
                      className={clases.formD25}
                      id="venAbono"
                      label="Abono $"
                      value={venAbono}
                      name="venAbono"
                      error={error.venAbono}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      type="number"
                      disabled={deshabilitar}
                      className={clases.formD25}
                      id="venDescPorcentaje"
                      label="Desc %"
                      putProps={{ style: { paddingTop: "0px" } }}
                      value={venDescPorcentaje}
                      name="venDescPorcentaje"
                      error={error.venDescPorcentaje}
                      onChange={(e) => {
                        const resProductos = venProductos.map((productoMap) => {
                          return calcularProducto({
                            producto: {
                              ...productoMap,
                              descPorcentaje: e.target.value,
                            },
                            formPorcentaje: item.formPorcentaje,
                            iva: 15,
                          });
                        });
                        const {
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        } = calcularTotales(resProductos);
                        setitem({
                          ...item,
                          venProductos: resProductos,
                          venDescPorcentaje: e.target.value,
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        });
                      }}
                    />
                    <TextField
                      type="date"
                      disabled={deshabilitar}
                      className={clases.formD25}
                      id="venFechaFactura"
                      label="Fecha"
                      value={venFechaFactura}
                      name="venFechaFactura"
                      error={error.venFechaFactura}
                      inputProps={{
                        max: new Date(Date.now()).toISOString().substr(0, 10),
                        min: new Date(
                          new Date(Date.now()).setMonth(
                            new Date().getMonth() - 3
                          )
                        )
                          .toISOString()
                          .substr(0, 10),
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      multiline
                      rows={1}
                      disabled={deshabilitar}
                      className={clases.formD25}
                      id="venObservacion"
                      label="Observación"
                      value={venObservacion}
                      name="venObservacion"
                      error={error.venObservacion}
                      onChange={(e) => {
                        if (e.nativeEvent.inputType === "insertLineBreak") {
                          return;
                        }
                        handleChange(e);
                      }}
                    />
                    <FormControl className={clases.formD25}>
                      <Typography
                        variant="subtitle1"
                        color="secondary"
                        style={{ fontSize: "1.8rem" }}
                      >
                        ${venTotal}
                      </Typography>
                    </FormControl>
                  </>

                  {(ObjectoActivo.venOption === "4" ||
                    ObjectoActivo.venOption === "3") &&
                  ObjectoActivo.venTipoDocumento === "NC" ? (
                    <>
                      <Divider className={clases.formAllW} />
                      <Box
                        display={"flex"}
                        justifyContent={"space-around"}
                        className={clases.formAllW}
                      >
                        <TextField
                          disabled={deshabilitar}
                          className={clases.formD25}
                          id="venNumFacturaAnterior"
                          label="Factura Afectar"
                          value={item.venNumFacturaAnterior}
                          name="venNumFacturaAnterior"
                          error={error.venNumFacturaAnterior}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled={deshabilitar}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          className={clases.formD25}
                          id="fechaSustentoFactura"
                          label="F. Factura"
                          value={item.fechaSustentoFactura}
                          name="fechaSustentoFactura"
                          error={error.fechaSustentoFactura}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <Typography
                        style={{ width: "100%" }}
                        variant="subtitle1"
                        color="secondary"
                        align="center"
                        component={"span"}
                      >
                        FACTURA AFECTAR #: 
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          component={"span"}
                        >
                          {item.venNumFacturaAnterior}
                        </Typography>
                      </Typography>
                      <Typography
                        style={{ width: "100%" }}
                        variant="subtitle1"
                        color="secondary"
                        align="center"
                        component={"span"}
                      >
                        FECHA FACTURA: 
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          component={"span"}
                        >
                          {item.fechaSustentoFactura}
                        </Typography>
                      </Typography> */}
                      </Box>
                      <Divider className={clases.formAllW} />
                    </>
                  ) : null}
                  <FormControl className={clases.formD25}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        color={error.venEmailCliente ? "error" : "primary"}
                      >
                        Correos:
                      </Typography>
                      <IconButton
                        aria-label=""
                        disabled={deshabilitar}
                        size="small"
                        onClick={() => {
                          const newCorreos = item.venEmailCliente;
                          newCorreos.push("");
                          setitem({
                            ...item,
                            venEmailCliente: newCorreos,
                          });
                        }}
                      >
                        <AddCircle fontSize="small" color="secondary" />
                      </IconButton>
                    </Box>
                  </FormControl>
                  {venEmailCliente.map((correo, index) => {
                    return (
                      <TextField
                        className={clases.formD25}
                        id=""
                        disabled={deshabilitar}
                        value={venEmailCliente[index]}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            venEmailCliente: venEmailCliente.map(
                              (correoItem, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return correoItem;
                                }
                              }
                            ),
                          });
                        }}
                        type="email"
                        label={`Correo #${index}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={() => {
                                  setitem({
                                    ...item,
                                    venEmailCliente: venEmailCliente.filter(
                                      (item, indexItem) => {
                                        return indexItem !== index;
                                      }
                                    ),
                                  });
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  })}
                  {/* <Box
                className={clases.formAllW}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider style={{ width: "42%" }} />
                <Typography
                  style={{ width: "100%" }}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  component={"span"}
                >
                  PRODUCTOS
                </Typography>
                <Divider style={{ width: "42%" }} />
              </Box> */}
                  <Box p={0} maxHeight={"40vh"} width={"100%"}>
                    <Paper className={clases.root}>
                      <ToolBarTable
                        titulo={"Productos"}
                        etiqueta={"Código Barra"}
                        inputData={codigoBarra}
                        setinputData={setcodigoBarra}
                        inputRef={inputRef}
                        funcionInputKey={async (e) => {
                          if (codigoBarra === "") {
                            return mostrarAlerta(
                              "Ingrese un cóodigo de barra",
                              "error"
                            );
                          }
                          if (e.key === "Enter") {
                            try {
                              const res = await clienteAxios.get(
                                `/inventario/filtro/0?search=${e.target.value}&tipo=codigobarra`
                              );

                              setcodigoBarra("");
                              setproducto(res.data.data);
                              setfocusCantidad(true);
                              const productoRes = res.data.data;
                              const newValue =
                                productoRes.invNombre +
                                "/+/" +
                                productoRes.invSerie +
                                "/+/" +
                                productoRes.invStock +
                                "/+/" +
                                productoRes.invIncluyeIva +
                                "/+/" +
                                productoRes.invTipo +
                                "/+/" +
                                productoRes.invIva;

                              const options = newValue.split("/+/");

                              setItemRow({
                                ...itemRow,
                                descripcion: options[0],
                                codigo: options[1],
                                incluyeIva: options[3],
                                llevaIva: options[5],
                                valorUnitario: productoRes.invprecios[0],
                                valUnitarioInterno: productoRes.invprecios[0],
                                descPorcentaje: item.venDescPorcentaje,
                              });

                              setagregarProducto(true);
                            } catch (error) {}
                          }
                        }}
                        soloTitulo={true}
                        botonAdd={true}
                        deshabilitarbotonAdd={deshabilitar}
                        funcionAdd={() => {
                          setagregarProducto(true);

                          // const retenciones = [
                          //   {
                          //     detTipo: "",
                          //     detCodigo: "",
                          //     detBasImponible: "",
                          //     detImpuesto: "",
                          //     detPorcentaje: "",
                          //     detValor: "",
                          //   },
                          //   ...item.retenciones,
                          // ];

                          // setitem({
                          //   ...item,
                          //   retenciones,
                          // });
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "Producto",
                                  label: "Producto",
                                  minWidth: 300,
                                  tipo: "string",

                                  propiedad: "Producto",
                                },
                                {
                                  id: "Código",
                                  label: "Código",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "Código",
                                },
                                {
                                  id: "incluye iva",
                                  label: "incluye iva",
                                  minWidth: 50,
                                  tipo: "string",

                                  propiedad: "incluye iva",
                                },
                                {
                                  id: "IVA",
                                  label: "IVA",
                                  minWidth: 50,
                                  tipo: "string",

                                  propiedad: "IVA",
                                },
                                {
                                  id: "Cantidad",
                                  label: "Cantidad",
                                  minWidth: 25,
                                  tipo: "string",

                                  propiedad: "Cantidad",
                                },
                                {
                                  id: "Precio",
                                  label: "Precio",
                                  minWidth: 75,
                                  tipo: "string",

                                  propiedad: "Precio",
                                },
                                {
                                  id: "Desc%",
                                  label: "Desc%",
                                  minWidth: 25,
                                  tipo: "string",

                                  propiedad: "Desc%",
                                },
                                {
                                  id: "Costo",
                                  label: "Costo",
                                  minWidth: 25,
                                  tipo: "string",

                                  propiedad: "Costo",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {agregarProducto ? (
                              <RowAgregarProducto
                                formPorcentaje={item.formPorcentaje}
                                venProductos={venProductos}
                                setagregar={setagregarProducto}
                                setitem={setitem}
                                item={item}
                                agregar={agregarProducto}
                                setopenInventario={setopenInventario}
                                NotaCredito={
                                  (ObjectoActivo.venOption === "4" &&
                                    ObjectoActivo.venTipoDocumento === "NC") ||
                                  estadoRequest === "NOTA CREDITOS"
                                }
                                producto={producto}
                                setproducto={setproducto}
                                itemRow={itemRow}
                                setItemRow={setItemRow}
                                setfocusCantidad={setfocusCantidad}
                                focusCantidad={focusCantidad}
                                inputRef={inputRef}
                                desdePeluqueria={desdePeluqueria}
                                subcategoriasData={subcategoriasData}
                                seteditarInventario={seteditarInventario}
                                changeObjetoActivoStateInventario={
                                  changeObjetoActivoStateInventario
                                }
                                socket={socket}
                              />
                            ) : null}
                            {venProductos
                              .sort((a, b) => a.order - b.order)
                              .map((row, index) => {
                                return (
                                  <RowProducto
                                    formPorcentaje={item.formPorcentaje}
                                    venProductos={venProductos}
                                    subcategoriasData={subcategoriasData}
                                    setopenInventario={setopenInventario}
                                    deshabilitarbotones={deshabilitar}
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={index}
                                    NotaCredito={
                                      (ObjectoActivo.venOption === "4" &&
                                        ObjectoActivo.venTipoDocumento ===
                                          "NC") ||
                                      estadoRequest === "NOTA CREDITOS"
                                    }
                                    desdePeluqueria={desdePeluqueria}
                                    seteditarInventario={seteditarInventario}
                                    changeObjetoActivoStateInventario={
                                      changeObjetoActivoStateInventario
                                    }
                                    handleDragStart={handleDragStart}
                                    handleDragEnter={handleDragEnter}
                                    handleDragEnd={handleDragEnd}
                                    socket={socket}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                  <>
                    <Box display="flex" mt={0} width={"100%"} flexWrap="wrap">
                      <Box className={clases.formD25}>
                        <Divider className={clases.formAllW} />
                        <Box
                          display={"flex"}
                          justifyContent={"space-around"}
                          className={clases.formAllW}
                        >
                          <Typography
                            style={{ width: "100%" }}
                            variant="subtitle1"
                            color="secondary"
                            align="center"
                            component={"span"}
                          >
                            DINERO $:
                            <Typography
                              variant="subtitle1"
                              color="primary"
                              component={"span"}
                            >
                              <TextField
                                type="number"
                                id=""
                                label=""
                                value={dinero}
                                onChange={(e) => {
                                  setdinero(e.target.value);
                                }}
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <React.Fragment>
                                      <MonetizationOn color="primary" />
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            </Typography>
                          </Typography>
                          <Typography
                            style={{ width: "100%" }}
                            variant="subtitle1"
                            color="secondary"
                            align="center"
                            component={"span"}
                          >
                            CAMBIO $:
                            <Typography variant="subtitle1" color="primary">
                              {(dinero - (venTotal - venAbono)).toFixed(2)}
                            </Typography>
                          </Typography>
                        </Box>
                        <Divider className={clases.formAllW} />
                      </Box>

                      <TableContainer
                        className={clases.formD25}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal {15}%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.font500}
                              >
                                ${venSubTotal12}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal 0%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venSubtotal0}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Descuento
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venDescuento}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venSubTotal}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Iva {15}%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venTotalIva}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Abono
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venAbono}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.font500}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.font500}
                              >
                                ${(venTotal - venAbono).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                </Box>
                {/* <Divider /> */}
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                ></Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);
