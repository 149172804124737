import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Avatar, Box, ListItemAvatar } from "@mui/material";
import { NavLink } from "react-router-dom";
import { socketUrl, trimPropsItem } from "../../config/const";
import AuthContext from "../../Context/Auth/AuthContext";
import io from "socket.io-client";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificacionDialog({
  open,
  setOpen,
  notificacionesData,
}) {
  const { usuario } = React.useContext(AuthContext);
  const handleClose = () => {
    setOpen(false);
  };
  const socket = React.useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  React.useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:actualizadoExitoso", (data) => {});

    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  const handleEditar = (notificacion) => {
    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...notificacion,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        notUser: usuario.usuario,
        notEmpresa: usuario.rucempresa,
        tabla: "notificacionleido",
      })
    );
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Tiene notificaciones pendientes
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Divider
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            Notificaciones 1
          </Divider>
        </Box>
        <List>
          {notificacionesData.notificacion1.map((noti) => {
            return (
              <>
                <ListItem button disablePadding>
                  <ListItemText primary="Phone ringtone" secondary="Titania" />
                  <ListItemAvatar>
                    <Avatar src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Divider
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            Notificaciones 2
          </Divider>
        </Box>
        <List>
          {notificacionesData.notificacion2.map((noti) => {
            return (
              <>
                <NavLink
                  to={
                    noti.notInformacion.urlweb ? noti.notInformacion.urlweb : ""
                  }
                  onClick={() => {
                    if (noti.notVisto === "SI") {
                      return;
                    }
                    handleEditar(noti);
                  }}
                >
                  <ListItem
                    sx={{
                      background: (theme) => {
                        return noti.notVisto === "NO"
                          ? theme.palette.secondary.light
                          : "";
                      },
                    }}
                    button
                    disablePadding
                  >
                    <ListItemText
                      primaryTypographyProps={{ color: "primary" }}
                      secondaryTypographyProps={{ color: "primary" }}
                      primary={
                        <>
                          <Typography
                            variant="body1"
                            color={"primary"}
                          >{`${noti.notTitulo} - ${noti.notDocuPersona} ${noti.notNombrePersona}`}</Typography>
                          <Typography
                            variant="body2"
                            color={"primary"}
                          >{`${noti.notUser}`}</Typography>
                        </>
                      }
                      secondary={`${noti.notContenido}`}
                    />
                    <ListItemAvatar>
                      <Avatar src={noti.notFoto} />
                    </ListItemAvatar>
                  </ListItem>
                </NavLink>
                <Divider />
              </>
            );
          })}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
