import React from "react";
import { Box, Collapse, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const CollapseCell = (props) => {
  const { celComprimida, setcelComprimida, row, propiedad, claseCelda } = props;
  const classes = useRowStyles();

  //   function splitHtmlTags(str) {
  //     return str.split(/<[^>]*>/g);
  //   }
  function HTMLString(props) {
    const { htmlString } = props;
    return <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>;
  }
  const regex = /<[^>]*>/g;
  function generarTabla(datos) {
    const noMostrar = ["id", "order", "selected"];
    if (datos.length === 0) {
      return "<table></table>";
    }
    let columnas = Object.keys(datos[0]);
    let tabla = "<table><tr>";
    for (let i = 0; i < columnas.length; i++) {
      if (!noMostrar.some((propiedad) => propiedad === columnas[i])) {
        tabla += "<th>" + columnas[i] + "</th>";
      }
    }
    tabla += "</tr>";
    for (let i = 0; i < datos.length; i++) {
      tabla += "<tr>";
      for (let j = 0; j < columnas.length; j++) {
        if (!noMostrar.some((propiedad) => propiedad === columnas[j])) {
          tabla += "<td>" + datos[i][columnas[j]] + "</td>";
        }
      }
      tabla += "</tr>";
    }
    tabla += "</table>";
    return tabla;
  }
  const convetirTabla = (dataConver) => {
    let [texto, tabla] = dataConver.split(`[{"order":`);
    if (!tabla) {
      return texto;
    }
    tabla = `[{"order":` + tabla;
    return texto + generarTabla(JSON.parse(tabla));
  };
  if (!regex.test(convetirTabla(row[propiedad]))) {
    return (
      <TableCell
        className={`${claseCelda} ${classes.hover}`}
        size="small"
        align="center"
        onClick={() =>
          setcelComprimida({
            ...celComprimida,
            [propiedad]:
              row[propiedad].length > 35
                ? !celComprimida[propiedad]
                : celComprimida[propiedad],
          })
        }
      >
        {!celComprimida[propiedad] ? (
          <>
            {row[propiedad].length > 35
              ? `${row[propiedad].substr(0, 35)}...`
              : `${row[propiedad]}`}
          </>
        ) : null}
        <Collapse in={celComprimida[propiedad]} timeout="auto" unmountOnExit>
          {row[propiedad]}
        </Collapse>
      </TableCell>
    );
  }
  return (
    <TableCell
      className={`${claseCelda} ${classes.hover}`}
      size="small"
      align="center"
      onClick={() =>
        setcelComprimida({
          ...celComprimida,
          [propiedad]:
            row[propiedad].length > 35
              ? !celComprimida[propiedad]
              : celComprimida[propiedad],
        })
      }
    >
      <Box
        sx={{
          maxHeight: celComprimida[propiedad] ? "8rem" : "2rem",
          overflow: celComprimida[propiedad] ? "scroll" : "hidden",
          transitionProperty: "max-height",
          transitionDuration: ".5s",
          paddingBottom: 0,
        }}
      >
        {HTMLString({ htmlString: convetirTabla(row[propiedad]) })}
      </Box>
    </TableCell>
  );
};

export default CollapseCell;
