import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import { objectDefault } from "../data";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket, supervisoresData } = props;
  const claseCelda = `${classes.celda}`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // COPIA DEL REGISTRO PARA EDITAR

  const [proveedoresData, setproveedoresData] = useState([]);
  const [item, setitem] = useState(objectDefault);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (false) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      console.log(
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "compras",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "compras",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          {" "}
          <TableCell size="small" align="center" className={classes.celda}>
            <FormControl fullWidth>
              <Autocomplete
                disableClearable={true}
                options={proveedoresData.map((item) => {
                  return (
                    item.cliDocNumero +
                    "/+/" +
                    item.cliRazonSocial +
                    "/+/" +
                    item.cliId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                value={
                  `${item.razonSocial_proveedor} ${item.documento_proveedor}`.trim() ===
                  ""
                    ? ""
                    : `${item.razonSocial_proveedor} ${item.documento_proveedor}`
                }
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setitem({
                      ...item,
                      documento_proveedor: options[0],
                      razonSocial_proveedor: options[1],
                      id_proveedor: options[2],
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        console.log(e.target.value);
                        const res = await clienteAxios.get(
                          `/proveedores/filtro/0?search=${e.target.value}`
                        );
                        console.log({ res });

                        setproveedoresData(res.data.data);
                      } catch (error) {
                        console.log({ error });
                        setproveedoresData([]);

                        return mostrarAlerta("No hay proveedores", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.numFactura}
              name="numFactura"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="date"
              value={item.fecFactura}
              name="fecFactura"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.cantidad}
              name="cantidad"
              onChange={(e) =>
                setitem((prev) => ({
                  ...prev,
                  [e.target.name]: Math.max(0, e.target.value),
                }))
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {item.user}
          </TableCell>
          <TableCell
            fontSize="small"
            align="center"
            className={claseCelda}
          ></TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
