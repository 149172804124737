import React, { memo, useContext, useEffect } from "react";

import MuiImageSlider from "mui-image-slider";
import { useState } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { DropzoneDialog } from "react-mui-dropzone";

// MATERIAL
import {
  Box,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Popper,
  Typography,
  Dialog,
  Autocomplete,
} from "@mui/material";
import {
  Cancel,
  Check,
  Visibility,
  Photo,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import Cantidades from "../Cantidades/Cantidades";
import { v4 } from "uuid";

const RowAgregarArma = (props) => {
  const { setagregar, setitem, item, bodegaData } = props;
  const classes = useRowStyles();
  const claseCelda = `${classes.celda}`;
  const [open, setOpen] = React.useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [expandido, setexpandido] = useState(false);
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const { mostrarAlerta } = useContext(AlertaContext);
  const [nombresData, setnombresData] = useState([]);

  const itemHijoDefecto = {
    idMapeo: v4(),
    tipo: "ARMAS",
    bodega: bodegaData.length > 0 ? bodegaData[0].bodNombre : "",
    idBodega: bodegaData.length > 0 ? bodegaData[0].bodId : "",
    fotos: [],
    id: "",
    nombre: "",
    serie: "",
    valor: "",
    marca: "",
    modelo: "",
    tipoArma: "",
    calibre: "",
    color: "",
    stock: "",
    cantidades: [],
  };
  useEffect(() => {
    const getArmas = async () => {
      const res = await clienteAxios.get(
        `/inventario/filtroanidado/0?tipo=ARMAS&bodega=${
          bodegaData.length > 0 ? bodegaData[0].bodNombre : ""
        }`
      );
      setnombresData(res.data.data);
    };
    getArmas();
    // eslint-disable-next-line
  }, []);

  const [itemHijoNuevo, setitemHijoNuevo] = useState(itemHijoDefecto);
  const botonDisabled = () => {
    if (
      itemHijoNuevo.nombre === "" ||
      itemHijoNuevo.color === "" ||
      itemHijoNuevo.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregar(false);
        setitemHijoNuevo(itemHijoDefecto);
      },
      disabled: cargando,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: async () => {
        await handleAgregar();
        setagregar(false);
        setitemHijoNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Ver fotos",
      texto: "",
      funcion: async () => {
        setOpen(true);
      },
      disabled: false,
      Icon: Photo,
      color: "primary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Expandir",
      texto: "",
      funcion: async () => {
        setexpandido((prev) => !prev);
      },
      disabled: false,
      Icon: expandido ? ExpandLess : ExpandMore,
      color: "primary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const handleAgregar = async () => {
    try {
      setcargando(true);
      const formDataImagenes = new FormData();
      let resDocumentos = {
        data: {
          urls: itemHijoNuevo.fotos,
        },
      };
      await imagenes.map(async (item, index) => {
        return await formDataImagenes.append(`imagen${index + 1}`, item);
      });

      if (imagenes.length > 0) {
        resDocumentos = await clienteAxios.post(
          "/multimedias",
          formDataImagenes
        );
      }

      const disArmas = [
        {
          ...itemHijoNuevo,
          fotos: resDocumentos.data.urls,
        },
        ...item.disArmas,
      ];

      setitem({
        ...item,
        disArmas,
      });
      setimagenes([]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <DropzoneDialog
          dialogProps={{ maxWidth: "lg" }}
          dialogTitle={
            <Typography variant="h5" color="primary">
              Subir archivos
            </Typography>
          }
          dropzoneText={
            <>
              <Typography variant="h4" color="primary">
                Arrastra una imagen o da click aqui.
              </Typography>{" "}
            </>
          }
          acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
          cancelButtonText={"Cancelar"}
          submitButtonText={"Aceptar"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            setimagenes(files);
            setOpen(false);
          }}
          showPreviewsInDropzone={true}
          showPreviews={false}
          showAlerts={true}
          showFileNamesInPreview={false}
        />
        <ButtonsAction botones={botones} className={claseCelda} />

        {/* las demas columnas */}

        <>
          {" "}
          <Dialog
            open={Openimagen}
            onClose={() => setOpenimagen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            {itemHijoNuevo.fotos === 0 ? null : (
              <MuiImageSlider
                images={itemHijoNuevo.fotos.map((item) => item.url)}
                fitToImageHeight={true}
              />
            )}
          </Dialog>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {itemHijoNuevo.fotos.length === 0 ? (
              "No hay imagenes"
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding="none"
                alignItems="center"
              >
                <IconButton
                  aria-label=""
                  size="small"
                  onClick={() => setOpenimagen(true)}
                  className={classes.margin}
                >
                  <Visibility size="small" color="secondary" />
                </IconButton>
              </Box>
            )}
          </TableCell>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={bodegaData.map((item) => {
                  return item.bodNombre + "/+/" + item.bodId;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemHijoNuevo.bodega}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    const res = await clienteAxios.get(
                      `/inventario/filtroanidado/0?tipo=ARMAS&bodega=${options[0]}`
                    );
                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      bodega: options[0],
                      idBodega: options[1],
                      id: "",
                      nombre: "",
                      serie: "",
                      valor: "",
                      marca: "",
                      modelo: "",
                      tipoArma: "",
                      calibre: "",
                      color: "",
                      stock: "",
                      cantidades: [],
                    });
                    setnombresData(res.data.data);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={nombresData.map((item) => {
                  return (
                    item.invNombre + "/+/" + item.invStock + "/+/" + item.invId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ** ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemHijoNuevo.nombre}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    const nombre = options[0];
                    const id = options[2];
                    try {
                      const itemF = nombresData.find(
                        (item) => item.invId === Number(id)
                      );

                      if (Number(itemF.invStock) === 0) {
                        return mostrarAlerta(
                          "Este producto ya se encuentra distribuido, no hay stock",
                          "error"
                        );
                      }
                      const serie = itemF.invSerie;
                      const valor = itemF.invPrecioUnitario;
                      const tipoArma = itemF.invCalibre;
                      const calibre = itemF.invCalibre;
                      const marca = itemF.invMarca;
                      const modelo = itemF.invModelo;
                      const color = itemF.invColor;
                      const stock = itemF.invStock;

                      const hayDisponible = itemF.invTotalesStock.some(
                        (stock) => stock.cantidad > 0
                      );
                      if (hayDisponible) {
                        setexpandido(true);
                      }

                      setitemHijoNuevo({
                        ...itemHijoNuevo,
                        tipo: "ARMAS",
                        //interno
                        id,
                        serie,
                        valor,
                        tipoArma,
                        calibre,
                        marca,
                        modelo,
                        //demas
                        nombre,
                        color,
                        stock,
                        cantidades: itemF.invTotalesStock.map((stock) => ({
                          ...stock,
                          max: Number(stock.cantidad),
                          cantidad: Math.min(1, stock.cantidad),
                        })),
                      });
                    } catch (error) {}
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {itemHijoNuevo.color}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {itemHijoNuevo.valor}
          </TableCell>
        </>
      </TableRow>
      <Cantidades
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoNuevo}
        setitem={setitemHijoNuevo}
      />
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
