import React, { createRef, useContext } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Cancel,
  CloudDownload,
  CloudUpload,
  // Group,
  Person,
  Search,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));
export default function ConfirmacionDialog(props) {
  const inputEl = createRef();
  const { mostrarAlerta } = useContext(AlertaContext);
  const focusInput = () => {
    inputEl.current.click();
  };
  const classes = useStyles();
  const {
    open,
    setopen,
    ocultarBuscar,
    setopenMB,
    subirArchivo,
    funcArchivo,
    ats,
    setopenATS,
    estadoRequest,
    parametroArchivo,
    parametroParaElArchivo,
    setparametroParaElArchivo,
    //NuevaOpcion
    nuevaOpcion,
    funcionNuevaFuncion,
    tituloNuevaOpcion,
    iconoNuevaFuncion,
    // personalizado
    personalizado,
    setopenModal,
  } = props;

  const handleClose = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {personalizado ? (
        <>
          {" "}
          <Box>
            <Box display="flex" justifyContent="space-between">
              <DialogTitle id="alert-dialog-title">Agregar</DialogTitle>
              <IconButton aria-label="" onClick={handleClose} color="secondary">
                <Cancel />
              </IconButton>
            </Box>
            <input
              className={classes.ocultar}
              type="file"
              name="myImage"
              onChange={async (e) => {
                await funcArchivo(e);
                setopen(false);
              }}
              ref={inputEl}
            />

            <Box pr={1} pl={1} pb={1}>
              <DialogContent>
                <List component="nav">
                  {" "}
                  {nuevaOpcion ? (
                    <>
                      <ListItem button onClick={() => funcionNuevaFuncion()}>
                        <ListItemIcon>{iconoNuevaFuncion}</ListItemIcon>
                        <ListItemText primary={tituloNuevaOpcion} />
                      </ListItem>
                    </>
                  ) : null}
                  {ocultarBuscar ? null : (
                    <ListItem button onClick={() => setopenModal(true)}>
                      <ListItemIcon>
                        <Person color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Individual" />
                    </ListItem>
                  )}
                  {ats ? (
                    <ListItem
                      button
                      onClick={() => {
                        setopenATS(true);
                      }}
                    >
                      <ListItemIcon>
                        <CloudDownload color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Descargar ATS" />
                    </ListItem>
                  ) : null}
                  {subirArchivo ? (
                    <ListItem
                      button
                      onClick={() => {
                        if (parametroArchivo) {
                          if (parametroParaElArchivo.valor === "") {
                            setparametroParaElArchivo({
                              ...parametroParaElArchivo,
                              error: true,
                            });
                            return mostrarAlerta(
                              "El periodo es requerido",
                              "warning"
                            );
                          }
                        }
                        setparametroParaElArchivo({
                          ...parametroParaElArchivo,
                          error: false,
                        });
                        focusInput();
                      }}
                    >
                      <ListItemIcon>
                        <CloudUpload color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Subir archivo" />
                    </ListItem>
                  ) : null}
                  <Fade direction="up" in={true} style={{ width: "100%" }}>
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      {parametroArchivo ? (
                        <TextField
                          type="month"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.inputPadding }}
                          className={classes.formD}
                          size="small"
                          id="valor"
                          label="Periodo"
                          value={parametroParaElArchivo.valor}
                          name="valor"
                          helperText={estadoRequest}
                          error={parametroParaElArchivo.error}
                          onChange={(e) => {
                            setparametroParaElArchivo({
                              ...parametroParaElArchivo,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      ) : null}
                    </Box>
                  </Fade>
                </List>
              </DialogContent>
              <DialogActions></DialogActions>
            </Box>
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Box>
            <Box display="flex" justifyContent="space-between">
              <DialogTitle id="alert-dialog-title">Agregar</DialogTitle>
              <IconButton aria-label="" onClick={handleClose} color="secondary">
                <Cancel />
              </IconButton>
            </Box>
            <input
              className={classes.ocultar}
              type="file"
              name="myImage"
              onChange={async (e) => {
                await funcArchivo(e);
                setopen(false);
              }}
              ref={inputEl}
            />

            <Box pr={1} pl={1} pb={1}>
              <DialogContent>
                <List component="nav">
                  {" "}
                  {ocultarBuscar ? null : (
                    <ListItem button onClick={() => setopenMB(true)}>
                      <ListItemIcon>
                        <Search color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Busqueda avanzada" />
                    </ListItem>
                  )}
                  {ats ? (
                    <ListItem
                      button
                      onClick={() => {
                        setopenATS(true);
                      }}
                    >
                      <ListItemIcon>
                        <CloudDownload color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Descargar ATS" />
                    </ListItem>
                  ) : null}
                  {subirArchivo ? (
                    <ListItem
                      button
                      onClick={() => {
                        if (parametroArchivo) {
                          if (parametroParaElArchivo.valor === "") {
                            setparametroParaElArchivo({
                              ...parametroParaElArchivo,
                              error: true,
                            });
                            return mostrarAlerta(
                              "El periodo es requerido",
                              "warning"
                            );
                          }
                        }
                        setparametroParaElArchivo({
                          ...parametroParaElArchivo,
                          error: false,
                        });
                        focusInput();
                      }}
                    >
                      <ListItemIcon>
                        <CloudUpload color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Subir archivo" />
                    </ListItem>
                  ) : null}{" "}
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {parametroArchivo ? (
                      <TextField
                        type="month"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ className: classes.inputPadding }}
                        className={classes.formD}
                        size="small"
                        id="valor"
                        label="Periodo"
                        value={parametroParaElArchivo.valor}
                        name="valor"
                        helperText={estadoRequest}
                        error={parametroParaElArchivo.error}
                        onChange={(e) => {
                          setparametroParaElArchivo({
                            ...parametroParaElArchivo,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    ) : null}
                  </Box>
                  {nuevaOpcion ? (
                    <>
                      <ListItem button onClick={() => funcionNuevaFuncion()}>
                        <ListItemIcon>{iconoNuevaFuncion}</ListItemIcon>
                        <ListItemText primary={tituloNuevaOpcion} />
                      </ListItem>
                    </>
                  ) : null}
                </List>
              </DialogContent>
              <DialogActions></DialogActions>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
}
