import React, { memo, useContext } from "react";
import MuiImageSlider from "mui-image-slider";
import { useState } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { DropzoneDialog } from "react-mui-dropzone";
import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Dialog,
  MenuItem,
} from "@mui/material";
import { Cancel, Check, Visibility, Photo } from "@mui/icons-material";
import { FechaExacta } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const [open, setOpen] = React.useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { usuario } = useContext(AuthContext);

  const { mostrarAlerta } = useContext(AlertaContext);

  const { setagregar, item, setmovimientosData, movimientosData } = props;
  const objetoDefault = {
    hisId: 0, // si es nuevo => 0, o al editar misma propiedad que te devuelvo
    // invId: "90", // si es nuevo tomar valor del inventario =>  invId, o al editar misma propiedad que te devuelvo
    hisTipo: "MOVIMIENTO", //interno
    hisOption: "Independiente", //si es nuevo "Independiente" o al actualizar lo que te devuelvo
    hisStatus: "EGRESO",
    hisCantidad: "1",
    hisIdDistribucion: 0,
    hisFecha: FechaExacta, //input date
    hisResponsable: "", //input
    hisDetalle: "", // textarea row 1
    hisFotos: [], // dropzone
    hisUser: "", //datos del login
    hisEmpresa: "", //datos del login
    hisFecReg: "",
    Todos: "",
  };
  const [itemHijoNuevo, setitemHijoNuevo] = useState(objetoDefault);
  const handleChange = (e) => {
    setitemHijoNuevo({
      ...itemHijoNuevo,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      itemHijoNuevo.hisFecha === "" ||
      itemHijoNuevo.hisResponsable === "" ||
      itemHijoNuevo.hisDetalle === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const classes = useRowStyles();

  const handleAgregar = async () => {
    try {
      setcargando(true);
      const formDataImagenes = new FormData();
      let resDocumentos = {
        data: {
          urls: itemHijoNuevo.hisFotos,
        },
      };
      await imagenes.map(async (item, index) => {
        return await formDataImagenes.append(`imagen${index + 1}`, item);
      });

      if (imagenes.length > 0) {
        resDocumentos = await clienteAxios.post(
          "/multimedias",
          formDataImagenes
        );
      }

      const resItem = await clienteAxios.post("/historialinventario", {
        ...itemHijoNuevo,
        invId: item.invId,
        hisUser: usuario.usuario,
        hisEmpresa: usuario.rucempresa,
        hisFotos: resDocumentos.data.urls,
      });

      const mantenimientosDataR = [resItem.data.data, ...movimientosData];
      mostrarAlerta("Historial Creado Correctamente", "success");
      setmovimientosData(mantenimientosDataR);
      setimagenes([]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <DropzoneDialog
          dialogProps={{ maxWidth: "lg" }}
          dialogTitle={
            <Typography variant="h5" color="primary">
              Subir archivos
            </Typography>
          }
          dropzoneText={
            <>
              <Typography variant="h4" color="primary">
                Arrastra una imagen o da click aqui.
              </Typography>{" "}
            </>
          }
          acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
          cancelButtonText={"Cancelar"}
          submitButtonText={"Aceptar"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            setimagenes(files);
            setOpen(false);
          }}
          showPreviewsInDropzone={true}
          showPreviews={false}
          showAlerts={true}
          showFileNamesInPreview={false}
        />
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            ml={1}
            mr={1}
          >
            <IconButton
              className={classes.margin}
              disabled={cargando}
              color={cargando ? "inherit" : "secondary"}
              onClick={() => {
                setagregar(false);
                setitemHijoNuevo(objetoDefault);
              }}
            >
              <Cancel fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled() || cargando}
              color={botonDisabled() || cargando ? "inherit" : "secondary"}
              onClick={async () => {
                await handleAgregar();

                setagregar(false);
                setitemHijoNuevo(objetoDefault);
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <IconButton
              disabled={cargando}
              className={classes.margin}
              onClick={() => setOpen(true)}
            >
              <Photo fontSize="small" color="primary" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          {" "}
          <Dialog
            open={Openimagen}
            onClose={() => setOpenimagen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            {itemHijoNuevo.hisFotos === 0 ? null : (
              <MuiImageSlider
                images={itemHijoNuevo.hisFotos.map((item) => item.url)}
                fitToImageHeight={true}
              />
            )}
          </Dialog>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {itemHijoNuevo.hisFotos.length === 0 ? (
              "No hay imagenes"
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding="none"
                alignItems="center"
              >
                <IconButton
                  aria-label=""
                  size="small"
                  onClick={() => setOpenimagen(true)}
                  className={classes.margin}
                >
                  <Visibility size="small" color="secondary" />
                </IconButton>
              </Box>
            )}
          </TableCell>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={itemHijoNuevo.hisStatus}
              name="hisStatus"
              onChange={(e) => handleChange(e)}
              select
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["EGRESO", "INGRESO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              size="small"
              fullWidth
              value={itemHijoNuevo.hisCantidad}
              name="hisCantidad"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"date"}
              size="small"
              id="hisFecha"
              fullWidth
              value={itemHijoNuevo.hisFecha}
              name="hisFecha"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              id="hisResponsable"
              fullWidth
              value={itemHijoNuevo.hisResponsable}
              name="hisResponsable"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              multiline
              rows={1}
              size="small"
              id="hisDetalle"
              fullWidth
              value={itemHijoNuevo.hisDetalle}
              name="hisDetalle"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
