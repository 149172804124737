import React from "react";

//CSS
import "./GlobalCssSlider.css";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, Dialog, Slide, Typography, Button } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#00a000",
    color: "white",
  },
  warning: {
    backgroundColor: "#ffa90b",
    color: "black",
  },
  error: {
    backgroundColor: "#ff1c06",
    color: "white",
  },
  font: { fontSize: ".9rem", fontWeight: "700" },
}));

export default function ConfirmacionDialog(props) {
  const clases = useStyles();
  const { open, setopen, titulo, categoria, funcion } = props;
  const handleClose = () => {
    setopen(false);
  };

  if (!open) {
    return null;
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={categoria ? clases[categoria] : clases.warning}>
        <Box
          display="flex"
          justifyContent="space-between"
          p={1.5}
          alignItems={"center"}
        >
          <Typography
            variant="subtitle1"
            color=""
            component={"span"}
            className={clases.font}
          >
            {titulo}
          </Typography>
          <Box mr={1}></Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                funcion();
                handleClose();
              }}
            >
              SI
            </Button>
            <Box ml={1} mr={1}>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                NO
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
