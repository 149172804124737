import React, { useState, useContext, useEffect, memo, createRef } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Tooltip,
  InputAdornment,
  Autocomplete,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Cancel, CloudUpload, Download } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useModalStyle } from "../../styles/styleModal";
import { objectDefault } from "../data";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAnticipos = (props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  //props
  const {
    estadoRequest,
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdeDis,
  } = props;
  function getMonths() {
    let currentDate = new Date();
    let months = [
      currentDate.getFullYear() +
        "-" +
        (currentDate.getMonth() + 1).toString().padStart(2, "0"),
    ];
    for (let i = 0; i < 4; i++) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      months.push(
        currentDate.getFullYear() +
          "-" +
          (currentDate.getMonth() + 1).toString().padStart(2, "0")
      );
    }
    return months;
  }
  function obtenerFechasDescuentos() {
    let fechas = [];
    let fechaActual = new Date();
    fechaActual.setMonth(fechaActual.getMonth());
    for (let i = 0; i < 9; i++) {
      let fecha = new Date(
        fechaActual.getFullYear(),
        fechaActual.getMonth() + i,
        1
      );
      let mes = fecha.getMonth() + 1;
      mes = mes < 10 ? "0" + mes : mes;
      fechas.push(fecha.getFullYear() + "-" + mes);
    }
    return fechas;
  }
  //documento
  const [documentoinvPermisoPdf, setdocumentoinvPermisoPdf] = useState("");
  const refArchivoinvPermisoPdf = createRef();
  const focusInputinvPermisoPdf = () => {
    refArchivoinvPermisoPdf.current.click();
  };
  const onChangeArchivoinvPermisoPdf = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoinvPermisoPdf("");
    }
    if (
      !["png", "jpg", "jpeg"].some(
        (item) => item === e.target.files[0].type.split("/")[1]
      )
    ) {
      setdocumentoinvPermisoPdf("");
      return mostrarAlerta("El archivo debe ser una imagen", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (antComprobante === "") {
        await formDataLogos.append("antComprobante", e.target.files[0]);
      } else {
        arrayEliminar.push({
          nombre: "eliminar",
          url: antComprobante,
        });
        await formDataLogos.append("antComprobante", e.target.files[0]);
      }

      await formDataLogos.append("eliminar", JSON.stringify(arrayEliminar));

      const resDocumentos = await clienteAxios.post(
        "/multiplefiles",
        formDataLogos
      );

      const documentosUrls = {};
      resDocumentos.data.urls.forEach((dataUrl) => {
        if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
          return;
        }
        documentosUrls[dataUrl.nombre] = dataUrl.url;
      });

      setitem({
        ...item,
        antComprobante: documentosUrls["antComprobante"],
      });

      setdocumentoinvPermisoPdf(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoinvPermisoPdf(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoinvPermisoPdf(e.target.files[0]);
  };
  // eslint-disable-next-line

  const [clientesData, setclientesData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const {
    // antIdPersona,
    antDocuPersona,
    antNomPersona,
    antValor,
    antFormaPago,
    antPeriodo,
    antDetalle,
    antFecha,
    antComprobante,
    antEstado,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    antDocuPersona: false,
    antNomPersona: false,
    antValor: false,
    antFormaPago: false,
    antPeriodo: false,
    antDetalle: false,
    antFecha: false,
    antComprobante: false,
  });

  useEffect(() => {
    setitem({
      ...ObjectoActivo,
    });

    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);

    setopen(false);
    seteditar(false);
    seterror({
      antDocuPersona: false,
      antNomPersona: false,
      antValor: false,
      antFormaPago: false,
      antPeriodo: false,
      antDetalle: false,
      antFecha: false,
      antComprobante: false,
    });
    setdocumentoinvPermisoPdf("");
    // setseccionActive({
    //   datosPersonalesSeccion: true,
    //   datosContablesSeccion: false,
    // });

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);

  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };

  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      antDocuPersona.trim() === "" ||
      antNomPersona.trim() === "" ||
      antValor === "" ||
      antFormaPago.trim() === "" ||
      antPeriodo.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        antDocuPersona: antDocuPersona.trim() === "" ? true : false,
        antNomPersona: antNomPersona.trim() === "" ? true : false,
        antValor: antValor === "" ? true : false,
        antFormaPago: antFormaPago.trim() === "" ? true : false,
        antPeriodo: antPeriodo.trim() === "" ? true : false,
      });
    }

    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "anticipo",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          antUser: usuario.usuario,
          antEmpresa: usuario.rucempresa,
          antTipo: estadoRequest,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (
      antDocuPersona.trim() === "" ||
      antNomPersona.trim() === "" ||
      antValor === "" ||
      antFormaPago.trim() === "" ||
      antPeriodo.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        antDocuPersona: antDocuPersona.trim() === "" ? true : false,
        antNomPersona: antNomPersona.trim() === "" ? true : false,
        antValor: antValor === "" ? true : false,
        antFormaPago: antFormaPago.trim() === "" ? true : false,
        antPeriodo: antPeriodo.trim() === "" ? true : false,
      });
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "anticipo",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          antUser: usuario.usuario,
          antEmpresa: usuario.rucempresa,
          antTipo: estadoRequest,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} ${
                    estadoRequest === "Anticipos" ? "Anticipo" : "Descuento"
                  }.`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={antEstado === "APROBADA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          antEstado: e.target.checked ? "APROBADA" : "ANULADA",
                        });
                      }}
                      name="antoine"
                      color={antEstado === "APROBADA" ? "success" : "error"}
                    />
                  }
                  label="Estado"
                />
              </Box>

              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <>
                  <FormControl
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                  >
                    <Autocomplete
                      disabled={desdeDis}
                      options={clientesData.map((item) => {
                        return (
                          item.perDocNumero +
                          "/+/" +
                          item.perApellidos +
                          " " +
                          item.perNombres +
                          "/+/" +
                          item.perId
                        );
                      })}
                      getOptionLabel={(option) => {
                        return option.replace("/+/", " ").split("/+/")[0];
                      }}
                      id="controlled-demo"
                      value={(antNomPersona + " " + antDocuPersona).trim()}
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          const options = newValue.split("/+/");

                          try {
                            setitem({
                              ...item,
                              antDocuPersona: options[0],
                              antNomPersona: options[1].trim(),
                              antIdPersona: options[2],
                            });
                          } catch (error) {
                            return mostrarAlerta("No hay personas", "error");
                          }
                        } else {
                          setitem({
                            ...item,
                            antIdPersona: "",
                            antDocuPersona: "",
                            antNomPersona: "",
                          });
                          setclientesData([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          required
                          fullWidth
                          disabled={desdeDis}
                          label="Persona"
                          error={error.antDocuPersona}
                          onChange={async (e) => {
                            if (e.target.value === "") {
                              return mostrarAlerta("Ingrese datos", "error");
                            }
                            try {
                              const res = await clienteAxios.get(
                                `/personas/filtro/0?search=${e.target.value}`
                              );

                              setclientesData(res.data.data);
                            } catch (error) {
                              setitem({
                                ...item,
                                antIdPersona: "",
                                antDocuPersona: "",
                                antNomPersona: "",
                              });
                              setclientesData([]);

                              return mostrarAlerta("No hay clientes", "error");
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <TextField
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="antFormaPago"
                    label="F. Pago"
                    value={antFormaPago}
                    name="antFormaPago"
                    error={error.antFormaPago}
                    onChange={(e) => handleChange(e)}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione.</MenuItem>
                    {["EFECTIVO", "DEPOSITO", "CHEQUE", "TRANSFERENCIA"].map(
                      (item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      }
                    )}
                  </TextField>
                  <TextField
                    type="number"
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="antValor"
                    label="Valor"
                    value={antValor}
                    name="antValor"
                    error={error.antValor}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    label="Periodo de Afectación"
                    name="antPeriodo"
                    disabled={editar}
                    value={antPeriodo.split(", ")}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        antPeriodo: e.target.value
                          .filter((mes) => mes !== "")
                          .join(", "),
                      });
                    }}
                    select
                    error={error.antPeriodo}
                    MenuProps={MenuProps}
                    SelectProps={{
                      displayEmpty: true,
                      multiple: true,
                      renderValue: (selected) => {
                        return selected
                          .map((value, index) => {
                            return value;
                          })
                          .join(", ");
                      },
                    }}
                  >
                    {estadoRequest === "Descuentos"
                      ? obtenerFechasDescuentos().map((item) => {
                          return (
                            <MenuItem
                              style={getStylesMenuItems(
                                item,
                                antPeriodo.split(", "),
                                theme
                              )}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })
                      : getMonths().map((item) => {
                          return (
                            <MenuItem
                              style={getStylesMenuItems(
                                item,
                                antPeriodo.split(", "),
                                theme
                              )}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                  </TextField>
                  <TextField
                    type={"date"}
                    disabled
                    inputProps={{
                      className: clases.inputPadding,
                      min: FechaExacta,
                    }}
                    className={clases.formD}
                    size="small"
                    id="antFecha"
                    label="Fecha"
                    value={antFecha}
                    name="antFecha"
                    error={error.antFecha}
                    onChange={(e) => handleChange(e)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  />
                  <>
                    <input
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeArchivoinvPermisoPdf}
                      ref={refArchivoinvPermisoPdf}
                    />
                    <TextField
                      error={error.antComprobante}
                      inputProps={{
                        className: clases.inputPadding,
                      }}
                      InputLabelProps={{
                        shrink:
                          documentoinvPermisoPdf !== "" ||
                          item.antComprobante !== ""
                            ? true
                            : false,
                      }}
                      className={clases.formD}
                      InputProps={{
                        endAdornment: (
                          <Box htmlFor="contained-button-documento" fullWidth>
                            <InputAdornment>
                              <Tooltip title="Subir Archivo: Documento" arrow>
                                <Box>
                                  <IconButton
                                    onClick={focusInputinvPermisoPdf}
                                    aria-label=""
                                  >
                                    <CloudUpload color="secondary" />
                                  </IconButton>
                                </Box>
                              </Tooltip>

                              {item.antComprobante === "" ? null : (
                                <Tooltip
                                  title="Descargar Archivo: Documento"
                                  arrow
                                >
                                  <Box>
                                    <IconButton
                                      color="primary"
                                      onClick={async () => {
                                        window.open(item.antComprobante);
                                      }}
                                      aria-label=""
                                    >
                                      <Download />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                      fullWidth
                      id="antComprobante"
                      label={`Comprobante`}
                      value={
                        antComprobante && !documentoinvPermisoPdf.name
                          ? antComprobante.split("/")[
                              antComprobante.split("/").length - 1
                            ]
                          : documentoinvPermisoPdf.name
                          ? documentoinvPermisoPdf.name
                          : ""
                      }
                      name="antComprobante"
                    />
                  </>
                  <TextField
                    multiline
                    rows={3}
                    variant={"outlined"}
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formAllW}
                    size="small"
                    id="antDetalle"
                    label="Detalle"
                    value={antDetalle}
                    name="antDetalle"
                    error={error.antDetalle}
                    onChange={(e) => handleChange(e)}
                  />
                </>
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
