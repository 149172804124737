// types

import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  OBTENER_USUARIO,
  CERRAR_SESION,
  REGISTRO_EXITOSO,
  EDITAR,
} from "../../types/index";
import io from "socket.io-client";
const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
    case REGISTRO_EXITOSO:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("empresaheader", action.payload.rucempresa);
      localStorage.setItem("usuarioheader", action.payload.usuario);
      localStorage.setItem("rolheader", action.payload.rol);
      return {
        ...state,
        cargando: false,
        mensaje: null,
        autenticado: true,
      };
    case OBTENER_USUARIO:
      //real 6000ef43fc0e9721cc719c77
      //prueba 5fff54ad20dfce219c151957

      return {
        ...state,
        usuario: action.payload,
        autenticado: true,
      };

    case LOGIN_ERROR:
    case CERRAR_SESION:
      localStorage.removeItem("token");
      localStorage.removeItem("empresaheader");
      localStorage.removeItem("usuarioheader");
      localStorage.removeItem("rolheader");
      state.socket.disconnect("disconnect", (mensaje) => {});

      return {
        ...state,
        token: null,
        mensaje: action.payload,
        usuario: null,
        autenticado: false,
        cargando: false,
        //  socket: io("http://localhost:4002/"),
        socket: io("https://backsigeop.neitor.com/"),
      };

    case EDITAR:
      return {
        ...state,
        usuario: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
