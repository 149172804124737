import React, { useState, memo, useContext } from "react";

import ConfirmacionDialog from "../../Extra/ConfirmacionDialog";
import MenuContext from "../../../Context/Menu/MenuContext";
import { tienePermiso } from "../../../config/validaciones";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, Checkbox } from "@mui/material";
import { Create, ExpandLess, ExpandMore } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    row,
    index,
    // eslint-disable-next-line
    setopen,
    expandir,
    expand,
    rol,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { editarCategoria, changeCategoriaActiva } = useContext(MenuContext);
  const classes = useRowStyles();
   

   

  const handleEditar = (e, param) => {
    let permisos = row.sidInfo.permisos;
    if (e.target.checked) {
      permisos.push(rol);
    } else {
      permisos = permisos.filter((permiso) => permiso !== rol);
    }

    editarCategoria({
      ...row,
      sidInfo: { ...row.sidInfo, [e.target.name]: permisos },
    });
    return e.target.checked
      ? mostrarAlerta("Permiso exitoso", "success")
      : mostrarAlerta("Permiso denegado", "error");
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro de que quiere eliminar esta asignatura?"
        contenido={`La asignatura   ${row.sidInfo.name} , una vez borrado no podra ser recuperado`}
        _id={row.sidInfo._id}
        funcion={() => {}}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <TableCell
          style={{ borderRight: "1px solid #ccc", paddingRight: "1.5rem" }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              aria-label=""
              onClick={() => {
                changeCategoriaActiva(row);
                setopen(true);
              }}
            >
              <Create fontSize="small" color="secondary" />
            </IconButton>
            {row.sidInfo.expandible ? (
              <IconButton
                className={classes.margin}
                onClick={() => {
                  expandir(row.sidInfo.name, Boolean(expand[row.sidInfo.name]));
                }}
              >
                {Boolean(expand[row.sidInfo.name]) ? (
                  <ExpandLess fontSize="small" color="secondary" />
                ) : (
                  <ExpandMore fontSize="small" color="secondary" />
                )}
              </IconButton>
            ) : null}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell size="small" align="center">
          {row.sidInfo.name}
        </TableCell>
        {row.sidInfo.expandible ? (
          <TableCell
            size="small"
            align="center"
           sx={{ paddingLeft: "5px" }}
          padding="none"
            colSpan={6}
          ></TableCell>
        ) : (
          <>
            {" "}
            <TableCell size="small" align="center"sx={{ paddingLeft: "5px" }}
          padding="none">
              <Checkbox
                value="ACTIVO"
                onChange={(e) => {
                  handleEditar(e, row.sidInfo.añadir);
                }}
                checked={tienePermiso(rol, row.sidInfo.añadir)}
                name="añadir"
                color="secondary"
                style={{ padding: "0", margin: "0" }}
                size="small"
              />
            </TableCell>
            <TableCell size="small" align="center"sx={{ paddingLeft: "5px" }}
          padding="none">
              <Checkbox
                value="ACTIVO"
                onChange={(e) => {
                  handleEditar(e, row.sidInfo.editar);
                }}
                checked={tienePermiso(rol, row.sidInfo.editar)}
                name="editar"
                color="secondary"
                style={{ padding: "0", margin: "0" }}
                size="small"
              />
            </TableCell>
            <TableCell size="small" align="center"sx={{ paddingLeft: "5px" }}
          padding="none">
              <Checkbox
                value="ACTIVO"
                onChange={(e) => {
                  handleEditar(e, row.sidInfo.eliminar);
                }}
                checked={tienePermiso(rol, row.sidInfo.eliminar)}
                name="eliminar"
                color="secondary"
                style={{ padding: "0", margin: "0" }}
                size="small"
              />
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(Row);
